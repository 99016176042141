import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { useGetDisbursements } from "api";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import formatDate from "shared/formatters/date";


function PanelDisbursements() {
  const { grid, query } = useDataGrid("disbursements", columns);

  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);
  
  const {
    data: disbursements,
    isFetching,
  } = useGetDisbursements(
    loanId,
    { ...query },
    { query: { keepPreviousData: true } }
  );

  return (
    <Box>
      <Box sx={{ display: "flex", height: "100%", width: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGridPro
            {...grid}
            rows={disbursements?.items ?? []}
            rowCount={disbursements?.total}
            loading={isFetching}
            components={{
              Toolbar: Toolbar,
            }}
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PanelDisbursements;

const columns: GridColumns = [
  {
    field: "referenceNumber",
    headerName: "Reference Number",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "drawAmount",
    headerName: "Draw Amount",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "effectiveDate",
    headerName: "Effective Date",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "entryDate",
    headerName: "Entry Date",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
    hide: true,
  },
  {
    field: "principalBalance",
    headerName: "Principal Balance",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "availableBalance",
    headerName: "Available Balance",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    hide: true,
  },
];

function Toolbar() {
  return (
    <DataGridToolbar
      header={"Disbursement History"}
    />
  );
}
