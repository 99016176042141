import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  Stack,
  Modal,
  Link,
} from "@mui/material";
import { useGetPaymentImport, useAddActivity } from "api";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { icons } from "theme";
import Icon from "shared/Icon";
import AddActivity from "activity/forms/AddActivityForm";
import formatPercent from "shared/formatters/percentage";
import History1502Table from "./History1502Table";

function PaymentProcessingDetailPage() {
  const { paymentImportId: paymentImportIdParam } = useParams<{
    paymentImportId: string;
  }>();
  const paymentImportId = Number(paymentImportIdParam);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const { data: paymentImportDetails, isFetched } = useGetPaymentImport(
    {
      paymentImportId: paymentImportId,
    },
    {
      query: {
        keepPreviousData: true,
      },
    }
  );

  const reconciled = paymentImportDetails?.processingModel.lenderCalculation
    .reconciliationDate
    ? true
    : false;

  const { enqueueSnackbar } = useSnackbar();

  const formatDecimal = (
    value: number | undefined | null,
    digits: number = 2
  ): string => {
    return value !== null && value !== undefined
      ? value.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      : "";
  };
  const formatDate = (inputDate?: string | null) => {
    if (inputDate === null || inputDate === undefined) {
      return inputDate;
    } else {
      const [year, month, day] = inputDate.split("-");
      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    }
  };

  const calculateDecimalDifference = (systemValue: any, LenderValue: any) => {
    const valueToFormat = systemValue - LenderValue;

    return formatDecimal(valueToFormat);
  };

  const calculateDateDifference = (
    loan?: string | undefined | null,
    paymentImport?: string | undefined | null
  ) => {
    let result = "";
    if (typeof loan === "string" && typeof paymentImport === "string") {
      const loanDate: Date = new Date(Date.parse(loan));
      const paymentImportDate: Date = new Date(Date.parse(paymentImport));
      const diffTime: number = loanDate.getTime() - paymentImportDate.getTime();
      const days: number = diffTime / (1000 * 3600 * 24);
      if (!Number.isNaN(days)) {
        result = `${days} days`;
      }
    }

    return result;
  };

  const { mutate: activityMutate, ...activityStatus } = useAddActivity({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Activity Added", {
          variant: "success",
        });
        setOpen(false);
      },
    },
  });

  return (
    <Stack width="100%">
      <Card
        elevation={4}
        sx={{
          mb: 4,
          width: "100%",
        }}
      >
        <CardHeader
          title={
            <Link
              component={RouterLink}
              to={`/loans/${paymentImportDetails?.processingModel.lenderCalculation.loanId}`}
            >
              {`Reconciliation: ${paymentImportDetails?.processingModel.systemCalculation.borrowerName} - ${paymentImportDetails?.processingModel.systemCalculation.sbaLoanNumber}`}
            </Link>
          }
          subheader={
            <div>
              <Button onClick={() => setOpen(true)}>Add Activity</Button>
              &nbsp;
              {reconciled === null && (
                <Button
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Reset
                </Button>
              )}
            </div>
          }
          subheaderTypographyProps={{
            align: "right",
            mt: 4,
            ml: 1,

            width: "100%",
          }}
        />

        <CardContent
          sx={{
            ".processing-grid": {
              position: "relative",

              "& .MuiBox-root": {
                flex: 1,
                p: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderRight: "1px solid",
                borderColor: "gray.dark",
              },
              "& .copy-link": {
                flex: 1,
                float: "right",
                border: "none",
                padding: "none",
                cursor: "pointer",
                justifyContent: "flex-end",
              },
              "& .processing-main-header": {
                fontWeight: "700",
                color: "#ffffff",
                textTransform: "uppercase",
                position: "sticky",
                zIndex: "2",
                top: "0",

                "& div:nth-child(2)": {
                  backgroundColor: "primary.main",
                },
                "& div:nth-child(3)": {
                  backgroundColor: "secondary.main",
                },
                "& div:nth-child(4)": {
                  backgroundColor: "error.dark",
                },
              },
              "& .processing-section-header": {
                fontWeight: "700",
                backgroundColor: "gray.main",
                textTransform: "uppercase",
              },
              "& .processing-alt-row": {
                backgroundColor: "grid.altRow",
              },
            },
          }}
        >
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              elevation={4}
              sx={{
                maxWidth: "600px",
                width: "100%",
              }}
            >
              <CardHeader
                title="Add Activity"
                action={
                  <Button sx={{ ml: 2 }} onClick={handleClose}>
                    <Icon fixedWidth icon={icons.close} />
                  </Button>
                }
              />
              <CardContent>
                <AddActivity
                  onSave={(form) => {
                    activityMutate({ data: form });
                  }}
                  status={activityStatus}
                  lenderId={
                    paymentImportDetails?.processingModel.lenderCalculation
                      .lenderId
                  }
                  lenderLoanNumber={
                    paymentImportDetails?.processingModel.systemCalculation
                      .sbaLoanNumber
                  }
                />
              </CardContent>
            </Card>
          </Modal>
          <Box className="processing-grid">
            <Stack className="processing-main-header" direction="row">
              <Box></Box>
              <Box>System Calculation</Box>
              <Box>Lender Calculation</Box>
              <Box>Difference</Box>
            </Stack>

            <Stack className="processing-section-header" direction="row">
              <Box>Whole Loan</Box>
              <Box></Box>
              <Box></Box>
              <Box></Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Ending Principal Balance</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .principalBalance
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .principalBalance
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .principalBalance,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .principalBalance
                )}
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Total Payment Received</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .totalPayment
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .totalPayment
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .totalPayment,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .totalPayment
                )}
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Principal Payment</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .principalPayment
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .principalPayment
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .principalPayment,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .principalPayment
                )}
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Interest Payment</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .interestPayment
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .interestPayment
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .interestPayment,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .interestPayment
                )}
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Prepayment Penalty</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .prepaymentPenaltyPayment
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .prepaymentPenaltyPayment
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .prepaymentPenaltyPayment,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .prepaymentPenaltyPayment
                )}
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Next Due Date</Box>
              <Box>
                <div>
                  {formatDate(
                    paymentImportDetails?.processingModel.systemCalculation
                      .nextDueDate
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  {formatDate(
                    paymentImportDetails?.processingModel?.lenderCalculation
                      ?.nextDueDate
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  {calculateDateDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .nextDueDate,
                    paymentImportDetails?.processingModel?.lenderCalculation
                      ?.nextDueDate
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Interest Paid From Date</Box>
              <Box>
                <div>
                  {formatDate(
                    paymentImportDetails?.processingModel.systemCalculation
                      .interestPaidFromDate
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  {formatDate(
                    paymentImportDetails?.processingModel.lenderCalculation
                      .interestPaidFromDate
                  )}
                </div>
              </Box>
              <Box>
                <div>
                  {calculateDateDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .interestPaidFromDate,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .interestPaidFromDate
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Interest Paid To Date</Box>
              <Box>
                <div>
                  {formatDate(
                    paymentImportDetails?.processingModel.systemCalculation
                      .interestPaidToDate
                  )}
                </div>
              </Box>
              <Box>
                {formatDate(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .interestPaidToDate
                )}
              </Box>
              <Box>
                <div>
                  {calculateDateDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .interestPaidToDate,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .interestPaidToDate
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box
                sx={{
                  textDecoration: "underline",
                }}
              >
                Days Of Interest
              </Box>
              <Box>
                <div>
                  <u>
                    {
                      paymentImportDetails?.processingModel.systemCalculation
                        .daysOfInterest
                    }
                  </u>
                </div>
              </Box>
              <Box>
                <u>
                  {
                    paymentImportDetails?.processingModel.lenderCalculation
                      .daysOfInterest
                  }
                </u>
              </Box>
              <Box>
                <div>
                  <u>
                    {calculateDecimalDifference(
                      paymentImportDetails?.processingModel.systemCalculation
                        .daysOfInterest,
                      paymentImportDetails?.processingModel.lenderCalculation
                        .daysOfInterest
                    )}
                  </u>
                </div>
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Principal Beginning of Period</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantBeginningPrincipal
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantBeginningPrincipal
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantBeginningPrincipal,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantBeginningPrincipal
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Principal End of Period</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantEndingPrincipal
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantEndingPrincipal
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantEndingPrincipal,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantEndingPrincipal
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Guaranty Portion Total P &amp; I</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantPrincipalAndInterest
                  )}
                </div>
                <div className="MuiBox-root copy-link"></div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantPrincipalAndInterest
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantPrincipalAndInterest,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantPrincipalAndInterest
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Guaranty Gross Interest</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantGrossInterest
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantGrossInterest
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantGrossInterest,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantGrossInterest
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Participant Net Interest</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantNetInterest
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantNetInterest
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantNetInterest,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantNetInterest
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Participant Service Fee</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantServiceFee
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantServiceFee
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantServiceFee,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantServiceFee
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Guaranty Portion Principal</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantPrincipalPayment
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .participantPrincipalPayment
                )}
              </Box>
              <Box>
                <div>
                  ${" "}
                  {calculateDecimalDifference(
                    paymentImportDetails?.processingModel.systemCalculation
                      .participantPrincipalPayment,
                    paymentImportDetails?.processingModel.lenderCalculation
                      .participantPrincipalPayment
                  )}
                </div>
              </Box>
            </Stack>

            <Stack className="processing-row" direction="row">
              <Box>Total to FTA</Box>
              <Box>
                <div>
                  ${" "}
                  {formatDecimal(
                    paymentImportDetails?.processingModel.systemCalculation
                      .totalToFTA
                  )}
                </div>
              </Box>
              <Box>
                {" "}
                ${" "}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .totalToFTA
                )}
              </Box>
              <Box>
                ${" "}
                {calculateDecimalDifference(
                  paymentImportDetails?.processingModel.systemCalculation
                    .totalToFTA,
                  paymentImportDetails?.processingModel.lenderCalculation
                    .totalToFTA
                )}
              </Box>
            </Stack>

            <Stack className="processing-alt-row" direction="row">
              <Box>Pass Thru Interest Rate</Box>
              <Box></Box>
              <Box>
                {formatPercent(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .passThruInterestRate
                )}
              </Box>
              <Box></Box>
            </Stack>

            <Stack sx={{ display: "none" }}>
              <Box>
                {formatDate(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .effectiveDate
                )}
                {formatDecimal(
                  paymentImportDetails?.processingModel.lenderCalculation
                    .totalToFTA
                )}
              </Box>
            </Stack>
          </Box>
        </CardContent>
      </Card>
      {isFetched ? (
        <Card
          elevation={4}
          sx={{
            mb: 4,
            width: "100%",
          }}
        >
          <CardHeader
            title="1502 Preview"
            subheader={
              <div>
                <Button onClick={() => setOpen(true)}>Add Activity</Button>
                &nbsp;
                {reconciled === null && (
                  <Button
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Reset
                  </Button>
                )}
              </div>
            }
          ></CardHeader>
          <CardContent>
            <Box sx={{ flexGrow: 1 }}>
              <History1502Table
                paymentImportId={paymentImportId}
                isReconciled={reconciled}
              />
            </Box>
          </CardContent>
        </Card>
      ) : undefined}
    </Stack>
  );
}
export default PaymentProcessingDetailPage;
