import React from "react";
import { useParams } from "react-router";
import { Box, Grid, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useQueryClient } from "react-query";
import { useGetLoanInterestRateById, useUpdateLoanInterestRate } from "api";

import EditLoanInterestRateForm from "./forms/EditLoanInterestRateForm";
import { useHistory } from "react-router-dom";

function LoanInterestRateDetailsPage() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const history = useHistory();

  const { loanInterestRateId: loanInterestRateIdParam } = useParams<{
    loanInterestRateId: string;
  }>();
  const loanInterestRateId = Number(loanInterestRateIdParam);

  const { data, queryKey } = useGetLoanInterestRateById(loanInterestRateId);

  const { mutate, ...status } = useUpdateLoanInterestRate({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(queryKey);
        enqueueSnackbar("Loan Interest Rate Updated", {
          variant: "success",
        });
        history.push(`/loans/${data?.loanInterestRate.loanId}`);
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item lg={12} xl={8} sx={{ display: "flex" }}>
          <Card
            elevation={4}
            sx={{ width: "100%", position: "relative", overflow: "unset" }}
          >
            <CardHeader
              sx={{
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "#ffffff",
                borderRadius: "1rem 1rem 0 0",

                "& .MuiCardHeader-action": {
                  width: "100%",
                },
              }}
              title="Information"
            />
            <CardContent>
              {data && (
                <Stack>
                  <EditLoanInterestRateForm
                    status={status}
                    loanInterestRate={data.loanInterestRate}
                    onSave={(form) =>
                      mutate({ loanInterestRateId, data: form })
                    }
                  />
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoanInterestRateDetailsPage;
