import {
  GridToolbarContainer,
  GridToolbarExport,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import {
  Stack,
  Button,
  TextField,
  IconButton,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { theme } from "theme";
import ClearIcon from "@mui/icons-material/Clear";
import { createRef, useState } from "react";
import { useDebounce } from "react-use";
import { PermitModel } from "api";

const taskFilterOptPrefix = "task-filter-";
const activityTypeFilterOptPrefix = "activity-type-filter-";

type FilterOptions = {
  label: string;
  value: boolean | number | string | null;
};

const activityTypeRadioValues: FilterOptions[] = [
  { value: 1, label: "Note" },
  { value: 2, label: "Document" },
  { value: null, label: "All" },
];

const isTaskRadioValues: FilterOptions[] = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
  { value: null, label: "All" },
];

const loanSoldRadioValues: FilterOptions[] = [
  { value: 1, label: "Sold" },
  { value: 2, label: "Unsold" },
  { value: null, label: "All" },
];

export interface DataGridToolbarProps {
  header: React.ReactNode;
  subheader?: React.ReactNode;
  addButtonLabel?: string | null;
  addButtonLink?: string;
  addModalButtonLabel?: string | null;
  activityTypeFilterLabel?: string;
  initialSearch?: string;
  isTaskLabel?: string;
  loanSoldFilterLabel?: string;
  exportButton?: boolean;
  exportHiddenCols?: boolean;
  permitModel?: PermitModel;
  onAdd?(): void;
  onAddModal?(open: boolean): boolean;
  onActivityTypeFilter?(type: number | null): void;
  onTaskFilter?(type: boolean | null): void;
  onLoanSoldFilter?(type: number | null): void;
  onSearch?(text: string): void;
}

function DataGridToolbar({
  header,
  subheader,
  addButtonLabel,
  addButtonLink,
  addModalButtonLabel,
  activityTypeFilterLabel,
  isTaskLabel,
  loanSoldFilterLabel,
  initialSearch,
  exportButton = true,
  exportHiddenCols = false,
  permitModel,
  onAdd,
  onAddModal,
  onActivityTypeFilter,
  onTaskFilter,
  onLoanSoldFilter,
  onSearch,
}: DataGridToolbarProps) {
  const [state, setState] = useState(initialSearch);
  const [activityState, setActivityState] = useState<number | null>(null);
  const [isTaskState, setIsTaskState] = useState<boolean | null>(null);
  const [loanSoldFilter, setLoanSoldFilter] = useState<number | null>(null);
  const searchFieldRef = createRef<HTMLInputElement>();
  const apiRef = useGridApiContext();

  useDebounce(
    () => {
      onSearch?.(state || "");
    },
    500,
    [state]
  );
  useDebounce(
    () => {
      onActivityTypeFilter?.(activityState);
    },
    100,
    [activityState]
  );

  useDebounce(
    () => {
      onTaskFilter?.(isTaskState);
    },
    100,
    [isTaskState]
  );

  useDebounce(
    () => {
      onLoanSoldFilter?.(loanSoldFilter);
    },
    100,
    [loanSoldFilter]
  );

  return (
    <GridToolbarContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{ pb: 2, "& MuiRadio-root": { padding: ".25rem" } }}
      >
        <Stack>
          <Typography
            variant="subtitle1"
            sx={{
              textTransform: "uppercase",
              fontSize: "1.25rem",
              fontWeight: 700,
            }}
          >
            {header}
          </Typography>
          {!!subheader && (
            <Typography variant="subtitle2">{subheader}</Typography>
          )}
        </Stack>
        <Stack direction="row">
          {!!onSearch && (
            <TextField
              fullWidth={false}
              autoFocus
              sx={{pr:2}}
              placeholder="Search..."
              inputRef={searchFieldRef}
              value={state}
              onChange={(e) => {
                setState(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      searchFieldRef.current?.focus();
                      setState("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            ></TextField>
          )}
          {!!loanSoldFilterLabel && (
            <>
              <Stack direction="row" sx={{ mr: 2 }}>
                <Stack alignItems="center" justifyContent="center">
                  <Typography component="span" sx={{ fontWeight: 700, pr: 2 }}>
                    {loanSoldFilterLabel}:
                  </Typography>
                </Stack>
                <RadioGroup
                  row
                  value={loanSoldFilter}
                  onChange={(event) => {
                    const soldTypeId = Number(event.target.value);
                    !soldTypeId
                      ? setLoanSoldFilter(null)
                      : setLoanSoldFilter(soldTypeId);
                  }}
                >
                  {loanSoldRadioValues.map((option, index) => (
                    <FormControlLabel
                      key={`loan-sold-filter-${index}`}
                      value={String(option.value)}
                      label={option.label}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </>
          )}
          {!!activityTypeFilterLabel && (
            <>
              <Stack direction="row" sx={{ mr: 2 }}>
                <Stack alignItems="center" justifyContent="center">
                  <Typography component="span" sx={{ fontWeight: 700, pr: 2 }}>
                    {activityTypeFilterLabel}:
                  </Typography>
                </Stack>
                <RadioGroup
                  row
                  value={activityState}
                  onChange={(event) => {
                    const activityTypeId = Number(event.target.value);
                    !activityTypeId
                      ? setActivityState(null)
                      : setActivityState(activityTypeId);
                  }}
                >
                  {activityTypeRadioValues.map((option, index) => (
                    <FormControlLabel
                      key={`${activityTypeFilterOptPrefix}${index}`}
                      value={String(option.value)}
                      label={option.label}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </>
          )}
          {!!isTaskLabel && (
            <>
              <Stack direction="row" sx={{ mr: 2 }}>
                <Stack alignItems="center" justifyContent="center">
                  <Typography component="span" sx={{ fontWeight: 700, pr: 2 }}>
                    {isTaskLabel}:
                  </Typography>
                </Stack>
                <RadioGroup
                  row
                  value={isTaskState}
                  onChange={(event) => {
                    if (event.target.value === "true") setIsTaskState(true);
                    else if (event.target.value === "false")
                      setIsTaskState(false);
                    else {
                      setIsTaskState(null);
                    }
                  }}
                >
                  {isTaskRadioValues.map((option, index) => (
                    <FormControlLabel
                      key={`${taskFilterOptPrefix}${index}`}
                      value={String(option.value)}
                      label={option.label}
                      control={<Radio />}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            </>
          )}
          <Stack direction="row"></Stack>
          {addModalButtonLabel && (
            <Button
              sx={{
                ml: 2,
                px: 2,
                color: theme.palette.secondary.main,
                minWidth: "auto",
                whiteSpace: "nowrap",
              }}
              onClick={() => onAddModal?.(true)}
            >
              {addModalButtonLabel}
            </Button>
          )}
          <Stack direction="row">
            {exportButton && (
              <GridToolbarExport
                csvOptions={{ allColumns: exportHiddenCols }}
                printOptions={{ disableToolbarButton: true }}
                sx={{ ml: 2, px: 2 }}
                onClick={() => {
                  apiRef.current.setPageSize(1000);
                }}
              />
            )}
            {(addButtonLabel || permitModel?.create) && (
              <Button
                sx={{
                  ml: 2,
                  px: 2,
                  color: theme.palette.secondary.main,
                  minWidth: "auto",
                  whiteSpace: "nowrap",
                }}
                onClick={() => onAdd?.()}
                href={addButtonLink}
              >
                {addButtonLabel}
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </GridToolbarContainer>
  );
}

export default DataGridToolbar;
