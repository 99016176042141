import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import { useGetAllInterestRates } from "api/interest-rate/interest-rate";

const columns: GridColumns = [
  {
    field: "RateCode",
    headerName: "Rate Code",
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/admin/rates/${row.baseRateCodeID}`}>
        {row.code}
      </Link>
    ),
    headerAlign: "left",
    align: "left",
  },
  {
    field: "rate",
    headerName: "Current Rate",
    flex: 1,
    valueFormatter: (params) => {
      let valueFormatted = '';
      const paramsValue = ((params.value as number) ?? 0) * 100;
      if(paramsValue) {
        valueFormatted = `${Number(paramsValue).toLocaleString()} %`;
      }
      return valueFormatted;
    },
    valueParser: (value) => Number(value) / 100,
  },
  {
    field: "effectiveDate",
    headerName: "Effective Date",
    flex: 1,
    valueFormatter: (params) => {
      return params.value ?? '';
    }
  },
];

interface Filters {
  searchQuery?: string;
}

function Toolbar() {
  return (
    <DataGridToolbar
      header={"Interest Rates"}
    />
  );
}

function AdminRates() {
  const { grid, query } = useDataGrid<Filters>("Rates", columns);
  const { data } = useGetAllInterestRates(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );
  
  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                autoHeight
                rowCount={data?.total}
                columns={columns}
                components={{
                  Toolbar: Toolbar,
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
export default AdminRates;
