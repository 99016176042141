import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { useGet1502History } from "api";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import formatDate from "shared/formatters/date";
import formatPercent from "shared/formatters/percentage";

const columns: GridColumns = [
  {
    field: "reportId",
    headerName: "Report Id",
    headerAlign: "left",
    align: "left",
    width: 75,
  },
  {
    field: "endOfPeriodDate",
    headerName: "Report End Of Period",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Report End</div>
        <div>Of Period</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "beginningPrincipal",
    headerName: "Principal Beginning of Period",

    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Principal</div>
        <div>Beginning of Period</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "endingPrincipal",
    headerName: "Principal - End of Period",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Principal</div>
        <div>End of Period</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "principalAndInterest",
    headerName: "Guaranty Portion Total P&I",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Guaranty</div>
        <div>Portion Total P&I</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "grossInterest",
    headerName: "Guaranty Gross Interest",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Guaranty</div>
        <div>Gross Interest</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "netInterest",
    headerName: "Guaranty Portion Net Interest",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Guaranty</div>
        <div>Portion Net Interest</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "guarantyPortionClosingBalance",
    headerName: "Guaranty Portion Closing Balance",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Guaranty Portion</div>
        <div>Closing Balance</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "principalPayment",
    headerName: "Guaranty Portion Principal",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Guaranty</div>
        <div>Portion Principal</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "totalPayment",
    headerName: "Total Payment",
    width: 150,
    headerAlign: "left",
    align: "left",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "serviceFee",
    headerName: "Participant Service Fee",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Participant</div>
        <div>Service Fee</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "totalToFTA",
    headerName: "Total to FTA",
    width: 100,
    headerAlign: "center",
    align: "center",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "interestPaidFromDate",
    headerName: "Interest Paid From Date",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Interest</div>
        <div>Paid From Date</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "interestPaidToDate",
    headerName: "Interest Paid To Date",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Interest</div>
        <div>Paid To Date</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "nextInstallmentDate",
    headerName: "Next Installment Date",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Next</div>
        <div>Installment Date</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "numberOfDays",
    headerName: "NumberOfDays",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Number</div>
        <div>Of Days</div>
      </div>
    ),
  },
  {
    field: "lenderLoanNumber",
    headerName: "Lender Loan Number",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Lender</div>
        <div>Loan Number</div>
      </div>
    ),
  },
  {
    field: "remittancePenalty",
    headerName: "Remittance Penalty",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Remittance</div>
        <div>Penalty</div>
      </div>
    ),
  },
  {
    field: "calendarBasis",
    headerName: "Calendar Basis",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Calendar </div>
        <div>Basis</div>
      </div>
    ),
  },
  {
    field: "sBAStatusCode",
    headerName: "SBA Status Code",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>SBA</div>
        <div>Status Code</div>
      </div>
    ),
  },
  {
    field: "interestRate",
    headerName: "Interest Rate",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Interest</div>
        <div>Rate</div>
      </div>
    ),
    valueFormatter: formatGridCell(formatPercent),
  },
  {
    field: "amountDisbursed",
    headerName: "Amount Disbursed",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Amount</div>
        <div>Disbursed</div>
      </div>
    ),
  },
  {
    field: "amountUndisbursed",
    headerName: "Amount Undisbursed",
    headerAlign: "left",
    align: "left",
    width: 150,
    renderHeader: () => (
      <div className="data-header-no-line-height">
        <div>Amount</div>
        <div>Undisbursed</div>
      </div>
    ),
  },
];

function Toolbar() {
  return <DataGridToolbar header={"1502 History"} />;
}

function Panel1502History() {
  const { grid, query } = useDataGrid("1502 History", columns);

  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);

  const { data: history1502, isFetching } = useGet1502History({
    loanId,
    ...query,
  });

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          {...grid}
          autoHeight={true}
          rows={history1502?.items ?? []}
          getRowId={(row) => `${row["id"]}_${row["fieldName"]}`}
          loading={isFetching}
          components={{
            Toolbar: Toolbar,
          }}
        />
      </Box>
    </Box>
  );
}

export default Panel1502History;
