import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import AddLenderForm from "./forms/AddLenderForm";
import { LendersAddLenderResult, useAddLender } from "api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

function LenderAddLenderPage() {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const { mutate, ...status } = useAddLender({
    mutation: {
      onSuccess(data: LendersAddLenderResult) {
        history.push(`/lenders/${data.item.id}`);
        enqueueSnackbar("Lender Added", {
          variant: "success",
        });
      },
    },
  });

  return (
    <Card elevation={4} sx={{ overflow: "unset" }}>
      <CardHeader title="Add Lender" />

      <CardContent>
        <AddLenderForm
          onSave={(form) => mutate({ data: form })}
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default LenderAddLenderPage;
