import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";

interface SignInFormProps {
  username?: string;
  onSignIn(username: string, password: string): void;
}

function SignInForm({ username, onSignIn }: SignInFormProps) {
  const { register, handleSubmit } = useForm<{
    username: string;
    password: string;
  }>({ defaultValues: { username } });

  const onSubmit = handleSubmit((form) =>
    onSignIn(form.username, form.password)
  );

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Card
        component="form"
        onSubmit={onSubmit}
        sx={{ maxWidth: 400, width: "100%" }}
        elevation={4}
      >
        <CardHeader title="Login" />
        <CardContent>
          <Stack spacing={2}>
            <TextField
              label="Username"
              {...register("username", { required: true })}
            />
            <TextField
              label="Password"
              type="password"
              {...register("password", { required: true })}
            />
            <Button
              sx={{ justifyContent: "flex-end", mt: 0 }}
              component={Link}
              to="/forgot-password"
              variant="text"
            >
              Forgot Password
            </Button>
          </Stack>
        </CardContent>
        <FormErrorAlert label="Access Denied" />
        <CardActions sx={{ justifyContent: "center" }}>
          <SubmitButton>Sign In</SubmitButton>
        </CardActions>
      </Card>
    </Stack>
  );
}

export default withFormStatus(SignInForm);
