import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import UserAddUserPage from "./UserAddUserPage";
import UserDetailPage from "./UserDetailPage";
import UserList from "./UserListPage";

function UserRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={UserList} />
      <Route path={`${path}/add`} component={UserAddUserPage} />
      <Route path={`${path}/:userId`} component={UserDetailPage} />
    </Switch>
  );
}

export default UserRoutes;
