import React from "react";
import { Redirect } from "react-router";
import { useForgotPasswordFlow } from "./auth/useForgotPasswordFlow";
import ForgotPasswordForm from "./forms/ForgotPasswordForm";

function ForgotPasswordPage() {
  const flow = useForgotPasswordFlow();

  switch (flow.step) {
    case "ForgotPasswordStep":
      return <ForgotPasswordForm {...flow} />;

    case "ResetPasswordStep":
      return <Redirect to={`/reset-password?username=${flow.username}`} />;

    case "ForgotPasswordCompleteStep":
      return <Redirect to="/" />;
  }
}

export default ForgotPasswordPage;
