import React, { useMemo } from "react";
import { InputAdornment, TextField as MuiTextField } from "@mui/material";
import clsx from "clsx";

import { faPercent } from "@fortawesome/free-solid-svg-icons";
import Icon from "shared/Icon";

import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";
import { useNumberMask } from "./useInputMask";

type PercentFieldProps<T> = FormTextFieldProps<
  T,
  {
    scale?: number;
    roundScale?: number;
  }
>;

function PercentField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  scale = 4,
  roundScale = 100,
  ...props
}: PercentFieldProps<T>) {
  const { getError } = useFormErrors();

  const maskOptions = useMemo(
    () => ({
      scale,
      thousandsSeparator: ",",
      radix: ".",
      padFractionalZeros: true,
    }),
    [scale]
  );

  const { inputRef, onBlur } = useNumberMask(
    {
      control,
      name,
      rules,
    },
    maskOptions,
    {
      // round to scale
      toForm: (v) => Number((v / roundScale).toFixed(scale + 2)),
      toInput: (v) => Number((v * roundScale).toFixed(scale)),
    }
  );

  return (
    <MuiTextField
      {...props}
      {...getError(name)}
      className={clsx(className, readOnly && "textfield-readonly")}
      onBlur={onBlur}
      inputRef={inputRef}
      inputProps={{ style: { textAlign: "right" } }}
      InputProps={{
        readOnly,
        endAdornment: (
          <InputAdornment position='end'>
            <Icon fixedWidth icon={faPercent} />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default asVariants(PercentField);
