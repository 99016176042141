import { Box, LinearProgress, Stack } from "@mui/material";
import React,{ useEffect } from "react";

interface Props {
  onComplete(): void;
}

function FileProcessing({ onComplete }: Props) {
    const [progress, setProgress] = React.useState(0);
    useEffect(() =>{
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
              if (100 === oldProgress) {
                  onComplete();
              }
              return oldProgress + 1;
            });
          }, 100);
        
          return () => {
            clearInterval(timer);
          };
    }, [onComplete]);

    return (
    <Stack sx={{width: 400, height:100 }}>
        <Box sx={{textAlign: 'center'}}>
            File Processing 
        </Box>
        <Box>
            <LinearProgress sx={{height: 20}} variant="determinate" value={progress} />
        </Box>
    </Stack>)
}
export default FileProcessing;