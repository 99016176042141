import React from "react";
import { useParams } from "react-router";
import { useGetLenderById, useUpdateLender } from "api";
import { Box, Grid, Card, CardContent, CardHeader } from "@mui/material";
import EditLenderForm from "./forms/EditLenderForm";
import RecentActivities from "activity/RecentActivities";
import { useSnackbar } from "notistack";

function LenderDetailPage() {
  const { lenderId: lenderIdParam } = useParams<{ lenderId: string }>();

  const lenderId = Number(lenderIdParam);
  const { data } = useGetLenderById(lenderId);
  const { enqueueSnackbar } = useSnackbar();

  const { mutate, ...status } = useUpdateLender({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Lender Updated.", {
          variant: "success",
        });
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} xl={8} sx={{ display: "flex" }}>
          <Card elevation={4} sx={{ overflow: "unset" }}>
            <CardHeader title='Information' />
            <CardContent>
              {data && (
                <EditLenderForm
                  status={status}
                  lender={data.lender}
                  permit={data.permit}
                  onSave={(form) => mutate({ lenderId, data: form })}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <RecentActivities lenderId={lenderId} />
      </Grid>
    </Box>
  );
}

export default LenderDetailPage;
