import React from "react";
import { Stack } from "@mui/material";
import TabMenuItem from "shared/layout/TabMenuItem";
import { icons } from "theme";
import useLenderContext from "shared/useLenderContext";
import { useGetLenderStatistics } from "api";

function LenderStatistics() {
  const { lenderId } = useLenderContext();

  const { data, refetch } = useGetLenderStatistics({ lenderId: lenderId });

  const {
    totalLenders,
    totalLoans,
    paymentsNeedsProcessing,
    disbursementsNeedsProcessing,
    reportsDue,
    needsAttention,
  } = data?.stats ?? {};

  window.addEventListener("refreshPaymentLenderStatistics", () => {
    refetch();
  });
  window.addEventListener("refreshDisbursementLenderStatistics", () => {
    refetch();
  });
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        display: { xs: "none", md: "flex" },
        height: "7rem",
      }}
    >
      {data?.permit.create && (
        <TabMenuItem
          label="Needs Attention"
          icon={icons.acivity}
          value={needsAttention}
          to="/activity"
        />
      )}
      <TabMenuItem
        label="Lenders"
        icon={icons.lender}
        value={totalLenders}
        to="/lenders"
      />

      <TabMenuItem
        label="Loans"
        icon={icons.loan}
        value={totalLoans}
        to="/loans"
      />
      {data?.permit.create && (
        <TabMenuItem
          label="Reports Due"
          icon={icons.reports}
          value={reportsDue}
          to="/reporting"
        />
      )}
      {data?.permit.delete && (
        <TabMenuItem
          label="Payment Processing"
          icon={icons.processing}
          value={paymentsNeedsProcessing}
          to="/processing/payments"
        />
      )}
      {data?.permit.delete && (
        <TabMenuItem
          label="Disbursement Processing"
          icon={icons.processing}
          value={disbursementsNeedsProcessing}
          to="/processing/disbursements"
        />
      )}
    </Stack>
  );
}

export default LenderStatistics;
