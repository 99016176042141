import { Path } from "react-hook-form";
import pick from "pick-deep";

function useFieldList<TForm extends object>(editable: Array<string>) {
  const editableSet = new Set(editable);

  const readOnly = (field: Path<TForm>) => !editableSet.has(field);

  const extractForm = (form: TForm): TForm => pick(form, editable) as TForm;

  return { readOnly, extractForm };
}

export default useFieldList;
