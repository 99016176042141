import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Modal,
} from "@mui/material";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import formatGridCell from "shared/formatters/grid";
import formatDate from "shared/formatters/date";
import useDataGrid from "shared/grid/useDataGrid";
import formatActivityType from "shared/formatters/formatActivityTypeGrid";
import { useAddActivity, useDeleteActivity, useGetAllActivities } from "api";
import AddActivity from "activity/forms/AddActivityForm";
import { useSnackbar } from "notistack";
import { icons } from "theme";
import Icon from "shared/Icon";
import useLenderContext from "shared/useLenderContext";
import { DeleteForever } from "@mui/icons-material";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";

function Toolbar(
  props: Pick<
    DataGridToolbarProps,
    "onAddModal" | "onActivityTypeFilter" | "onTaskFilter" | "permitModel"
  >
) {
  const { data } = useGetAllActivities({ pageSize: 1 });
  return (
    <DataGridToolbar
      header={"Activity List"}
      addModalButtonLabel={data?.permitModel.create ? "Add Activity" : null}
      activityTypeFilterLabel="Activity Type"
      isTaskLabel="Is a Task?"
      exportButton={false}
      {...props}
    />
  );
}
interface Filters {
  activityTypeQuery?: number;
}

function ActivityListPage() {
  const columns: GridColumns = [
    {
      field: "activityTitle",
      headerName: "Activity Title",
      flex: 1,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/activity/${row.id}`}>
          {row.activityTitle}
        </Link>
      ),
    },
    {
      field: "lenderName",
      headerName: "Lender",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "lenderLoanNumber",
      headerName: "Loan Number",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "activityTypeId",
      headerName: "Type",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: formatGridCell(formatActivityType),
    },
    {
      field: "activityDate",
      headerName: "Created Date",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "delete",
      headerName: "Delete Activity",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => (
        <Link
          onClick={() => {
            setDeleteId(row.id);
            setShowDeleteDialog(true);
          }}
        >
          <DeleteForever></DeleteForever>
        </Link>
      ),
    },
  ];
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const { lenderId } = useLenderContext();
  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const { mutate: activityMutate, ...activityStatus } = useAddActivity({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Activity Added", {
          variant: "success",
        });
        setOpen(false);
      },
    },
  });

  const { mutate: deleteActivityMutate } = useDeleteActivity({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Activity Deleted", {
          variant: "success",
        });
        refetch();
      },
    },
  });

  const { grid, query, updateActivityTypeQuery, updateIsTaskQuery } =
    useDataGrid<Filters>("activity", columns);

  const { data, isFetching, refetch } = useGetAllActivities(
    {
      lenderId,
      ...query,
    },
    { query: { keepPreviousData: true } }
  );
  function onActivityFilter(activityId: number | null) {
    updateActivityTypeQuery({ activityTypeQuery: activityId });
  }
  function onIsTaskFilter(isTaskId: boolean | null) {
    updateIsTaskQuery({ isTaskQuery: isTaskId });
  }
  return (
    <>
      <Card elevation={4} sx={{ flex: 1 }}>
        <CardContent sx={{ flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", height: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                <DataGridPro
                  {...grid}
                  rows={data?.items ?? []}
                  rowCount={data?.total}
                  loading={isFetching}
                  components={{
                    Toolbar: Toolbar,
                  }}
                  componentsProps={{
                    toolbar: {
                      onAddModal: (setModal: boolean) => {
                        return setOpen(setModal);
                      },
                      onActivityTypeFilter: (type: number | null) => {
                        onActivityFilter(type);
                      },
                      onTaskFilter: (task: boolean | null) => {
                        onIsTaskFilter(task);
                      },
                    },
                  }}
                  autoHeight
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
        <AcceptDialogWarning
          alertMessage="Are you sure you want to delete the Activity?"
          initialWarningDialog={showDeleteDialog}
          showDialog={(show) => {
            setShowDeleteDialog(show);
          }}
          acceptDelete={(accept) => {
            if (accept) {
              deleteActivityMutate({ activityId: deleteId });
              setDeleteId(0);
            }
          }}
        />
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          elevation={4}
          sx={{
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <CardHeader
            title="Add Activity"
            action={
              <Button sx={{ ml: 2 }} onClick={handleClose}>
                <Icon fixedWidth icon={icons.close} />
              </Button>
            }
          />
          <CardContent>
            <AddActivity
              onSave={(form) => {
                activityMutate({ data: form });
              }}
              status={activityStatus}
            />
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}
export default ActivityListPage;
