import { Autocomplete, TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps } from "./FieldVariants";

type DropDownFieldProps<T, TOptions> = FormTextFieldProps<
  T,
  {
    options: TOptions[];
    getOptionKey(option: TOptions): string | number;
    getOptionLabel(option: TOptions): string;
    disableClearable?: boolean;
  }
>;

function DropDownField<T, TOptions>({
  name,
  control,
  options,
  label,
  className,
  rules,
  readOnly = false,
  disableClearable = false,
  getOptionKey,
  getOptionLabel,
  ...props
}: DropDownFieldProps<T, TOptions>) {
  const { getError } = useFormErrors();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value: keyValue, ref } }) => {
        // autocomplete is controlled, so the value is either null or a matching option
        const currentOption: TOptions | null =
          options.find((opt) => getOptionKey(opt) === keyValue) ?? null;

        return (
          <Autocomplete
            {...props}
            disableClearable={disableClearable}
            value={currentOption}
            options={options}
            onChange={(_, newLabelValue) =>
              onChange(newLabelValue ? getOptionKey(newLabelValue) : null)
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            renderInput={({ InputProps, ...params }: any) => (
              <MuiTextField
                {...props}
                {...params}
                {...getError(name)}
                className={clsx(className, readOnly && "textfield-readonly")}
                label={label}
                onBlur={onBlur}
                inputRef={ref}
                InputProps={{ ...InputProps, readOnly }}
              />
            )}
          />
        );
      }}
    />
  );
}

export default DropDownField;
