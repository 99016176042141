import React from "react";
import { useParams } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { getAuditLog, getGetAuditLogQueryKey } from "api";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatDateTime from "shared/formatters/datetime";

function PanelHistory() {
  const { grid } = useDataGrid("history", columns);

  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);

  const {
    data: history,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery(
    getGetAuditLogQueryKey(loanId),
    ({ pageParam = 1 }) =>
      getAuditLog(loanId, { page: pageParam, pageSize: 10 }),
    {
      getNextPageParam: ({ page, pageCount }) =>
        page < pageCount ? page + 1 : undefined,
    }
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flexGrow: 1, minHeight: "600px" }}>
        <DataGridPro
          {...grid}
          rows={history?.pages.flatMap((page) => page.items) ?? []}
          getRowId={(row) => `${row["id"]}_${row["fieldName"]}`}
          loading={isFetching}
          components={{
            Toolbar: Toolbar,
          }}
          density="compact"
          hideFooterPagination
          onRowsScrollEnd={() => fetchNextPage()}
          disableColumnFilter
          disableColumnMenu
        />
      </Box>
    </Box>
  );
}

export default PanelHistory;

const columns: GridColumns = [
  {
    field: "timestamp",
    headerName: "Date",
    flex: 1,
    valueFormatter: formatGridCell(formatDateTime),
    sortable: false,
  },
  {
    field: "username",
    headerName: "User",
    flex: 1,
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    flex: 0.5,
    sortable: false,
  },

  {
    field: "displayName",
    headerName: "Field",
    flex: 1,
    sortable: false,
  },

  {
    field: "originalValue",
    headerName: "Original Value",
    flex: 1,
    sortable: false,
    headerAlign: "right",
    align: "right",
  },

  {
    field: "newValue",
    headerName: "New Value",
    flex: 1,
    sortable: false,
    headerAlign: "right",
    align: "right",
  },
];

function Toolbar() {
  return <DataGridToolbar header={"Loan History"} />;
}
