import { useGetLoanDropdowns } from "api";

function useLoanDropDownOptions() {
  const dropDownDefaults = {
    accuralBasis: [],
    baseRateCode: [],
    loanType: [],
    repaymentType: [],
    rateAdjustment: [],
    paymentFrequency: [],
    dayCode: [],
    prepayPenaltyCode: [],
    sbaStatusCode: [],
    guaranteedPortionSold: [],
    defermentType: [],
    loanPurposes: []
  };

  const { data } = useGetLoanDropdowns(undefined, {
    query: { placeholderData: dropDownDefaults },
  });

  return data;
}

export default useLoanDropDownOptions;
