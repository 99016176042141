/**
 *
 * @param value Decimal representation of a percentage.
 * @returns string of the parameter in percent form followed by '%'
 */
export default function formatPercent(
  value: number | string | null | undefined
) {
  let parcedNum = 0;

  switch (typeof value) {
    case "string":
      parcedNum = Number(value);
      break;
    case "number":
      parcedNum = value;
      break;
  }
  return `${(parcedNum * 100).toFixed(2)}%`;
}
