import { Box, Card, CardContent, Link } from "@mui/material";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import { useDeleteDisbursementImport, useGetDisbursementImports } from "api";
import { useSnackbar } from "notistack";
import { DeleteForever } from "@mui/icons-material";
import { useState } from "react";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";
import formatDate from "shared/formatters/date";
import useLenderContext from "shared/useLenderContext";

function Toolbar() {
  return <DataGridToolbar header={"Disbursement Processing List"} />;
}

function ProcessingListPage() {
  const columns: GridColumns = [
    {
      field: "sbaLoanNumber",
      headerName: "Loan ID",
      flex: 1,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/processing/disbursements/${row.id}`}>
          {row.sbaLoanNumber}
        </Link>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "borrowerName",
      headerName: "Borrower",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "lenderName",
      headerName: "Lender",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "amountDisbursed",
      headerName: "Disbursement Amount",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "reconciliationDate",
      headerName: "Reconciliation Status",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => (
        <div>{row.reconciliationDate === null ? "Pending" : "Completed"}</div>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Disbursement",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) =>
        row.reconciliationDate === null ? (
          <Link
            onClick={() => {
              setDeleteId(row.id);
              setShowDeleteDisbursementDialog(true);
            }}
          >
            <DeleteForever></DeleteForever>
          </Link>
        ) : (
          ""
        ),
    },
  ];
  const { grid, query } = useDataGrid("disbursements", columns);
  const { enqueueSnackbar } = useSnackbar();

  const { lender } = useLenderContext();

  const { data, isFetching, refetch } = useGetDisbursementImports(
    {
      lenderId: lender?.id,
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteDisbursementDialog, setShowDeleteDisbursementDialog] =
    useState<boolean>(false);

  const { mutate: deleteDisbursementMutate } = useDeleteDisbursementImport({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Disbursement Deleted", {
          variant: "success",
        });
      },
    },
  });

  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                autoHeight
                rowCount={data?.total}
                loading={isFetching}
                columns={columns}
                components={{
                  Toolbar: Toolbar,
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
      <AcceptDialogWarning
        alertMessage="Are you sure you want to delete the disbursement?"
        initialWarningDialog={showDeleteDisbursementDialog}
        showDialog={(show) => {
          setShowDeleteDisbursementDialog(show);
        }}
        acceptDelete={(accept) => {
          if (accept) {
            deleteDisbursementMutate({ disbursementImportId: deleteId });
            setDeleteId(0);
          }
        }}
      />
    </Card>
  );
}
export default ProcessingListPage;
