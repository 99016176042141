import React from "react";
import { useParams } from "react-router";
import { useQueryClient } from "react-query";
import {
  useGetLoanDetail,
  useUpdateLoanDetail,
} from "api";
import EditLoanDetailForm from "./forms/EditLoanDetailForm";
import InlineFormLayout from "shared/forms/InlineFormLayout";
import useLoanDropDownOptions from "shared/forms/useLoanDropDownOptions";


function PanelDetail() {
  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);

  const queryClient = useQueryClient();
  const { data, queryKey } = useGetLoanDetail(loanId);
  const loanDropDownOptions = useLoanDropDownOptions();

  const { mutate, ...status } = useUpdateLoanDetail({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(queryKey);
      },
    },
  });

  if (!data) return null;

  const { permit, loan } = data;

  return (
    <InlineFormLayout>
      <EditLoanDetailForm
        // initial data is provided
        loanDropDownOptions={loanDropDownOptions!}
        status={status}
        permit={permit}
        loan={loan}
        onSave={(form) => mutate({ loanId, data: form })}
      />
    </InlineFormLayout>
  );
}

export default PanelDetail;
