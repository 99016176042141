import React from "react";
import { Stack } from "@mui/material";

type Props = React.PropsWithChildren<{}>;

function StickyFooter({ children }: Props) {
  return (
    <Stack
      my={4}
      direction="row"
      justifyContent="flex-end"
      py={2}
      sx={{
        backgroundColor: "#ffffff",
        position: "sticky",
        bottom: "0",
        left: "0",
        zIndex: "2",
      }}
    >
      {children}
    </Stack>
  );
}

export default StickyFooter;
