import { Box, Card, CardContent, Link } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import { useGetAllPastDueReports } from "api";
import formatCurrency from "shared/formatters/currency";
import formatGridCell from "shared/formatters/grid";
import useLenderContext from "shared/useLenderContext";

const columns: GridColumns = [
  {
    field: "lenderLoanNumber",
    headerName: "Loan ID",
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/loans/${row.id}`}>
        {row.lenderLoanNumber}
      </Link>
    ),
  },
  {
    field: "lenderName",
    headerName: "Lender Name",
    flex: 1,
  },
  {
    field: "borrowerName",
    headerName: "Borrower Name",
    flex: 1,
  },
  {
    field: "nextDueDate",
    headerName: "Next Due Date",
    flex: 1,
    valueFormatter: (params) => {
      return params.value ?? "";
    },
  },

  {
    field: "daysPastDue",
    headerName: "Number of Days Past Due",
    flex: 1,
    valueFormatter: (params) => {
      return params.value ?? "";
    },
  },
  {
    field: "lastPaymentDate",
    headerName: "Last Payment Date",
    flex: 1,
    valueFormatter: (params) => {
      return params.value ?? "";
    },
  },
  {
    field: "lastPayment",
    headerName: "Last Payment Amount",
    flex: 1,
    valueFormatter: formatGridCell(formatCurrency),
  },
];

interface Filters {
  searchQuery?: string;
}

function Toolbar() {
  return <DataGridToolbar header={"Past Due"} />;
}

function PastDueReports() {
  const { grid, query } = useDataGrid<Filters>("Past Due Reports", columns);

  const { lenderId } = useLenderContext();


  const { data } = useGetAllPastDueReports(
    {
      ...query,
      lenderId
    },
    { query: { keepPreviousData: true } }
  );

  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                autoHeight
                rowCount={data?.total}
                columns={columns}
                components={{
                  Toolbar: Toolbar,
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
export default PastDueReports;
