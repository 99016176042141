import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  LoansGetLoanDetailLoanModel,
  LoansGetAllDropDownsResult,
  LoansUpdateLoanDetailForm,
  PermitModel,
  useAddOrUpdateDeferment,
} from "api";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  FormErrorAlert,
  SubmitButton,
  useFieldList,
  withFormStatus,
} from "shared/forms";
import TextField from "shared/forms/fields/TextField";
import DateField from "shared/forms/fields/DateField";
import MoneyField from "shared/forms/fields/MoneyField";
import PercentField from "shared/forms/fields/PercentField";
import DropDownField from "shared/forms/fields/DropDownField";
import StickyFooter from "shared/layout/StickyFooter";
import { icons } from "theme";
import Icon from "shared/Icon";
import EditDefermentForm from "./EditDefermentForm";
import { useUpdateEffect } from "react-use";

interface FormProps<LoansGetLoanDetailLoanModel, LoansUpdateLoanDetailForm> {
  permit: PermitModel;
  loan: LoansGetLoanDetailLoanModel;
  loanDropDownOptions: LoansGetAllDropDownsResult;
  onSave(form: LoansUpdateLoanDetailForm): void;
}

function EditLoanDetailForm({
  permit,
  loan,
  loanDropDownOptions,
  onSave,
}: FormProps<LoansGetLoanDetailLoanModel, LoansUpdateLoanDetailForm>) {
  const { control, handleSubmit, reset, setValue, watch } =
    useForm<LoansUpdateLoanDetailForm>({
      defaultValues: {
        ...loan,
      },
    });

  // when we reload loan data, reset the form
  useEffect(() => reset(loan), [loan, reset]);

  const { extractForm } = useFieldList<LoansUpdateLoanDetailForm>(
    permit.updateableFields
  );

  const [openDeferment, setOpenDeferment] = useState(false);
  const handleOpen = useCallback(() => {
    setOpenDeferment(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpenDeferment(false);
  }, []);

  const { mutate: saveDeferment } = useAddOrUpdateDeferment({
    mutation: {
      onSuccess: () => {
        handleClose();
      },
    },
  });
  // when we save the form, only save the fields that we can edit
  const onSubmit = handleSubmit((form) => onSave(extractForm(form)));
  const statusCodeWatch = watch("sbaStatusCodeId");
  function mapFields(grid: JSX.Element[]) {
    return grid.map((field, i) => (
      <Grid key={i} item xs={12} md={6}>
        {field}
      </Grid>
    ));
  }
  // to control whether Ceiling and Floor input are shown
  const [hidden, setHidden] = useState(false);

  const handleToggle = useCallback(() => {
    setHidden(!hidden);
    setValue("ceiling", null);
    setValue("floor", null);
  }, [hidden, setHidden, setValue]);

  useUpdateEffect(() => {
    if (statusCodeWatch === 1) {
      handleOpen();
    }
  }, [statusCodeWatch, handleOpen]);
  const generalInformation = [
    <TextField
      control={control}
      name='borrowerName'
      label='Borrower'
      required
    />,
    <TextField
      control={control}
      name='operatingName'
      label='DBA/Operating Name'
    />,
    <TextField control={control} name='id' label='Loan ID' />,
    <TextField control={control} name='lenderLoanNumber' label='Loan Number' />,
    <TextField control={control} name='lenderName' label='Lender' />,
    <DropDownField
      control={control}
      name='loanPurposeId'
      label='Loan Purpose'
      options={loanDropDownOptions.loanPurposes}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name='loanTypeId'
      label='Loan Type'
      options={loanDropDownOptions.loanType}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <MoneyField
      control={control}
      name='commitmentAmount'
      label='Commitment Amount'
    />,
    <MoneyField.Calculated
      control={control}
      name='amountDisbursed'
      label='Amount Disbursed'
      changeOverridden={(overridden: boolean) => {
        setValue("amountDisbursed.overridden", overridden);
      }}
    />,
    <MoneyField.Calculated
      control={control}
      name='remainingCommitment'
      label='Remaining Commitment'
      changeOverridden={(overridden: boolean) => {
        setValue("remainingCommitment.overridden", overridden);
      }}
    />,
    <MoneyField.Calculated
      control={control}
      name='principalBalance'
      label='Principal Balance'
      changeOverridden={(overridden: boolean) => {
        setValue("principalBalance.overridden", overridden);
      }}
    />,
    <DateField.Calculated
      control={control}
      name='accumulatetoDate'
      label='Accumulate to Date'
      changeOverridden={(overridden: boolean) => {
        setValue("accumulatetoDate.overridden", overridden);
      }}
    />,
    <DateField control={control} name='noteDate' label='Note Date' />,
    <DateField.Calculated
      control={control}
      name='firstDisbursementDate'
      label='First Disbursement Date'
      changeOverridden={(overridden: boolean) => {
        setValue("firstDisbursementDate.overridden", overridden);
      }}
    />,
    <DateField.Calculated
      control={control}
      name='maturityDate'
      label='Maturity Date'
      changeOverridden={(overridden: boolean) => {
        setValue("maturityDate.overridden", overridden);
      }}
    />,
    <TextField control={control} name='loanTerm' label='Loan Term' />,
    <TextField
      control={control}
      name='interestOnlyPeriod'
      label='Interest Only Period'
    />,
    <TextField
      control={control}
      name='amortizationPeriod'
      label='Amortization Period'
    />,
  ];

  const RatesRepaymentStructure = [
    <DropDownField
      control={control}
      name='baseRateCodeId'
      label='Base Rate Code'
      options={loanDropDownOptions.baseRateCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <PercentField.Calculated
      control={control}
      name='baseRatePercentage'
      label='Base Rate %'
      changeOverridden={(overridden: boolean) => {
        setValue("baseRatePercentage.overridden", overridden);
      }}
    />,
    <PercentField control={control} name='spread' label='Spread' />,
    <PercentField.Calculated
      control={control}
      name='effectiveRate'
      label='Effective Rate'
      changeOverridden={(overridden: boolean) => {
        setValue("effectiveRate.overridden", overridden);
      }}
    />,
    <MoneyField.Calculated
      control={control}
      name='accruedInterest'
      label='Accrued Interest'
      changeOverridden={(overridden: boolean) => {
        setValue("accruedInterest.overridden", overridden);
      }}
    />,
    <DropDownField
      control={control}
      name='rateAdjustmentId'
      label='Rate Adjustment Req'
      options={loanDropDownOptions.rateAdjustment}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DateField.Calculated
      control={control}
      name='nextRateChangeDate'
      label='Next Rate Change Date'
      changeOverridden={(overridden: boolean) => {
        setValue("nextRateChangeDate.overridden", overridden);
      }}
    />,
    <Stack spacing={1} sx={{ display: hidden ? "none" : "" }}>
      <PercentField
        control={control}
        name='floor'
        label='Floor'
        sx={{ display: hidden ? "none" : "" }}
      />
      <PercentField
        control={control}
        name='ceiling'
        label='Ceiling'
        sx={{ display: hidden ? "none" : "" }}
      />
    </Stack>,
    <DropDownField
      control={control}
      name='dayCodeId'
      label='Day Code'
      options={loanDropDownOptions.dayCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name='accuralBasisId'
      label='Accrual Basis'
      options={loanDropDownOptions.accuralBasis}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name='repaymentTypeId'
      label='Repayment Type'
      options={loanDropDownOptions.repaymentType}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <MoneyField.Calculated
      control={control}
      name='paymentAmount'
      label='Payment Amount'
      changeOverridden={(overridden: boolean) => {
        setValue("paymentAmount.overridden", overridden);
      }}
    />,
    <DropDownField
      control={control}
      name='paymentFrequencyId'
      label='Payment Frequency'
      options={loanDropDownOptions.paymentFrequency}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <TextField
      control={control}
      name='scheduledDueDay'
      label='Scheduled Due Day'
    />,
    <DateField.Calculated
      control={control}
      name='nextDueDate'
      label='Next Due Date'
      changeOverridden={(overridden: boolean) => {
        setValue("nextDueDate.overridden", overridden);
      }}
    />,
  ];
  const PrepayInfo = [
    <TextField
      control={control}
      name='prepayPenaltyDescription'
      label='Prepay Penalty Description'
    />,
    <TextField.Calculated
      control={control}
      name='prepaymentPenaltyAmount'
      label='Prepay Penalty Amount'
      changeOverridden={(overridden: boolean) => {
        setValue("prepaymentPenaltyAmount.overridden", overridden);
      }}
    />,
    <PercentField.Calculated
      control={control}
      name='prepaymentPenaltyPercentage'
      label='Prepay Penalty %'
      changeOverridden={(overridden: boolean) => {
        setValue("prepaymentPenaltyPercentage.overridden", overridden);
      }}
    />,
    <DateField.Calculated
      control={control}
      name='prepaymentExpirationDate'
      label='Prepay Exp Date'
      changeOverridden={(overridden: boolean) => {
        setValue("prepaymentExpirationDate.overridden", overridden);
      }}
    />,
  ];

  const SBA = [
    <DropDownField
      control={control}
      name='guaranteedPortionSoldId'
      label=' Guaranteed Portion Sold'
      options={loanDropDownOptions.guaranteedPortionSold}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <TextField control={control} name='sbagpNumber' label='SBA GP Number' />,
    <MoneyField
      control={control}
      name='sbaGuarantyFeePaidAmount'
      label='SBA Guaranty Fee Paid Amount'
    />,
    <DateField
      control={control}
      name='sbaGuarantyFeePaidDate'
      label='SBA Guaranty Fee Paid Date'
    />,
    <DateField
      control={control}
      name='sbaAuthorizationDate'
      label='SBA Authorization Date'
    />,
    <DropDownField
      control={control}
      disableClearable={true}
      name='sbaStatusCodeId'
      label='SBA Status Code'
      options={loanDropDownOptions.sbaStatusCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <PercentField
      control={control}
      name='sbaGuarantyFeeBasisPercent'
      label='SBA Ongoing Guaranty Fee Basis Point %'
      scale={6}
      roundScale={1}
    />,
    <DateField
      control={control}
      name='interestPaidFromDate'
      label='Interest Paid from Date'
    />,
    <PercentField
      control={control}
      name='sbagfPercent'
      label='SBA Guaranty %'
    />,
    <PercentField.Calculated
      control={control}
      name='serviceFeePercent'
      label='IFS Service Fee %'
    />,
  ];

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <FormErrorAlert />
        <Grid container spacing={4}>
          {mapFields(generalInformation)}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant='h5'>Rates / Repayment Structure</Typography>
        </Box>
        <Grid container spacing={4}>
          {mapFields(RatesRepaymentStructure)}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant='h5'>Prepay Info</Typography>
        </Box>
        <Grid container spacing={4}>
          {PrepayInfo.map((field, i) => (
            <Grid key={i} item xs={12} md={6}>
              {field}
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant='h5'>SBA</Typography>
        </Box>
        <Grid container spacing={4}>
          {mapFields(SBA)}
        </Grid>
        <Dialog open={openDeferment} onClose={handleClose}>
          <EditDefermentForm
            onSave={(form) =>
              saveDeferment({ data: { loanId: loan.id, ...form } })
            }
            deferment={loan?.defermentDetail}
            defermentOpt={loanDropDownOptions?.defermentType ?? []}
            action={
              <Button onClick={handleClose} sx={{ ml: 2 }}>
                <Icon fixedWidth icon={icons.close} />
              </Button>
            }
          />
        </Dialog>
        {permit.update && (
          <StickyFooter>
            <Stack spacing={1} direction='row'>
              {statusCodeWatch === 1 ? (
                <Button
                  size='medium'
                  onClick={() => handleOpen()}
                  hidden={statusCodeWatch !== 1}
                >
                  Deferment Info
                </Button>
              ) : undefined}
              <Button onClick={handleToggle}>
                {hidden ? "Show Ceiling/Floor" : "Hide Ceiling/Floor"}
              </Button>
              <SubmitButton variant='save' />
            </Stack>
          </StickyFooter>
        )}
      </Box>
    </form>
  );
}

export default withFormStatus(EditLoanDetailForm);
