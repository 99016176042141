import { Box, Link, Stack, Typography } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import {
  DisbursementsImportDisbursementLoanDisbursementModel,
  PaymentsImportPaymentsLoanPaymentModel,
  useDeleteDisbursementImport,
  useDeletePaymentImport,
} from "api";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link as RouterLink } from "react-router-dom";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import { useSnackbar } from "notistack";
import { DeleteForever } from "@mui/icons-material";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";
import formatDate from "shared/formatters/date";

interface ErrorLog {
  id: number;
  errorType: string;
  errorDescription: string;
}

interface LoanNeedsProcessing {
  id: number;
  loanId: string;
  borrower: string;
  effectiveDate: string;
  totalPayment: number;
  loanStatus: string;
  discrepancy: number;
}

interface DisbursementNeedsProcessing {
  id: number;
  loanId: number;
  borrower: string;
  effectiveDate: string;
  totalDisbursement: number;
  discrepancy: number;
  loanStatus: string;
}

const errorLogColumns: GridColumns = [
  {
    field: "errorType",
    headerName: "Error Type",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "errorDescription",
    headerName: "Error Description",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
];

interface Props {
  missingLoans: string[];
  paymentNotFound: string[];
  wrongLoans: string[];
  paymentImport: PaymentsImportPaymentsLoanPaymentModel[];
  disbursementImport: DisbursementsImportDisbursementLoanDisbursementModel[];
  reportStatus: string;
  refetchPayments: (refetch: boolean) => void;
  refetchDisbursements: (refetch: boolean) => void;
  paymentFileImport: (filesDropped: any[]) => void;
  disbursementFileImport: (filesDropped: any[]) => void;
}

function ImportResult({
  missingLoans,
  paymentNotFound,
  wrongLoans,
  paymentImport,
  disbursementImport,
  reportStatus,
  refetchPayments,
  refetchDisbursements,
  paymentFileImport,
  disbursementFileImport,
}: Props) {
  const loanNeedsProcessingColumns: GridColumns = [
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => (
        <span>
          <Link component={RouterLink} to={`/processing/${cell.row.id}`}>
            {cell.value}
          </Link>
        </span>
      ),
    },
    {
      field: "borrower",
      headerName: "Borrower",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "totalPayment",
      headerName: "Total Payment",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "loanStatus",
      headerName: "Loan Status",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "delete",
      headerName: "Delete Payment",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) =>
        row.loanStatus === "Needs Reconciliation" ? (
          <Link
            onClick={() => {
              setDeleteId(row.id);
              setShowDeletePaymentDialog(true);
            }}
          >
            <DeleteForever></DeleteForever>
          </Link>
        ) : (
          ""
        ),
    },
  ];
  const disbursementNeedsProcessingColumns: GridColumns = [
    {
      field: "loanId",
      headerName: "Loan ID",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderCell: (cell) => (
        <span>
          <Link
            component={RouterLink}
            to={`/processing/disbursements/${cell.row.id}`}
          >
            {cell.value}
          </Link>
        </span>
      ),
    },
    {
      field: "borrower",
      headerName: "Borrower",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "totalDisbursement",
      headerName: "Total Disbursement",
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "loanStatus",
      headerName: "Loan Status",
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "delete",
      headerName: "Delete Disbursement",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) =>
        row.loanStatus === "Needs Reconciliation" ? (
          <Link
            onClick={() => {
              setDeleteId(row.id);
              setShowDeleteDisbursementDialog(true);
            }}
          >
            <DeleteForever></DeleteForever>
          </Link>
        ) : (
          ""
        ),
    },
  ];
  useDataGrid("Report List", errorLogColumns);

  const errors: ErrorLog[] = new Array<ErrorLog>();

  const loanNeedsProcessing: LoanNeedsProcessing[] =
    new Array<LoanNeedsProcessing>();

  const disbursementNeedsProcessing: DisbursementNeedsProcessing[] =
    new Array<DisbursementNeedsProcessing>();

  const [importErrors, setImportErrors] = useState([] as string[]);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteId, setDeleteId] = useState<number>(0);

  const [showDeletePaymentDialog, setShowDeletePaymentDialog] =
    useState<boolean>(false);

  const [showDeleteDisbursementDialog, setShowDeleteDisbursementDialog] =
    useState<boolean>(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (filesDropped: any[], _rejectedFiles: any[]) => {
      filesDropped.forEach((file) => {
        const fileName: string = file.path;
        if (fileName.includes("Payment")) {
          paymentFileImport(file);
        } else if (fileName.includes("Disbursement")) {
          disbursementFileImport(file);
        } else {
          const errorList: string[] = [];
          errorList.push("File name must include 'Payment' or 'Disbursement'");
          setImportErrors(errorList);
        }
      });
    },
  });

  const { mutate: deletePaymentMutate } = useDeletePaymentImport({
    mutation: {
      onSuccess(data) {
        enqueueSnackbar("Payment Deleted", {
          variant: "success",
        });
        refetchPayments(true);
      },
    },
  });

  const { mutate: deleteDisbursementMutate } = useDeleteDisbursementImport({
    mutation: {
      onSuccess(data) {
        enqueueSnackbar("Disbursement Deleted", {
          variant: "success",
        });
        refetchDisbursements(true);
      },
    },
  });

  missingLoans.forEach((missingLoan) => {
    errors.push({
      id: errors.length + 1,
      errorType: "Missing Payment Data",
      errorDescription: `Loan  ${missingLoan} did not have any data associated with it`,
    });
  });

  paymentNotFound.forEach((nf) => {
    errors.push({
      id: errors.length + 1,
      errorType: "Missing Payment Data",
      errorDescription: `Loan  ${nf} was found in the report but was not detected in the system`,
    });
  });

  wrongLoans.forEach((nf) => {
    errors.push({
      id: errors.length + 1,
      errorType: "Wrong Loans",
      errorDescription: `Loan  ${nf} does not belong to this Lender`,
    });
  });

  paymentImport.forEach((pi) => {
    loanNeedsProcessing.push({
      id: pi.id,
      borrower: pi.borrower!,
      loanId: pi.sbaLoanNumber.toString(),
      effectiveDate: pi.effectiveDate,
      totalPayment: pi.totalPayment,
      discrepancy: 0,
      loanStatus: pi.isReconciled ? "Approved" : "Needs Reconciliation",
    });
  });

  disbursementImport.forEach((pi) => {
    disbursementNeedsProcessing.push({
      id: pi.id,
      loanId: pi.sbaLoanNumber!,
      totalDisbursement: pi.totalDisbursement,
      borrower: pi.borrower!,
      effectiveDate: pi.effectiveDate,
      discrepancy: 0,
      loanStatus: pi.isReconciled ? "Approved" : "Needs Reconciliation",
    });
  });

  return (
    <Box sx={{ pt: 3 }}>
      {reportStatus !== "Approved" && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textTransform: "uppercase" }}
          >
            Upload New File
          </Typography>

          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              flex: "1",
              backgroundColor: "gray.light",
              my: 2,
              p: 4,
            }}
          >
            <Typography style={{ color: "red" }}>
              {importErrors.map((error) => (
                <div>{error}</div>
              ))}
            </Typography>
            <Box
              sx={{
                p: 2,
                backgroundColor: "#fff",
                border: "2px dashed",
                borderColor: "secondary.main",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "gray.light",
                  "&:hover": { backgroundColor: "grid.rowHover" },
                }}
                {...getRootProps({ className: "dropzone" })}
              >
                <input {...getInputProps()} />
                <Typography sx={{ fontWeight: 700 }}>
                  Drag 'n' drop payment or disbursement file here, or click to
                  select file
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      )}
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textTransform: "uppercase" }}
      >
        Error Log
      </Typography>

      <Box sx={{ flexGrow: 2 }}>
        <DataGridPro columns={errorLogColumns} rows={errors} autoHeight />
      </Box>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textTransform: "uppercase" }}
      >
        Disbursements
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          columns={disbursementNeedsProcessingColumns}
          rows={disbursementNeedsProcessing}
          autoHeight
        />
      </Box>
      <Typography
        variant="h6"
        sx={{ fontWeight: 700, textTransform: "uppercase" }}
      >
        Payments
      </Typography>

      <Box sx={{ flexGrow: 1 }}>
        <DataGridPro
          columns={loanNeedsProcessingColumns}
          rows={loanNeedsProcessing}
          autoHeight
        />
      </Box>
      <AcceptDialogWarning
        alertMessage="Are you sure you want to delete the payment?"
        initialWarningDialog={showDeletePaymentDialog}
        showDialog={(show) => {
          setShowDeletePaymentDialog(show);
        }}
        acceptDelete={(accept) => {
          if (accept) {
            deletePaymentMutate({ paymentImportId: deleteId });
            setDeleteId(0);
          }
        }}
      />
      <AcceptDialogWarning
        alertMessage="Are you sure you want to delete the disbursement?"
        initialWarningDialog={showDeleteDisbursementDialog}
        showDialog={(show) => {
          setShowDeleteDisbursementDialog(show);
        }}
        acceptDelete={(accept) => {
          if (accept) {
            deleteDisbursementMutate({ disbursementImportId: deleteId });
            setDeleteId(0);
          }
        }}
      />
    </Box>
  );
}
export default ImportResult;
