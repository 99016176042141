import React from "react";
import {
  Box,
  Grid,
  Tab,
  Tabs,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import PanelDetail from "./PanelDetail";
import PanelParticipation from "./PanelParticipation";
import PanelDisbursements from "./PanelDisbursements";
import PanelPayments from "./PanelPayments";
import PanelHistory from "./PanelHistory";
import { useParams } from "react-router-dom";
import RecentActivities from "activity/RecentActivities";
import { useGetLoanDetail } from "api";
import PanelHistory1502 from "./PanelHistory1502";
import PanelLoanRateHistory from "./PanelLoanRateHistory";
import PanelDeferments from "./PanelDeferments";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function LoanDetailPage() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);

  const { data: loan } = useGetLoanDetail(loanId);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} xl={8} sx={{ display: "flex" }}>
          <Card
            elevation={4}
            sx={{ width: "100%", position: "relative", overflow: "unset" }}
          >
            <CardHeader
              sx={{
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "#ffffff",
                borderRadius: "1rem 1rem 0 0",

                "& .MuiCardHeader-action": {
                  width: "100%",
                },
              }}
              action={
                <Box sx={{ maxWidth: "100vw", width: "100%" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Loan Detail Navigation"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Details" {...a11yProps(0)} />
                      <Tab label="Participation"  disabled={loan?.loan.guaranteedPortionSoldId === 1 ? false : true} {...a11yProps(1)} />
                    <Tab label="Disbursements" {...a11yProps(2)} />
                    <Tab label="Payments" {...a11yProps(3)} />
                    <Tab label="History" {...a11yProps(4)} />
                    <Tab label="1502 History" {...a11yProps(5)} />
                    <Tab label="Interest Rate History" {...a11yProps(6)} />
                    <Tab label="Deferments" {...a11yProps(7)} />

                  </Tabs>
                </Box>
              }
            />
            <CardContent>
              <Box>
                <TabPanel value={value} index={0}>
                  <PanelDetail></PanelDetail>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <PanelParticipation loan={loan}></PanelParticipation>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <PanelDisbursements></PanelDisbursements>
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <PanelPayments isSold={loan?.loan.guaranteedPortionSoldId === 1}></PanelPayments>
                </TabPanel>

                <TabPanel value={value} index={4}>
                  <PanelHistory></PanelHistory>
                </TabPanel>
                <TabPanel value={value} index={5}>
                  <PanelHistory1502></PanelHistory1502>
                </TabPanel>
                <TabPanel value={value} index={6}>
                  <PanelLoanRateHistory></PanelLoanRateHistory>
                </TabPanel>
                <TabPanel value={value} index={7}>
                  <PanelDeferments ></PanelDeferments>
                </TabPanel>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <RecentActivities lenderId={loan?.loan.lenderId!} loanId={loanId} />
      </Grid>
    </Box>
  );
}
export default LoanDetailPage;
