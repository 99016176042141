/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PaymentsGetPaymentsResult,
  GetPaymentsParams,
  PaymentsAddPaymentResult,
  PaymentsAddPaymentForm,
  PaymentsImportPaymentsResult,
  PaymentsImportBody,
  PaymentsImportParams,
  PaymentsGetPaymentImportsResult,
  GetPaymentImportsParams,
  PaymentsGetPaymentImportResult,
  GetPaymentImportParams,
  PaymentsGetPaymentImportResultResult,
  GetPaymentImportResultParams,
  PaymentsPaymentImportReconciliationResult,
  PaymentsPaymentImportReconciliationForm,
  SetPaymentImportReconciliationParams,
  PaymentsDeletePaymentImportResult,
  PaymentsDeletePaymentImportsByReportIdResult
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getPayments = (
    loanId: number,
    params?: GetPaymentsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsGetPaymentsResult>(
      {url: `/payment/${loanId}`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetPaymentsQueryKey = (loanId: number,
    params?: GetPaymentsParams,) => [`/payment/${loanId}`, ...(params ? [params]: [])];

    
export const useGetPayments = <TData = AsyncReturnType<typeof getPayments>, TError = unknown>(
 loanId: number,
    params?: GetPaymentsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getPayments>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentsQueryKey(loanId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getPayments>> = () => getPayments(loanId,params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getPayments>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const addPayment = (
    loanId: number,
    paymentsAddPaymentForm: PaymentsAddPaymentForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsAddPaymentResult>(
      {url: `/payment/${loanId}`, method: 'post',
      data: paymentsAddPaymentForm
    },
      options);
    }
  


    export const useAddPayment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addPayment>, TError,{loanId: number;data: PaymentsAddPaymentForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addPayment>, {loanId: number;data: PaymentsAddPaymentForm}> = (props) => {
          const {loanId,data} = props || {};

          return  addPayment(loanId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addPayment>, TError, {loanId: number;data: PaymentsAddPaymentForm}, TContext>(mutationFn, mutationOptions)
    }
    export const paymentsImport = (
    paymentsImportBody: PaymentsImportBody,
    params?: PaymentsImportParams,
 options?: SecondParameter<typeof apiRequest>) => {const formData = new FormData();
if(paymentsImportBody.file !== undefined) {
 formData.append('file', paymentsImportBody.file)
 }

      return apiRequest<PaymentsImportPaymentsResult>(
      {url: `/payment/paymentsimport`, method: 'post',
       data: formData,
        params,
    },
      options);
    }
  


    export const usePaymentsImport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof paymentsImport>, TError,{data: PaymentsImportBody;params?: PaymentsImportParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof paymentsImport>, {data: PaymentsImportBody;params?: PaymentsImportParams}> = (props) => {
          const {data,params} = props || {};

          return  paymentsImport(data,params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof paymentsImport>, TError, {data: PaymentsImportBody;params?: PaymentsImportParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getPaymentImports = (
    params?: GetPaymentImportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsGetPaymentImportsResult>(
      {url: `/payment/getpaymentimports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetPaymentImportsQueryKey = (params?: GetPaymentImportsParams,) => [`/payment/getpaymentimports`, ...(params ? [params]: [])];

    
export const useGetPaymentImports = <TData = AsyncReturnType<typeof getPaymentImports>, TError = unknown>(
 params?: GetPaymentImportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getPaymentImports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentImportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getPaymentImports>> = () => getPaymentImports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getPaymentImports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getPaymentImport = (
    params?: GetPaymentImportParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsGetPaymentImportResult>(
      {url: `/payment/getpaymentimport`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetPaymentImportQueryKey = (params?: GetPaymentImportParams,) => [`/payment/getpaymentimport`, ...(params ? [params]: [])];

    
export const useGetPaymentImport = <TData = AsyncReturnType<typeof getPaymentImport>, TError = unknown>(
 params?: GetPaymentImportParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getPaymentImport>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentImportQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getPaymentImport>> = () => getPaymentImport(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getPaymentImport>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getPaymentImportResult = (
    params?: GetPaymentImportResultParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsGetPaymentImportResultResult>(
      {url: `/payment/getpaymentimportresult`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetPaymentImportResultQueryKey = (params?: GetPaymentImportResultParams,) => [`/payment/getpaymentimportresult`, ...(params ? [params]: [])];

    
export const useGetPaymentImportResult = <TData = AsyncReturnType<typeof getPaymentImportResult>, TError = unknown>(
 params?: GetPaymentImportResultParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getPaymentImportResult>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetPaymentImportResultQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getPaymentImportResult>> = () => getPaymentImportResult(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getPaymentImportResult>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const setPaymentImportReconciliation = (
    paymentsPaymentImportReconciliationForm: PaymentsPaymentImportReconciliationForm,
    params?: SetPaymentImportReconciliationParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsPaymentImportReconciliationResult>(
      {url: `/payment/paymentimportreconciliation`, method: 'post',
      data: paymentsPaymentImportReconciliationForm,
        params,
    },
      options);
    }
  


    export const useSetPaymentImportReconciliation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof setPaymentImportReconciliation>, TError,{data: PaymentsPaymentImportReconciliationForm;params?: SetPaymentImportReconciliationParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof setPaymentImportReconciliation>, {data: PaymentsPaymentImportReconciliationForm;params?: SetPaymentImportReconciliationParams}> = (props) => {
          const {data,params} = props || {};

          return  setPaymentImportReconciliation(data,params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof setPaymentImportReconciliation>, TError, {data: PaymentsPaymentImportReconciliationForm;params?: SetPaymentImportReconciliationParams}, TContext>(mutationFn, mutationOptions)
    }
    export const deletePaymentImport = (
    paymentImportId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsDeletePaymentImportResult>(
      {url: `/payment/deletepaymentimport/${paymentImportId}`, method: 'post'
    },
      options);
    }
  


    export const useDeletePaymentImport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deletePaymentImport>, TError,{paymentImportId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deletePaymentImport>, {paymentImportId: number}> = (props) => {
          const {paymentImportId} = props || {};

          return  deletePaymentImport(paymentImportId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deletePaymentImport>, TError, {paymentImportId: number}, TContext>(mutationFn, mutationOptions)
    }
    export const deletePaymentImportsByReportId = (
    reportId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<PaymentsDeletePaymentImportsByReportIdResult>(
      {url: `/payment/deletepaymentimportsbyreportid/${reportId}`, method: 'post'
    },
      options);
    }
  


    export const useDeletePaymentImportsByReportId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deletePaymentImportsByReportId>, TError,{reportId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deletePaymentImportsByReportId>, {reportId: number}> = (props) => {
          const {reportId} = props || {};

          return  deletePaymentImportsByReportId(reportId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deletePaymentImportsByReportId>, TError, {reportId: number}, TContext>(mutationFn, mutationOptions)
    }
    