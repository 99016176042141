import React, { useEffect, useMemo, useState } from "react";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { AuthContext, AuthState, userPool } from "./context";

interface AuthProviderProps {
  publicRoutes: React.ReactNode;
  onStateChange?: (state: AuthState) => void;
}

function AuthProvider({
  onStateChange,
  publicRoutes,
  children: protectedRoutes,
}: React.PropsWithChildren<AuthProviderProps>) {
  const [pending, setPending] = useState(true);
  const [state, update] = useState<AuthState>({ authenticated: false });
  const value = useMemo(() => ({ ...state, update }), [state, update]);

  useEffect(() => {
    // attempt to sign in automatically
    const user = userPool.getCurrentUser();
    if (!user) {
      // there is no user to sign in
      setPending(false);
      return;
    }
    // try to get the user's session
    user.getSession(function (_: null, session: CognitoUserSession) {
      setPending(false);
      // if we have a session, then we're re-authenticated
      if (session?.isValid()) {
        update({
          authenticated: true,
          user,
        });
      }
    });
  }, []);

  useEffect(() => {
    onStateChange?.(state);
  }, [state, onStateChange]);

  // waiting on automatic sign-in
  if (pending) return null;

  return (
    <AuthContext.Provider value={value}>
      {state.authenticated ? protectedRoutes : publicRoutes}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
