import React, { useEffect } from "react";
import { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { Control, ControllerProps, Path, useWatch } from "react-hook-form";
import clsx from "clsx";

export type FormFieldProps<T> = {
  id?: string;
  name: Path<T>;
  control: Control<T, any>;
  readOnly?: boolean;
  multiline?: boolean;
  rows?: number;
  rules?: ControllerProps["rules"];
  placeholder?: string;
  required?: boolean;
  className?: string;
  variant?: "outlined" | "filled" | "standard";
  changeOverridden?: (overridden: boolean) => void;
  // restoreExpected?: (expectedValue: number | null | undefined) => void;
};

export type FormTextFieldProps<T, TProps = unknown> = FormFieldProps<T> &
  InheritedTextFieldProps &
  TProps;

type InheritedTextFieldProps = Pick<
  MuiTextFieldProps,
  "id" | "label" | "className" | "sx" | "helperText"
>;

type FormComponent = <T>(props: FormFieldProps<T>) => JSX.Element;

export const asVariants = <TComponent extends FormComponent>(
  Component: TComponent
) => {
  return Object.assign(Component, {
    Calculated: calculatedVariant(Component),
  });
};

const calculatedVariant = <TComponent extends FormComponent>(
  Component: TComponent
): TComponent =>
  (<T extends object>({
    control,
    name,
    className,
    multiline,
    rows,
    placeholder,
    required,
    changeOverridden,
    // restoreExpected,
    ...props
  }: FormFieldProps<T>) => {
    const watch = useWatch({
      control,
      name,
    }) as
      | { value: unknown; overridden: boolean; expected: unknown }
      | undefined;

    const { value, overridden, expected } = watch ?? { overridden: false };

    useEffect(() => {
      changeOverridden?.(value !== expected);
    }, [changeOverridden, value, expected]);

    return (
      <Component
        control={control}
        name={`${name}.value` as Path<T>}
        className={clsx(className, "calculated", overridden && "overridden")}
        // helperText={helperText}
        multiline={multiline}
        rows={rows}
        placeholder={placeholder}
        required={required}
        {...(props as any)}
      />
    );
  }) as TComponent;
