import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ActivityDetailPage from "./ActivityDetailPage";
import ActivityListPage from "./ActivityListPage";

function ActivitiesRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ActivityListPage} />
      <Route path={`${path}/:activityId`} component={ActivityDetailPage} />
    </Switch>
  );
}

export default ActivitiesRoutes;
