import React from "react";
import { useParams } from "react-router";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import { useSnackbar } from "notistack";
import EditActivityForm from "./forms/EditActivityForm";
import { useQueryClient } from "react-query";
import { useGetActivityById, useUpdateActivity } from "api";
import { Link as RouterLink } from "react-router-dom";

import RecentActivities from "./RecentActivities";

function ActivityDetailPage() {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { activityId: activityIdParam } = useParams<{ activityId: string }>();
  const activityId = Number(activityIdParam);

  const { data, queryKey } = useGetActivityById(activityId);

  const loanId = data?.activity.loanId;
  const lenderId = data?.activity.lenderId;

  const { mutate, ...status } = useUpdateActivity({
    mutation: {
      onSuccess() {
        queryClient.invalidateQueries(queryKey);
        enqueueSnackbar("Activity Added", {
          variant: "success",
        });
      },
    },
  });

  if (!data) return null;

  const { activity, permit } = data;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item lg={12} xl={8} sx={{ display: "flex" }}>
          <Card
            elevation={4}
            sx={{ width: "100%", position: "relative", overflow: "unset" }}
          >
            <CardHeader
              sx={{
                position: "sticky",
                top: "0",
                zIndex: "2",
                backgroundColor: "#ffffff",
                borderRadius: "1rem 1rem 0 0",

                "& .MuiCardHeader-action": {
                  width: "100%",
                },
              }}
              title="Information"
            />
            <CardContent>
              {data && (
                <Stack>
                  <Typography
                    component="h2"
                    sx={{ fontWeight: "700", paddingBottom: 1 }}
                  >
                    <Link
                      component={RouterLink}
                      to={`/loans/${activity.loanId}`}
                    >
                      Lender: {activity.lenderName} -{" "}
                      {activity.lenderLoanNumber}
                    </Link>
                  </Typography>

                  <EditActivityForm
                    status={status}
                    permit={permit}
                    activity={activity}
                    onSave={(form) => mutate({ activityId, data: form })}
                  />
                </Stack>
              )}
            </CardContent>
          </Card>
        </Grid>

        <RecentActivities lenderId={lenderId!} loanId={loanId ?? undefined} />
      </Grid>
    </Box>
  );
}

export default ActivityDetailPage;
