import React, { useContext, useMemo, useState } from "react";

interface ActiveLender {
  id: number;
  name: string;
  firs: string;
}

interface ActiveLenderContext {
  lender?: ActiveLender;
  lenderId?: ActiveLender["id"];
  select(lender: ActiveLender): void;
  clear(): void;
}

const Context = React.createContext<ActiveLenderContext>({
  select() {
    // no-op
  },
  clear() {
    // no-op
  },
});

function useLenderContext(): ActiveLenderContext {
  return useContext(Context);
}

export default useLenderContext;

export function LenderContextProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [lender, setLender] = useState<ActiveLender | undefined>(undefined);

  const value = useMemo(() => {
    function set(next: ActiveLender | undefined) {
      setLender(next);
    }

    return {
      lender,
      lenderId: lender?.id,
      select(next: ActiveLender) {
        set(next);
      },
      clear() {
        set(undefined);
      },
    };
  }, [lender, setLender]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
