import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";

type TextFieldProps<T> = FormTextFieldProps<T>;

function TextField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  multiline = false,
  placeholder,
  required,
  ...props
}: TextFieldProps<T>) {
  const { getError } = useFormErrors();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <MuiTextField
          {...props}
          {...getError(name)}
          className={clsx(className, readOnly && "textfield-readonly")}
          value={value ?? ""}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          InputProps={{ readOnly, placeholder, required }}
          multiline={multiline}
        />
      )}
    />
  );
}

export default asVariants(TextField);
