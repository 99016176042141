/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  GetDocumentByKeyParams
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDocumentByKey = (
    params?: GetDocumentByKeyParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<void>(
      {url: `/documents`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDocumentByKeyQueryKey = (params?: GetDocumentByKeyParams,) => [`/documents`, ...(params ? [params]: [])];

    
export const useGetDocumentByKey = <TData = AsyncReturnType<typeof getDocumentByKey>, TError = unknown>(
 params?: GetDocumentByKeyParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDocumentByKey>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDocumentByKeyQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDocumentByKey>> = () => getDocumentByKey(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDocumentByKey>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

