import { Box, Card, CardContent, Link } from "@mui/material";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import useDataGrid from "shared/grid/useDataGrid";
import { useGetAllReports } from "api/reports/reports";
import Download1502Link from "shared/documents/Download1502Link";
import formatGridCell from "shared/formatters/grid";
import formatDate from "shared/formatters/date";
import useLenderContext from "shared/useLenderContext";
import { Upload } from "@mui/icons-material";

const columns: GridColumns = [
  {
    field: "id",
    flex: 1,
    headerName: "Upload Files",
    headerAlign: "left",
    align: "left",
    renderCell: (row) =>
      row.row.statusDescription === "Approved" ? (
        <Link component={RouterLink} to={`/reporting/${row.value}`}>
          Approved
        </Link>
      ) : (
        <Link component={RouterLink} to={`/reporting/${row.value}`}>
          <Upload></Upload>
        </Link>
      ),
  },
  {
    field: "lenderName",
    headerName: "Lender",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "reportEndOfPeriod",
    headerName: "Report End Of Period",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "reportingMonth",
    headerName: "Reporting Month",
    flex: 1,
    headerAlign: "right",
    align: "right",
  },
  {
    field: "gracePeriod",
    headerName: "Grace Period",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "statusDescription",
    headerName: "Status",
    flex: 1,
    headerAlign: "right",
    align: "right",
    renderCell: (row) => {
      return <span>{row.value}</span>;
    },
  },
  {
    field: "downloadReport",
    headerName: "Download Report",
    flex: 1,
    headerAlign: "right",
    align: "right",
    renderCell: (row) => {
      return (
        <span>
          <Download1502Link
            reportDate={row.row.dueDate}
            reportId={row.row.id}
            lenderName={row.row.lenderName}
          ></Download1502Link>
        </span>
      );
    },
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onSearch">) {
  return <DataGridToolbar header={"1502 Reports"} {...props} />;
}

function ReportingListPage() {
  const { grid, query, updateSearchQuery } = useDataGrid("reportlist", columns);

  const { lenderId } = useLenderContext();

  const { data, isFetching } = useGetAllReports(
    {
      lenderId,
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                autoHeight
                columns={columns}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
export default ReportingListPage;
