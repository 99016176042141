import React from "react";
import logo from "../../logo.svg";
import { icons } from "../../theme";
import NavMenuItem from "./NavMenuItem";
import PageHeader from "./PageHeader";
import { Box, Stack, ButtonBase } from "@mui/material";
import LenderSelector from "lenders/LenderSelector";
import LenderStatistics from "lenders/LenderStatistics";
import { useState } from "react";
import Icon from "../Icon";
import { useGetAllLenders } from "api";

type Props = React.PropsWithChildren<{}>;

function Shell({ children }: Props) {
  const [state, setState] = useState("");

  const toggleMenu = () => {
    setState(state === "" ? "close" : "");
  };

  const { data: lender } = useGetAllLenders({ pageSize: 1 });

  return (
    <Box
      className={`${state}`}
      sx={{
        width: "100%",
        backgroundColor: "gray.light",

        "& .main-content": {
          pl: {
            xs: "2rem",
            lg: "19.5rem",
          },
          pr: 4,
          transition: "padding-left .5s ease",
        },
        "& .main-menu": {
          left: {
            xs: "-17.5rem",
            lg: "0",
          },
        },
        "&.close .menu-icon": {
          transform: "rotate(135deg)",
        },
        "&.close .main-menu": {
          left: {
            xs: "0",
            lg: "-17.5rem",
          },
        },
        "&.close .main-content": {
          pl: "2rem",
        },
      }}
    >
      <Box
        className="main-menu"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          background: "#ffffff",
          boxShadow: 4,
          width: "17.5rem",
          minHeight: "100vh",
          zIndex: "3",
          transition: "left .5s ease",
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "relative",
            pl: 2,
            pr: 2,
            height: "5rem",
          }}
        >
          <img src={logo} alt="logo" />

          <Stack
            sx={{
              position: "absolute",
              top: "0",
              right: "-2rem",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <ButtonBase
              sx={{
                backgroundColor: "gray.main",
                width: "2rem",
                height: "2rem",
                borderRadius: "0 .5rem .5rem 0",
              }}
              onClick={toggleMenu}
            >
              <Icon
                className="menu-icon"
                sx={{
                  color: "gray.dark",
                  transition: "transform .25s ease",
                }}
                fixedWidth
                icon={icons.close}
              />
            </ButtonBase>
          </Stack>
        </Stack>

        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            pl: 2,
            pr: 2,
            height: "7rem",
            backgroundColor: "gray.light",
          }}
        >
          <LenderSelector />
        </Stack>

        <Stack sx={{ flex: "1" }}>
          <NavMenuItem label="Activities" icon={icons.acivity} to="/activity" />

          <NavMenuItem label="Lenders" icon={icons.lender} to="/lenders" />

          <NavMenuItem label="Loans" icon={icons.loan} to="/loans" />
          {lender?.permitModel.update && (
            <NavMenuItem
              label="Reports Due"
              icon={icons.reports}
              to="/reporting"
            />
          )}
          {lender?.permitModel.delete && (
            <NavMenuItem
              label="Payment Processing"
              icon={icons.processing}
              to="/processing/payments"
            />
          )}
          {lender?.permitModel.delete && (
            <NavMenuItem
              label="Disbursement Processing"
              icon={icons.processing}
              to="/processing/disbursements"
            />
          )}
          {lender?.permitModel.delete && (
            <NavMenuItem
              label="Administration"
              icon={icons.settings}
              to="/admin"
            />
          )}
        </Stack>
      </Box>

      <Box className="main-content" sx={{ px: 4, flex: "1" }}>
        <PageHeader />

        <LenderStatistics />

        <Box sx={{ display: "flex", py: 4, flex: 1 }}>{children}</Box>
      </Box>
    </Box>
  );
}

export default Shell;
