import { IconButton } from "@mui/material";
import { saveAs } from "file-saver";
import { useGet1502Report } from "api/reports/reports";
import { Download } from "@mui/icons-material";

interface Props {
  reportId: number;
  reportDate: string;
  lenderName?: string;
}

function Download1502Link({ reportId, reportDate, lenderName }: Props) {
  const { refetch } = useGet1502Report(
    {
      reportId: reportId,
      reportDate: reportDate,
    },
    {
      query: {
        enabled: false, // don't run the query immediately when mounted
        cacheTime: 0, // don't cache query results
        retry: 0, // don't retry the query if it fails
        onSuccess(data: Blob) {
          // when data is fetched, save it
          const fileName = `1502Report_${lenderName}_${reportDate}`;
          saveAs(data, fileName || undefined);
        },
      },
      request: {
        // interpret the response as a blob
        responseType: "blob",
      },
    }
  );

  return (
    <IconButton
      onClick={() => {
        refetch();
      }}
    >
      <Download />
    </IconButton>
  );
}

export default Download1502Link;
