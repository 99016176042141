import React from "react";
import { useForm } from "react-hook-form";
import { Grid, Box, Stack } from "@mui/material";
import {
  LoansGetLoanDetailResult,
  ParticipantsAddParticipantForm,
  ParticipantsGetParticipantsParticipantModel,
  ParticipantsUpdateParticipantForm,
} from "api";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import TextField from "shared/forms/fields/TextField";
import MoneyField from "shared/forms/fields/MoneyField";
import DateField from "shared/forms/fields/DateField";
import PercentField from "shared/forms/fields/PercentField";

interface Props {
  onSave(form: ParticipantsUpdateParticipantForm): void;
  loan?: LoansGetLoanDetailResult;
  participant?: ParticipantsGetParticipantsParticipantModel;
}

function UpdateParticipantForm({ onSave, participant, loan }: Props) {
  const { control, handleSubmit, reset } =
    useForm<ParticipantsAddParticipantForm>({
      defaultValues: {
        lender: loan?.loan.lenderName,
        borrower: loan?.loan.borrowerName,
      },
    });

  const onSubmit = handleSubmit((form) => onSave(form));

  React.useEffect(() => {
    reset({
      investorName: participant?.investorName,
      loanSalePremiumAmount: participant?.loanSalePremiumAmount,
      loanSaleSettlementDate: participant?.loanSaleSettlementDate,
      percentageSold: participant?.percentageSold,
      principalAmountSold: participant?.principalAmountSold,
      accumulateToDate: participant?.accumulateToDate,
      interestReceivable: participant?.interestReceivable,
      servicingFee: participant?.servicingFee,
      currentSoldBalance: participant?.currentSoldBalance,
      netLoanSalePremiumToLender: participant?.netLoanSalePremiumToLender,
      loanSalePremiumToLender: participant?.loanSalePremiumToLender,
    });
  }, [participant, reset]);

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />

      <Grid container spacing={4}>
        <Grid item md={6}>
          <TextField
            control={control}
            name="investorName"
            label="Investor Name"
          />
        </Grid>
        <Grid item md={6}>
          <PercentField
            control={control}
            name="loanSalePremiumAmount"
            label="Loan Sale Premium Amount"
          />
        </Grid>
        <Grid item md={6}>
          <MoneyField
            control={control}
            name="loanSalePremiumToLender"
            label="Loan Sale Premium Amount To Lender"
          />
        </Grid>
        <Grid item md={6}>
          <MoneyField.Calculated
            control={control}
            name="netLoanSalePremiumToLender"
            label="Net Loan Sale Premium to Lender"
            readOnly={true}
          />
          ,
        </Grid>

        <Grid item md={6}>
          <DateField
            control={control}
            name="loanSaleSettlementDate"
            label="Loan Sale Settlement Date"
            required
          />
        </Grid>
        <Grid item md={6}>
          <PercentField
            control={control}
            name="percentageSold"
            label="Percentage Sold"
            required
          />
        </Grid>
        <Grid item md={6}>
          <MoneyField
            control={control}
            name="principalAmountSold"
            label="Principal Amount Sold"
            required
          />
        </Grid>

        <Grid item md={6}>
          <DateField
            control={control}
            name="accumulateToDate"
            label="Accumulate to Date"
            required
          />
        </Grid>
        <Grid item md={6}>
          <MoneyField
            control={control}
            name="interestReceivable"
            label="Interest Receivable"
          />
        </Grid>

        <Grid item md={6}>
          <PercentField
            control={control}
            name="servicingFee"
            label="Servicing Fee Retained by Lender"
          />
        </Grid>
        <Grid item md={6}>
          <MoneyField
            control={control}
            name="currentSoldBalance"
            label="Current Sold Balance"
            readOnly={true}
          />
        </Grid>
      </Grid>

      <Stack direction="row" justifyContent="flex-end" pt={2}>
        <SubmitButton variant="save" />
      </Stack>
    </Box>
  );
}

export default withFormStatus(UpdateParticipantForm);
