import { Dispatch, useContext, useEffect, useReducer } from "react";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { userPool, AuthContext } from "./context";

export interface FlowState {
  status: { isLoading?: boolean; isError?: boolean; error?: string };
  user?: CognitoUser;
  session?: CognitoUserSession;
}

export type FlowAction<TStep> =
  | { type: "next"; step: TStep }
  | { type: "user"; user: CognitoUser }
  | { type: "session"; user: CognitoUser; session: CognitoUserSession }
  | { type: "pending" }
  | { type: "error"; err: any }
  | { type: "reset" };

export type DispatchRef<T> = () => Dispatch<T>;

export function createUser(username: string) {
  return new CognitoUser({
    Username: username,
    Pool: userPool,
  });
}

export function useFlow<TStep>(
  init: (dispatch: DispatchRef<FlowAction<TStep>>, user?: CognitoUser) => TStep
): FlowState & TStep {
  const auth = useContext(AuthContext);
  const arr: [FlowState & TStep, Dispatch<FlowAction<TStep>>] = useReducer(
    // `arr` usage is a little funky here, because we want to reference our own dispatch fn
    function (
      state: FlowState & TStep,
      next: FlowAction<TStep>
    ): FlowState & TStep {
      const { type } = next;

      switch (type) {
        case "pending":
          return { ...state, status: { isLoading: true } };

        case "error":
          const { err } = next;
          return {
            ...state,
            status: {
              isLoading: false,
              isError: true,
              error: err.message ?? err,
            },
          };

        case "user":
          return { ...state, user: next.user };

        case "session":
          return { ...state, user: next.user, session: next.session };

        case "reset":
          return {
            ...init(() => arr[1], state.user),
            status: {},
          };

        case "next":
          const { step } = next;
          return { ...step, status: {} };
      }
    },
    { ...init(() => arr[1]), status: {} }
  );

  const { user, session } = arr[0];

  useEffect(() => {
    if (user && session && session.isValid) {
      auth.update({
        authenticated: true,
        user,
      });
    }
  }, [auth, user, session]);

  return arr[0];
}
