import { useParams } from "react-router";
import { useGetDueDateDetails, useUpdateReportDate } from "api";
import UpdateDueDate from "./forms/UpdateDueDateForm";
import { Box, Grid, Card, CardContent, CardHeader } from "@mui/material";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

function AdminRatesDetailPage() {
  const { reportDateId: reportDateIdParam } = useParams<{
    reportDateId: string;
  }>();
  const reportDateId = Number(reportDateIdParam);
  const { data: dueDateRateResult } = useGetDueDateDetails(reportDateId);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { mutate, ...status } = useUpdateReportDate({
    mutation: {
      onSuccess() {
        history.push(`/admin/dueDates`);

        enqueueSnackbar("Updated Date", {
          variant: "success",
        });
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} xl={8} sx={{ display: "flex" }}>
          <Card elevation={4} sx={{ overflow: "unset" }}>
            <CardHeader title="Information" />
            <CardContent>
              {dueDateRateResult && (
                <UpdateDueDate
                  dueDate={dueDateRateResult.dueDate}
                  status={status}
                  onSave={(form) => mutate({ reportDateId, data: form })}
                ></UpdateDueDate>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminRatesDetailPage;
