import { Box } from "@mui/system";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import {
  useGetLoanDeferments,
} from "api";
import { useParams } from "react-router";
import formatDate from "shared/formatters/date";
import formatGridCell from "shared/formatters/grid";
import DataGridToolbar from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";

function Toolbar() {
  return <DataGridToolbar header={"Deferments"} exportButton={false} />;
}

function PanelDeferments() {
  const { grid, query } = useDataGrid("deferments", columns);
  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);
  const { data: deferments, isFetching } = useGetLoanDeferments(
    loanId,
    { ...query },
    {
      query: {
        keepPreviousData: true,
      },
    }
  );

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flexGrow: 1, minHeight: "600px" }}>
          <DataGridPro
            {...grid}
            rows={deferments?.items ?? []}
            rowCount={deferments?.total}
            loading={isFetching}
            components={{
              Toolbar: Toolbar,
            }}
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
}

const columns: GridColumns = [
  {
    field: "createdDate",
    headerName: "Created Date",
    flex: 1,
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "beginDate",
    headerName: "Begin Date",
    flex: 1,
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "endDate",
    headerName: "End Date",
    flex: 1,
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "type",
    headerName: "Type",
    flex: 1,
  },

  {
    field: "nextPaymentDate",
    headerName: "Next Payment Date",
    flex: 1,
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "isActive",
    headerName: "Is Active",
    flex: 1,
    valueFormatter: (params) => (params.value ? "Yes" : "No"),
  },
];
export default PanelDeferments;
