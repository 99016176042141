import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
} from "@mui/material";

interface Props {
  alertMessage: string;
  initialWarningDialog: boolean;
  showDialog(close: boolean): void;
  acceptDelete(accept: boolean): void;
}

function AcceptDialogWarning({
  alertMessage,
  initialWarningDialog,
  showDialog,
  acceptDelete,
}: Props) {
  const handleClose = () => {
    showDialog(false);
  };
  return (
    <Dialog
      open={initialWarningDialog}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"IFS Warning"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertMessage}
        </DialogContentText>
        <Stack direction="row">
          <DialogActions>
            <Button
              onClick={() => {
                acceptDelete(true);
                showDialog(false);
              }}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
          <DialogActions>
            <Button
              onClick={() => {
                showDialog(false);
              }}
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default AcceptDialogWarning;
