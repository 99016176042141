import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { IMaskInput } from "react-imask";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";

type PhoneFieldProps<T> = FormTextFieldProps<T>;

function PhoneField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  ...props
}: PhoneFieldProps<T>) {
  const { getError } = useFormErrors();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <MuiTextField
          {...props}
          {...getError(name)}
          className={clsx(className, readOnly && "textfield-readonly")}
          value={value ?? undefined}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          inputProps={{ unmask: true, style: { textAlign: "right" } }}
          InputProps={{
            readOnly,
            inputComponent: PhoneMaskedInput as any,
          }}
        />
      )}
    />
  );
}

export interface MaskedInputProps {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

const PhoneMaskedInput = React.forwardRef<HTMLElement, MaskedInputProps>(
  function MaskedInput(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        mask="{+1} (000) 000-0000"
        placeholderChar="#"
        overwrite
        {...other}
        //@ts-expect-error imask missing type def for mask
        inputRef={ref}
        onAccept={(value: any) => {
          onChange({ target: { name: props.name, value } });
        }}
      />
    );
  }
);

export default asVariants(PhoneField);
