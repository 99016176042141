import {
  faUniversity,
  faFileInvoiceDollar,
  faFileUpload,
  faCog,
  faBell,
  faUser,
  faAngleDown,
  faAngleRight,
  faTimes,
  faCheckCircle,
  faTimesCircle,
  faSave,
  faArrowCircleRight,
  faUndo,
  faArrowRight,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFileChartPie,
  faFileExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

const palette = {
  primary: {
    light: "#55799e",
    main: "#2B5886",
    dark: "#1e3d5d",
    contrastText: "#ffffff",
  },
  secondary: {
    light: "#e3a35f",
    main: "#DC8D38",
    dark: "#9a6227",
    contrastText: "#ffcc00",
  },
  gray: {
    light: "#EBEBEB",
    main: "#D1D1D1",
    dark: "#4A4A4A",
    contrastText: "#ffcc00",
  },
  grid: {
    altRow: "#F6F6F6",
    rowHover: "#faf0e6",
  },
};

const defaults = createTheme();

export const theme = createTheme({
  palette,

  breakpoints: {
    values: {
      xs: 0,
      sm: 900,
      md: 1200,
      lg: 1500,
      xl: 1900,
    },
  },

  components: {
    /*! buttons */
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        contained: {
          minHeight: "3rem",
          border: "2px solid",
          borderColor: palette.gray.light,
          borderRadius: ".5rem",
          boxShadow: defaults.shadows[4],
          fontSize: "1rem",

          "&:hover": {
            borderColor: palette.secondary.main,
            backgroundColor: "#ffffff",
            boxShadow: defaults.shadows[8],
            color: palette.secondary.main,
          },
        },

        containedPrimary: {
          color: palette.primary.main,
          backgroundColor: "#ffffff",
        },
      },
    },

    /*! links */
    MuiLink: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
          textDecoration: "none",

          "&:hover": {
            color: palette.secondary.main,
            textDecoration: "underline",
          },
        },
      },
    },

    /*! typography */
    MuiTypography: {
      styleOverrides: {
        button: {
          minHeight: "3rem",
          display: "flex",
          alignItems: "center",
          fontSize: "1rem",
          padding: "0 2rem",
          "&:hover": {},
        },
      },
    },

    /*! inputs */
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: palette.gray.dark,

          "&.Mui-focused": {
            color: palette.gray.dark,
          },
        },
      },
    },

    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        shrink: {
          position: "relative",
          transform: "none !important",
          marginBottom: defaults.spacing(0.5),
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        root: {
          "&.textfield-readonly .MuiOutlinedInput-root": {
            boxShadow: "none",
            backgroundColor: palette.grid.altRow,

            "&:hover fieldset": {
              borderColor: "inherit !important",
            },

            "&.Mui-focused fieldset": {
              borderColor: "inherit !important",
            },
          },

          "&.calculated .MuiInputLabel-root": {
            color: palette.primary.main,
          },

          "&.calculated .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary.main,
          },

          "& .MuiFormHelperText-root": {
            textAlign: "right",
            marginRight: "0",
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: "#ffffff !important",
          },
          "& .Mui-selected:hover": {
            backgroundColor: `${palette.gray.light} !important`,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: ".5rem",
          borderColor: palette.gray.main,
          transition: "all .5s ease",

          "& fieldset": {
            top: "0",
          },

          "& fieldset legend": {
            display: "none",
          },

          "&:hover fieldset": {
            borderColor: `${palette.secondary.main} !important`,
          },

          "&.Mui-focused fieldset": {
            borderColor: `${palette.secondary.main} !important`,
          },
        },
        notchedOutline: {
          borderColor: palette.gray.main,
          borderWidth: "2px !important",
          transition: "all .5s ease",
        },
      },
    },

    /*! paper */
    MuiPaper: {
      styleOverrides: {
        root: {
          "& .MuiGridPanelWrapper-root .MuiDataGridPanelHeader-root": {
            padding: defaults.spacing(2),
          },
          "& .MuiGridPanelWrapper-root .MuiGridPanelContent-root": {
            padding: defaults.spacing(1),
          },
          "& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root": {
            paddingTop: defaults.spacing(2),
            paddingBottom: defaults.spacing(2),
            paddingRight: defaults.spacing(1),
            paddingLeft: defaults.spacing(1),
          },

          "& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root .MuiButton-root":
            {
              flex: 1,
              marginLeft: defaults.spacing(1),
              marginRight: defaults.spacing(1),
            },
        },
        rounded: {
          borderRadius: ".5rem",
        },
      },
    },

    /*! card */
    MuiCardHeader: {
      styleOverrides: {
        root: {
          "& .MuiCardHeader-title": {
            textTransform: "uppercase",
            fontSize: "1.25rem",
            fontWeight: "700",
          },
        },
        action: {
          margin: defaults.spacing(0),
        },
      },
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: defaults.spacing(2),
        },
      },
    },

    /*! data grid */
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",

          "& .MuiDataGrid-toolbarContainer": {
            padding: "0",
          },

          "& .MuiDataGrid-row:hover": {
            backgroundColor: `${palette.grid.rowHover} !important`,
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${palette.gray.main}`,
          },

          "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 0,
          },

          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },

          "& .MuiDataGrid-columnsContainer": {
            borderBottom: `1px solid ${palette.gray.dark}`,
            boxSizing: "border-box",
          },

          "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: `1px solid ${palette.secondary.main}`,
          },

          "& .MuiDataGrid-row:nth-of-type(odd)": {
            backgroundColor: palette.grid.altRow,
          },
          "& .data-header-no-line-height": {
            lineHeight: "normal",
          },
          "&.data-grid-max-height-main .MuiDataGrid-virtualScrollerContent": {
            height: "calc(100vh - 31rem) !important",
          },
        },
      },
    },
  },
});

export const icons = {
  loan: faFileInvoiceDollar,
  processing: faFileExclamation,
  lender: faUniversity,
  acivity: faFileUpload,
  settings: faCog,
  alerts: faBell,
  user: faUser,
  angleDown: faAngleDown,
  angleRight: faAngleRight,
  reports: faFileChartPie,
  close: faTimes,
  next: faArrowCircleRight,
  save: faSave,
  success: faCheckCircle,
  check: faCheck,
  error: faTimesCircle,
  restore: faUndo,
  copy: faArrowRight,
};
