import { GridColDef, DataGridProProps } from "@mui/x-data-grid-pro";
import { useMemo } from "react";
import useLocationState from "../useLocationState";
import useUserPreference from "../useUserPreference";

interface GridState<TSearchQueryFilter, TActivityTypeFilter, TTaskFilter, TLoanSoldStateFilter> {
  page: number;
  pageSize: number;
  orderBy?: string;
  reverse?: true;
  searchFilter?: TSearchQueryFilter;
  activityTypeFilter?: TActivityTypeFilter;
  isTaskFilter?: TTaskFilter;
  loanSoldStateFilter?: TLoanSoldStateFilter;
}

type GridQuery<TSearchQueryFilter> = {
  page: number;
  pageSize: number;
  orderBy?: string;
  reverse?: true;
} & TSearchQueryFilter;

interface ColumnState {
  field: string;
  isVisible?: boolean;
}

const rowsPerPageOptions = [10, 20, 50, 100];

type GridDefaults = Omit<Partial<DataGridProProps>, "columns"> &
  Pick<DataGridProProps, "columns">;

export default function useDataGrid<
  TSearchQueryFilter = any,
  TActivityTypeFilter = any,
  TTaskFilter = any,
  TLoanSoldStateFilter = any,
>(key: string, columnDefs: GridColDef[]) {
  const [state, setState] = useLocationState<
    GridState<TSearchQueryFilter, TActivityTypeFilter, TTaskFilter, TLoanSoldStateFilter>
  >(`grid.${key}`, {
    page: 0,
    pageSize: 20,
  });

  const {
    page,
    pageSize,
    searchFilter,
    activityTypeFilter,
    isTaskFilter,
    loanSoldStateFilter,
    ...rest
  } = state;

  const [columnState, setColumnState] = useUserPreference<ColumnState[]>(
    `grid.${key}.cols`,
    []
  );

  const columns = useMemo(
    () => mergeColumnState(columnState, columnDefs),
    [columnState, columnDefs]
  );

  const grid: GridDefaults = {
    columns,
    pagination: true,
    paginationMode: "server",
    page,
    pageSize,
    rowsPerPageOptions,
    onPageChange(newPage) {
      setState({ ...state, page: newPage });
    },
    onPageSizeChange(newPageSize) {
      setState({ ...state, page: 0, pageSize: newPageSize });
    },
    onSortModelChange(sortModel) {
      const { field, sort } = sortModel?.[0] || {};
      setState({
        ...state,
        orderBy: field,
        reverse: sort === "desc" ? true : undefined,
      });
    },
    onColumnVisibilityChange({ field, isVisible }) {
      const next = columnState?.filter((c) => c.field !== field) ?? [];
      const colDef = columnDefs.find((c) => c.field === field);

      if (colDef && isVisible !== !colDef.hide) {
        next.push({ field, isVisible });
      }
      setColumnState(next);
    },
  };

  const updateSearchQuery = (queryString: TSearchQueryFilter) => {
    setState({ ...state, searchFilter: queryString, page: 0 });
  };
  const updateActivityTypeQuery = (activityTypeQuery: TActivityTypeFilter) => {
    setState({ ...state, activityTypeFilter: activityTypeQuery, page: 0 });
  };
  const updateIsTaskQuery = (isTaskQuery: TTaskFilter) => {
    setState({ ...state, isTaskFilter: isTaskQuery, page: 0 });
  };
  const updateLoanSoldStateQuery = (loanSoldStateQuery: TLoanSoldStateFilter) => {
    setState({ ...state, loanSoldStateFilter: loanSoldStateQuery, page: 0});
  };
  const query: GridQuery<TSearchQueryFilter> = {
    page: page + 1,
    pageSize,
    ...searchFilter,
    ...activityTypeFilter,
    ...isTaskFilter,
    ...loanSoldStateFilter,
    ...rest,
  } as any;

  return {
    grid,
    query,
    updateSearchQuery,
    updateActivityTypeQuery,
    updateIsTaskQuery,
    updateLoanSoldStateQuery
  };
}

function mergeColumnState(
  columnState: ColumnState[],
  columnDefs: GridColDef[]
) {
  if (!columnState || columnState.length === 0) {
    return columnDefs;
  }

  const stateMap = new Map<string, ColumnState>(
    columnState.map((c) => [c.field, c])
  );
  return columnDefs.map((colDef) => {
    const state = stateMap.get(colDef.field);
    if (!state) return colDef;

    const { isVisible } = state;
    return { ...colDef, hide: !isVisible };
  });
}
