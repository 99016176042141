import React, { useMemo } from "react";
import { InputAdornment, TextField as MuiTextField } from "@mui/material";
import clsx from "clsx";

import { faDollarSign } from "@fortawesome/free-solid-svg-icons";
import Icon from "shared/Icon";

import useFormErrors from "../useFormErrors";
import { FormTextFieldProps, asVariants } from "./FieldVariants";
import { useNumberMask } from "./useInputMask";

type MoneyFieldProps<T> = FormTextFieldProps<
  T,
  {
    scale?: number;
  }
>;

function MoneyField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  scale = 2,  
  variant = "outlined",
  ...props
}: MoneyFieldProps<T>) {
  const { getError } = useFormErrors();

  const maskOptions = useMemo(
    () => ({
      scale,
      thousandsSeparator: ",",
      radix: ".",
      padFractionalZeros: true,
    }),
    [scale]
  );

  const { inputRef, onBlur } = useNumberMask(
    {
      control,
      name,
      rules,
    },
    maskOptions,
  );

  return (
    <MuiTextField
      {...props}
      {...getError(name)}
      className={clsx(className, readOnly && "textfield-readonly")}
      onBlur={onBlur}
      inputRef={inputRef}
      inputProps={{ style: { textAlign: "right" } }}
      InputProps={{
        readOnly,
        startAdornment: (
          <InputAdornment position="start">
            <Icon fixedWidth icon={faDollarSign} />
          </InputAdornment>
        ),
      }}
      variant={variant}
    />
  );
}

export default asVariants(MoneyField);
