/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ReportsGetReportDetailResult,
  GetReportDetailParams,
  ReportsGetAllReportsResult,
  GetAllReportsParams,
  ReportsGetAllPastDueReportsResult,
  GetAllPastDueReportsParams,
  ReportsGetAllNewLoansResult,
  GetAllNewLoansParams,
  Get1502ReportParams,
  ReportsGetAllReportDueDatesResult,
  GetAllReportDueDatesParams,
  ReportsAddReportDateResult,
  ReportsAddReportDateForm,
  ReportsUpdateReportDateResult,
  ReportsUpdateReportDateForm,
  ReportsGetDueDateDetailsResult,
  ReportsGet1502HistoryResult,
  Get1502HistoryParams,
  ReportsUpdateReportStatusResult,
  ReportsUpdateReportStatusForm
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getReportDetail = (
    params?: GetReportDetailParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetReportDetailResult>(
      {url: `/reports/getreportdetail`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetReportDetailQueryKey = (params?: GetReportDetailParams,) => [`/reports/getreportdetail`, ...(params ? [params]: [])];

    
export const useGetReportDetail = <TData = AsyncReturnType<typeof getReportDetail>, TError = unknown>(
 params?: GetReportDetailParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getReportDetail>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetReportDetailQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getReportDetail>> = () => getReportDetail(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getReportDetail>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getAllReports = (
    params?: GetAllReportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetAllReportsResult>(
      {url: `/reports/getallreports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllReportsQueryKey = (params?: GetAllReportsParams,) => [`/reports/getallreports`, ...(params ? [params]: [])];

    
export const useGetAllReports = <TData = AsyncReturnType<typeof getAllReports>, TError = unknown>(
 params?: GetAllReportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllReports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllReportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllReports>> = () => getAllReports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllReports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getAllPastDueReports = (
    params?: GetAllPastDueReportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetAllPastDueReportsResult>(
      {url: `/reports/getallpastduereports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllPastDueReportsQueryKey = (params?: GetAllPastDueReportsParams,) => [`/reports/getallpastduereports`, ...(params ? [params]: [])];

    
export const useGetAllPastDueReports = <TData = AsyncReturnType<typeof getAllPastDueReports>, TError = unknown>(
 params?: GetAllPastDueReportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllPastDueReports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllPastDueReportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllPastDueReports>> = () => getAllPastDueReports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllPastDueReports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getAllNewLoans = (
    params?: GetAllNewLoansParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetAllNewLoansResult>(
      {url: `/reports/getallnewloans`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllNewLoansQueryKey = (params?: GetAllNewLoansParams,) => [`/reports/getallnewloans`, ...(params ? [params]: [])];

    
export const useGetAllNewLoans = <TData = AsyncReturnType<typeof getAllNewLoans>, TError = unknown>(
 params?: GetAllNewLoansParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllNewLoans>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllNewLoansQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllNewLoans>> = () => getAllNewLoans(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllNewLoans>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const get1502Report = (
    params?: Get1502ReportParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<void>(
      {url: `/reports/get1502report`, method: 'get',
        params,
    },
      options);
    }
  

export const getGet1502ReportQueryKey = (params?: Get1502ReportParams,) => [`/reports/get1502report`, ...(params ? [params]: [])];

    
export const useGet1502Report = <TData = AsyncReturnType<typeof get1502Report>, TError = unknown>(
 params?: Get1502ReportParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof get1502Report>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGet1502ReportQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof get1502Report>> = () => get1502Report(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof get1502Report>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getAllReportDueDates = (
    params?: GetAllReportDueDatesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetAllReportDueDatesResult>(
      {url: `/reports/getallreportduedates`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllReportDueDatesQueryKey = (params?: GetAllReportDueDatesParams,) => [`/reports/getallreportduedates`, ...(params ? [params]: [])];

    
export const useGetAllReportDueDates = <TData = AsyncReturnType<typeof getAllReportDueDates>, TError = unknown>(
 params?: GetAllReportDueDatesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllReportDueDates>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllReportDueDatesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllReportDueDates>> = () => getAllReportDueDates(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllReportDueDates>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addReportDate = (
    reportsAddReportDateForm: ReportsAddReportDateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsAddReportDateResult>(
      {url: `/reports/addreportdate`, method: 'post',
      data: reportsAddReportDateForm
    },
      options);
    }
  


    export const useAddReportDate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addReportDate>, TError,{data: ReportsAddReportDateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addReportDate>, {data: ReportsAddReportDateForm}> = (props) => {
          const {data} = props || {};

          return  addReportDate(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addReportDate>, TError, {data: ReportsAddReportDateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const updateReportDate = (
    reportDateId: number,
    reportsUpdateReportDateForm: ReportsUpdateReportDateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsUpdateReportDateResult>(
      {url: `/reports/updatereportdate/${reportDateId}`, method: 'post',
      data: reportsUpdateReportDateForm
    },
      options);
    }
  


    export const useUpdateReportDate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateReportDate>, TError,{reportDateId: number;data: ReportsUpdateReportDateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateReportDate>, {reportDateId: number;data: ReportsUpdateReportDateForm}> = (props) => {
          const {reportDateId,data} = props || {};

          return  updateReportDate(reportDateId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateReportDate>, TError, {reportDateId: number;data: ReportsUpdateReportDateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getDueDateDetails = (
    reportDateId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGetDueDateDetailsResult>(
      {url: `/reports/${reportDateId}`, method: 'get'
    },
      options);
    }
  

export const getGetDueDateDetailsQueryKey = (reportDateId: number,) => [`/reports/${reportDateId}`];

    
export const useGetDueDateDetails = <TData = AsyncReturnType<typeof getDueDateDetails>, TError = unknown>(
 reportDateId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDueDateDetails>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDueDateDetailsQueryKey(reportDateId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDueDateDetails>> = () => getDueDateDetails(reportDateId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDueDateDetails>, TError, TData>(queryKey, queryFn, {enabled: !!(reportDateId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const get1502History = (
    params?: Get1502HistoryParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsGet1502HistoryResult>(
      {url: `/reports/1502history`, method: 'get',
        params,
    },
      options);
    }
  

export const getGet1502HistoryQueryKey = (params?: Get1502HistoryParams,) => [`/reports/1502history`, ...(params ? [params]: [])];

    
export const useGet1502History = <TData = AsyncReturnType<typeof get1502History>, TError = unknown>(
 params?: Get1502HistoryParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof get1502History>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGet1502HistoryQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof get1502History>> = () => get1502History(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof get1502History>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const updateReport = (
    reportsUpdateReportStatusForm: ReportsUpdateReportStatusForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ReportsUpdateReportStatusResult>(
      {url: `/reports/updatereport`, method: 'post',
      data: reportsUpdateReportStatusForm
    },
      options);
    }
  


    export const useUpdateReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateReport>, TError,{data: ReportsUpdateReportStatusForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateReport>, {data: ReportsUpdateReportStatusForm}> = (props) => {
          const {data} = props || {};

          return  updateReport(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateReport>, TError, {data: ReportsUpdateReportStatusForm}, TContext>(mutationFn, mutationOptions)
    }
    