import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Stack,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import {
  DisbursementsImportDisbursementLoanDisbursementModel,
  DisbursementsImportDisbursementResult,
  PaymentsImportPaymentsLoanPaymentModel,
  PaymentsImportPaymentsResult,
  useGetDisbursementImportResult,
  useGetMissingImports,
  useGetPaymentImportResult,
  useImportDisbursement,
  usePaymentsImport,
} from "api";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { useGetReportDetail, useUpdateReport } from "api/reports/reports";
import formatDate from "shared/formatters/date";
import FileProcessing from "./FileProcessing";
import ImportResult from "./ImportResult";
import DropDownField from "shared/forms/fields/DropDownField";
import { FormErrorAlert, SubmitButton } from "shared/forms";
import { useForm } from "react-hook-form";

enum ViewState {
  NONE,
  UPLOAD,
  PROCESSING,
  RESULTS,
}

function ReportingDetailPage() {
  const [viewState, setViewState] = useState(ViewState.NONE);
  const [missingLoans, setMissingLoans] = useState<any[]>([]);

  const [paymentNotFound, setPaymentNotFound] = useState<any[]>([]);
  const [wrongLoans, setWrongLoans] = useState<any[]>([]);

  const [importErrors, setImportErrors] = useState([] as string[]);
  const [reportStatus, setReportStatus] = useState("Pending Upload");
  const [currentReportStatus, setCurrentReportStatus] = useState<number>();

  const [paymentImport, setPaymentImport] = useState(
    [] as PaymentsImportPaymentsLoanPaymentModel[]
  );
  const [disbursementImport, setDisbursementImport] = useState(
    [] as DisbursementsImportDisbursementLoanDisbursementModel[]
  );
  const { reportId: reportIdParam } = useParams<{ reportId: string }>();
  const reportId = Number(reportIdParam);
  const { enqueueSnackbar } = useSnackbar();

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop: (filesDropped: any[], _rejectedFiles: any[]) => {
      setViewState(ViewState.PROCESSING);

      filesDropped.forEach((file) => {
        const fileName: string = file.path;
        if (fileName.includes("Payment")) {
          paymentImportMutate({
            data: { file: file },
            params: { reportId: reportId },
          });
        } else if (fileName.includes("Disbursement")) {
          disbursementImportMutate({
            data: { file: file },
            params: { reportId: reportId },
          });
        } else {
          const errorList: string[] = [];
          errorList.push("File name must include 'Payment' or 'Disbursement'");
          setImportErrors(errorList);
          setViewState(ViewState.UPLOAD);
        }
      });
    },
  });

  const files = acceptedFiles.map((file) => (
    <Box key={file.name} sx={{ pt: 2 }}>
      {file.name}
    </Box>
  ));

  const { mutate: paymentImportMutate } = usePaymentsImport({
    mutation: {
      onSuccess(data: PaymentsImportPaymentsResult) {
        setMissingLoans(data.paymentImportModel.missingLoans);
        setPaymentNotFound(data.paymentImportModel.notFound);
        setWrongLoans(data.paymentImportModel.wrongLoans);
        setPaymentImport(data.paymentImportModel.loanPaymentModels);
        if (data.paymentImportModel.loanPaymentModels.length === 0) {
          enqueueSnackbar("No payments Imported", {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("Payments Imported", {
            variant: "success",
          });
        }

        clearFile();
        setReportStatus("Needs Reconciliation");
        setViewState(ViewState.RESULTS);
      },
      onError: (error: any) => {
        const errorData = error.response.data;
        const errorList: string[] = [];
        if (errorData.errors) {
          Object.keys(errorData.errors).forEach((key) => {
            const formIndex = key.substring(6, key.indexOf("]"));
            const formattedErrorMessage = `Row ${parseInt(formIndex) + 1}: ${
              errorData.errors[key]
            }`;
            errorList.push(formattedErrorMessage.replaceAll("'", ""));
          });
        }
        if (error.response.data.status !== 200) {
          errorList.push(
            `${error.response.data.status} - ${error.response.data.title}`
          );
        }
        setImportErrors(errorList);
        setViewState(ViewState.UPLOAD);
      },
    },
  });

  const { mutate: UpdateReportMutate } = useUpdateReport({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Report Updated", {
          variant: "success",
        });
        reportRefetch();
      },
    },
  });

  const { mutate: disbursementImportMutate } = useImportDisbursement({
    mutation: {
      onSuccess(data: DisbursementsImportDisbursementResult) {
        setDisbursementImport(
          data.disbursementImportModel.loanDisbursementModels
        );
        setWrongLoans(data.disbursementImportModel.wrongLoans);

        if (data.disbursementImportModel.loanDisbursementModels.length === 0) {
          enqueueSnackbar("No payments Imported", {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("Disbursement Imported", {
            variant: "success",
          });
        }

        clearFile();
        setReportStatus("Needs Reconciliation");
        setViewState(ViewState.RESULTS);
      },
      onError: (error: any) => {
        const errorData = error.response.data;
        const errorList: string[] = [];
        if (errorData.errors) {
          Object.keys(errorData.errors).forEach((key) => {
            const formIndex = key.substring(6, key.indexOf("]"));
            const formattedErrorMessage = `Row ${parseInt(formIndex) + 1}: ${
              errorData.errors[key]
            }`;
            errorList.push(formattedErrorMessage.replaceAll("'", ""));
          });
        }
        if (error.response.data.status !== 200) {
          errorList.push(
            `${error.response.data.status} - ${error.response.data.title}`
          );
        }
        setImportErrors(errorList);
        setViewState(ViewState.UPLOAD);
      },
    },
  });

  const { data: importsNotFound, refetch: refetchMissingImports } =
    useGetMissingImports({
      reportId: reportId,
    });

  const clearFile = () => {
    acceptedFiles.splice(0, acceptedFiles.length);
  };

  const { data: paymentImportResult, refetch: paymentRefetch } =
    useGetPaymentImportResult({
      paymentImportId: reportId,
    });

  const { data: disbursementImportResult, refetch: disbursementRefetch } =
    useGetDisbursementImportResult({
      disbursementImportId: reportId,
    });
  const { data: reportsGetReportDetailResult, refetch: reportRefetch } =
    useGetReportDetail({
      reportId: reportId,
    });

  const fileProcessingComplete = () => {
    setViewState(ViewState.RESULTS);
  };
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      currentReportStatus: currentReportStatus,
    },
  });

  useEffect(() => {
    reportRefetch();
    disbursementRefetch();
    refetchMissingImports();
    paymentRefetch();
    if (reportsGetReportDetailResult) {
      setReportStatus(
        reportsGetReportDetailResult?.resultModel?.reportModel
          ?.reportStatusDescription ?? ""
      );
      setCurrentReportStatus(
        reportsGetReportDetailResult?.resultModel?.reportModel?.reportStatusId
      );
    }
    if (
      paymentImportResult &&
      disbursementImportResult &&
      importsNotFound &&
      (paymentImportResult.paymentImportModel.missingLoans.length > 0 ||
        paymentImportResult.paymentImportModel.notFound.length > 0 ||
        paymentImportResult.paymentImportModel.loanPaymentModels.length > 0 ||
        disbursementImportResult.disbursementImportModel.disbursementsModel
          .length > 0 ||
        disbursementImportResult.disbursementImportModel.notFound.length > 0 ||
        importsNotFound.importsNotFoundModel.disbursementsNotFound.length > 0 ||
        importsNotFound.importsNotFoundModel.paymentsNotFound.length > 0)
    ) {
      setMissingLoans(
        paymentImportResult?.paymentImportModel.missingLoans || []
      );
      setPaymentNotFound(
        paymentImportResult?.paymentImportModel.notFound || []
      );

      setPaymentImport(
        paymentImportResult?.paymentImportModel.loanPaymentModels || []
      );
      setDisbursementImport(
        disbursementImportResult?.disbursementImportModel.disbursementsModel ||
          []
      );
      setViewState(ViewState.RESULTS);
    } else {
      setViewState(ViewState.UPLOAD);
    }
  }, [
    paymentImportResult,
    reportRefetch,
    disbursementRefetch,
    paymentRefetch,
    refetchMissingImports,
    reportsGetReportDetailResult,
    disbursementImport,
    disbursementImportResult,
    importsNotFound,
  ]);

  setValue(
    "currentReportStatus",
    reportsGetReportDetailResult?.resultModel?.reportModel?.reportStatusId
  );

  const onSubmit = handleSubmit((form) => {
    UpdateReportMutate({
      data: { reportId: reportId, reportStatus: form.currentReportStatus! },
    });
  });

  return (
    <Card
      elevation={4}
      sx={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      <CardHeader title="Report Details"></CardHeader>
      <CardContent sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Report ID:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {reportsGetReportDetailResult?.resultModel?.reportModel.id}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Lender ID:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {
                  reportsGetReportDetailResult?.resultModel?.reportModel
                    .lenderName
                }
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Period End Date:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {formatDate(
                  reportsGetReportDetailResult?.resultModel?.reportModel
                    .reportEndOfPeriod
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Due Date:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {formatDate(
                  reportsGetReportDetailResult?.resultModel?.reportModel.dueDate
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Grace Period Date:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {formatDate(
                  reportsGetReportDetailResult?.resultModel?.reportModel
                    .gracePeriod
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Stack>
              <Box
                sx={{
                  fontSize: "smaller",
                  marginBottom: "5px",
                  fontWeight: "bold",
                }}
              >
                Status:
              </Box>
            </Stack>
            <Stack>
              <Box sx={{ borderBottom: "solid 1px #efefef" }}>
                {reportStatus}
              </Box>
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          component="form"
          onSubmit={onSubmit}
          sx={{ marginTop: "1%" }}
        >
          <FormErrorAlert />
          <Grid item xs={3}>
            <DropDownField
              sx={{ width: "100%" }}
              control={control}
              name="currentReportStatus"
              label="Report Status"
              options={
                reportsGetReportDetailResult?.resultModel?.reportStatusModel
                  ?.reportStatuses ?? []
              }
              getOptionKey={(opt) => opt.id}
              getOptionLabel={(opt) => opt.label}
            />
          </Grid>
          <Grid item sx={{ marginTop: 3.5 }}>
            <SubmitButton variant="save">Update Status</SubmitButton>
          </Grid>
        </Grid>
        {viewState === ViewState.UPLOAD && (
          <Stack
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              flex: "1",
              backgroundColor: "gray.light",
              my: 2,
              p: 4,
              minHeight: 320,
            }}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography style={{ color: "red" }}>
                {importErrors.map((error) => (
                  <div>{error}</div>
                ))}
              </Typography>
              <Box>
                <Typography variant="h5">
                  Please upload your Loan History Report or Disbursement
                </Typography>
                <Grid>
                  <Button
                    sx={{ minWidth: "20rem", my: 2 }}
                    href="/resources/PaymentTemplate.xlsx"
                    target="_blank"
                    download="PaymentTemplate.xlsx"
                  >
                    Download Payment Example Template
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    sx={{ minWidth: "20rem", my: 2 }}
                    href="/resources/DisbursementTemplate.xlsx"
                    target="_blank"
                    download="DisbursementTemplate.xlsx"
                  >
                    Download Disbursement Example Template
                  </Button>
                </Grid>
                <Typography variant="h5" paddingBottom={2}>
                  OR
                </Typography>
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "#fff",
                    border: "2px dashed",
                    borderColor: "secondary.main",
                  }}
                >
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "gray.light",
                      "&:hover": { backgroundColor: "grid.rowHover" },
                    }}
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input {...getInputProps()} />
                    <Typography sx={{ fontWeight: 700 }}>
                      Drag 'n' drop payment file here, or click to select file
                    </Typography>
                  </Box>

                  <Box>{files}</Box>
                </Box>
              </Box>
            </Box>
          </Stack>
        )}
        {viewState === ViewState.PROCESSING && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              flex: "1",
              backgroundColor: "gray.light",
              my: 2,
              p: 4,
              minHeight: 320,
            }}
          >
            <FileProcessing onComplete={fileProcessingComplete} />
          </Stack>
        )}
        {viewState === ViewState.RESULTS && (
          <Stack sx={{ flex: "1", minHeight: 320 }}>
            <ImportResult
              reportStatus={reportStatus}
              disbursementImport={disbursementImport}
              paymentImport={paymentImport}
              missingLoans={
                missingLoans.length > 0
                  ? missingLoans
                  : importsNotFound?.importsNotFoundModel.paymentsNotFound ?? []
              }
              paymentNotFound={paymentNotFound}
              wrongLoans={wrongLoans}
              paymentFileImport={(filesDropped: any) => {
                paymentImportMutate({
                  data: { file: filesDropped },
                  params: { reportId: reportId },
                });
              }}
              disbursementFileImport={(filesDropped: any) => {
                disbursementImportMutate({
                  data: { file: filesDropped },
                  params: { reportId: reportId },
                });
              }}
              refetchPayments={(refetch) => {
                if (refetch) paymentRefetch();
              }}
              refetchDisbursements={(refetch) => {
                if (refetch) disbursementRefetch();
              }}
            />
          </Stack>
        )}
      </CardContent>
    </Card>
  );
}
export default ReportingDetailPage;
