/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ActivitiesGetAllActivitiesResult,
  GetAllActivitiesParams,
  ActivitiesAddActivityResult,
  AddActivityBody,
  ActivitiesGetActivityByIdResult,
  ActivitiesUpdateActivityResult,
  UpdateActivityBody,
  ActivitiesDeleteActivityResult
} from '../backend.schemas'
import { apiRequest } from '.././client'
import { uploadFormData } from '.././upload'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllActivities = (
    params?: GetAllActivitiesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ActivitiesGetAllActivitiesResult>(
      {url: `/activity`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllActivitiesQueryKey = (params?: GetAllActivitiesParams,) => [`/activity`, ...(params ? [params]: [])];

    
export const useGetAllActivities = <TData = AsyncReturnType<typeof getAllActivities>, TError = unknown>(
 params?: GetAllActivitiesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllActivities>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllActivitiesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllActivities>> = () => getAllActivities(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllActivities>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addActivity = (
    addActivityBody: AddActivityBody,
 options?: SecondParameter<typeof apiRequest>) => {const formData = uploadFormData(addActivityBody)
      return apiRequest<ActivitiesAddActivityResult>(
      {url: `/activity`, method: 'post',
       data: formData
    },
      options);
    }
  


    export const useAddActivity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addActivity>, TError,{data: AddActivityBody}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addActivity>, {data: AddActivityBody}> = (props) => {
          const {data} = props || {};

          return  addActivity(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addActivity>, TError, {data: AddActivityBody}, TContext>(mutationFn, mutationOptions)
    }
    export const getActivityById = (
    activityId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ActivitiesGetActivityByIdResult>(
      {url: `/activity/${activityId}`, method: 'get'
    },
      options);
    }
  

export const getGetActivityByIdQueryKey = (activityId: number,) => [`/activity/${activityId}`];

    
export const useGetActivityById = <TData = AsyncReturnType<typeof getActivityById>, TError = unknown>(
 activityId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getActivityById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetActivityByIdQueryKey(activityId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getActivityById>> = () => getActivityById(activityId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getActivityById>, TError, TData>(queryKey, queryFn, {enabled: !!(activityId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const updateActivity = (
    activityId: number,
    updateActivityBody: UpdateActivityBody,
 options?: SecondParameter<typeof apiRequest>) => {const formData = uploadFormData(updateActivityBody)
      return apiRequest<ActivitiesUpdateActivityResult>(
      {url: `/activity/${activityId}`, method: 'post',
       data: formData
    },
      options);
    }
  


    export const useUpdateActivity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateActivity>, TError,{activityId: number;data: UpdateActivityBody}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateActivity>, {activityId: number;data: UpdateActivityBody}> = (props) => {
          const {activityId,data} = props || {};

          return  updateActivity(activityId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateActivity>, TError, {activityId: number;data: UpdateActivityBody}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteActivity = (
    activityId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ActivitiesDeleteActivityResult>(
      {url: `/activity/delete/${activityId}`, method: 'post'
    },
      options);
    }
  


    export const useDeleteActivity = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteActivity>, TError,{activityId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteActivity>, {activityId: number}> = (props) => {
          const {activityId} = props || {};

          return  deleteActivity(activityId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteActivity>, TError, {activityId: number}, TContext>(mutationFn, mutationOptions)
    }
    