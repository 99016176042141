import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import {
  LoansGetAllDropDownsResult,
  LoansAddLoanForm,
  useGetAllLenders,
} from "api";
import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import TextField from "shared/forms/fields/TextField";
import DateField from "shared/forms/fields/DateField";
import MoneyField from "shared/forms/fields/MoneyField";
import PercentField from "shared/forms/fields/PercentField";
import DropDownField from "shared/forms/fields/DropDownField";
import DefermentForm from "./DefermentForm";
import { icons } from "theme";
import Icon from "shared/Icon";
import { useUpdateEffect } from "react-use";
import TaxIdField from "shared/forms/fields/TaxIdField";

interface Props {
  onSave(form: LoansAddLoanForm): void;
  loanDropDownOptions: LoansGetAllDropDownsResult;
}

function AddLoanForm({ onSave, loanDropDownOptions }: Props) {
  const { control, handleSubmit, watch, setValue } =
    useForm<LoansAddLoanForm>();
  const lenderWatch = watch("lenderId");
  const statusCodeWatch = watch("sbaStatusCodeId");
  const onSubmit = handleSubmit((form) => {
    onSave(form);
  });
  const [openDeferment, setOpenDeferment] = React.useState(false);
  const handleOpen = React.useCallback(() => {
    setOpenDeferment(true);
  }, []);
  const handleClose = React.useCallback(() => {
    setOpenDeferment(false);
  }, []);
  const { data: LenderList } = useGetAllLenders({ pageSize: 1000 });

  // to control whether Ceiling and Floor input are shown
  const [hidden, setHidden] = useState(false);

  const handleToggle = useCallback(() => {
    setHidden(!hidden);
    setValue("ceiling", null);
    setValue("floor", null);
  }, [hidden, setHidden, setValue]);

  function mapFields(grid: JSX.Element[]) {
    return grid.map((field, i) => (
      <Grid key={i} item xs={12} md={6}>
        {field}
      </Grid>
    ));
  }

  React.useEffect(() => {
    if (statusCodeWatch === 4) {
      handleOpen();
    }
  }, [statusCodeWatch, handleOpen]);

  const generalInformation = [
    <DropDownField
      control={control}
      name="lenderId"
      label="Lender"
      options={LenderList?.items || []}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.name}
      required
    />,
    <TextField
      control={control}
      name="borrowerName"
      label="Borrower Name"
      required
    />,
    <TextField
      control={control}
      name="operatingName"
      label="DBA/Operating Name"
    />,
    <TaxIdField control={control} name="taxId" label="Borrower TIN" />,

    <TextField
      control={control}
      name="lenderLoanNumber"
      label="Lender Loan Number"
    />,

    <DropDownField
      control={control}
      name="loanPurposeId"
      label="Loan Purpose"
      options={loanDropDownOptions.loanPurposes}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,

    <DropDownField
      control={control}
      name="loanTypeId"
      label="Loan Type"
      options={loanDropDownOptions.loanType}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <MoneyField
      control={control}
      name="commitmentAmount"
      label="Commitment Amount"
    />,

    <DateField control={control} name="noteDate" label="Note Date" />,

    <TextField control={control} name="loanTerm" label="Loan Term (Months)" />,
    <TextField
      control={control}
      name="interestOnlyPeriod"
      label="Interest Only Period"
    />,
    <TextField
      control={control}
      name="amortizationPeriod"
      label="Amortization Period (Months)"
    />,
  ];

  const RatesRepaymentStructure = [
    <DropDownField
      control={control}
      name="baseRateCodeId"
      label="Base Rate Code"
      options={loanDropDownOptions.baseRateCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,

    <PercentField control={control} name="spread" label="Spread" />,

    <DropDownField
      control={control}
      name="rateAdjustmentId"
      label="Rate Adjustment Req"
      options={loanDropDownOptions.rateAdjustment}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <Stack spacing={1}>
      <PercentField
        control={control}
        name="floor"
        label="Floor"
        sx={{ display: hidden ? "none" : "" }}
      />
      <PercentField
        control={control}
        name="ceiling"
        label="Ceiling"
        sx={{ display: hidden ? "none" : "" }}
      />
    </Stack>,
    <DropDownField
      control={control}
      name="dayCodeId"
      label="Day Code"
      options={loanDropDownOptions.dayCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name="accuralBasisId"
      label="Accrual Basis"
      options={loanDropDownOptions.accuralBasis}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name="repaymentTypeId"
      label="Repayment Type"
      options={loanDropDownOptions.repaymentType}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <DropDownField
      control={control}
      name="paymentFrequencyId"
      label="Payment Frequency"
      options={loanDropDownOptions.paymentFrequency}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <TextField
      control={control}
      name="scheduledDueDay"
      label="Scheduled Due Day"
    />,
    <DateField.Calculated
      control={control}
      name="nextDueDate"
      label="Next Due Date"
    />,
  ];
  const PrepayInfo = [
    <TextField
      control={control}
      name="prepayPenaltyDescription"
      label="Prepay Penalty Description"
    />,
    <TextField.Calculated
      control={control}
      name="prepaymentPenaltyAmount"
      label="Prepay Penalty Amount"
    />,
    <PercentField.Calculated
      control={control}
      name="prepaymentPenaltyPercentage"
      label="Prepay Penalty %"
    />,
    <DateField.Calculated
      control={control}
      name="prepaymentExpirationDate"
      label="Prepay Exp Date"
    />,
  ];

  const SBA = [
    <DropDownField
      control={control}
      name="guaranteedPortionSoldId"
      label=" Guaranteed Portion Sold"
      options={loanDropDownOptions.guaranteedPortionSold}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,
    <TextField control={control} name="sbagpNumber" label="SBA GP Number" />,
    <MoneyField
      control={control}
      name="sbaGuarantyFeePaidAmount"
      label="SBA Guaranty Fee Paid Amount"
    />,
    <DateField
      control={control}
      name="sbaGuarantyFeePaidDate"
      label="SBA Guaranty Fee Paid Date"
    />,
    <DateField
      control={control}
      name="sbaAuthorizationDate"
      label="SBA Authorization Date"
    />,

    <DropDownField
      control={control}
      name="sbaStatusCodeId"
      label="SBA Status Code"
      options={loanDropDownOptions.sbaStatusCode}
      getOptionKey={(opt) => opt.id}
      getOptionLabel={(opt) => opt.label}
    />,

    <PercentField
      control={control}
      name="sbaGuarantyFeeBasisPercent"
      label="SBA Ongoing Guaranty Fee Basis Point %"
    />,
    <PercentField
      control={control}
      name="sbagfPercent"
      label="SBA Guaranty %"
    />,
    <DateField
      control={control}
      name="interestPaidFromDate"
      label="Interest Paid from Date"
    />,
    <PercentField.Calculated
      control={control}
      name="serviceFeePercent"
      label="IFS Service Fee %"
    />,
  ];

  // set service fee percent to the selected lender's service fee config
  // can be overridden by user if necessary
  useUpdateEffect(() => {
    if (lenderWatch)
      setValue("serviceFeePercent", {
        value: LenderList?.items[lenderWatch]?.serviceFeePercent,
        overridden: false,
      });
  }, [lenderWatch, LenderList, setValue]);

  return (
    <form onSubmit={onSubmit}>
      <Box>
        <FormErrorAlert />
        <Grid container spacing={4}>
          {mapFields(generalInformation)}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h5">Rates / Repayment Structure</Typography>
        </Box>
        <Grid container spacing={4}>
          {mapFields(RatesRepaymentStructure)}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h5">Prepay Info</Typography>
        </Box>
        <Grid container spacing={4}>
          {PrepayInfo.map((field, i) => (
            <Grid key={i} item xs={12} md={6}>
              {field}
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            my: 4,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <Typography variant="h5">SBA</Typography>
        </Box>
        <Grid container spacing={4}>
          {mapFields(SBA)}
        </Grid>
        <Dialog open={openDeferment} onClose={handleClose}>
          <DefermentForm
            control={control}
            defermentOpt={loanDropDownOptions?.defermentType ?? []}
            action={
              <>
                <Button onClick={handleClose} sx={{ ml: 2 }}>
                  <Icon fixedWidth icon={icons.check} />
                </Button>
                <Button onClick={handleClose} sx={{ ml: 2 }}>
                  <Icon fixedWidth icon={icons.close} />
                </Button>
              </>
            }
          />
        </Dialog>
        {
          <Stack
            my={4}
            direction="row"
            justifyContent="flex-end"
            py={2}
            sx={{
              backgroundColor: "#ffffff",
              position: "sticky",
              bottom: "0",
              left: "0",
              zIndex: "2",
            }}
            spacing={1}
          >
            <Button
              size="medium"
              onClick={() => handleOpen()}
              hidden={statusCodeWatch !== 4}
            >
              Deferment Info
            </Button>
            <Button onClick={handleToggle}>
              {hidden ? "Show Ceiling/Floor" : "Hide Ceiling/Floor"}
            </Button>
            <SubmitButton variant="save" />
          </Stack>
        }
      </Box>
    </form>
  );
}

export default withFormStatus(AddLoanForm);
