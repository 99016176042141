import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import {
  useGetInterestRateById
} from "api/interest-rate/interest-rate";
import RateHistory from "./RateHistory";

function AdminRatesDetailPage() {
  const { rateId: rateIdParam } = useParams<{ rateId: string }>();
  const rateId = Number(rateIdParam);
  var { data: interestRateResult } = useGetInterestRateById(rateId);
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {interestRateResult && (
            <RateHistory
              baseRateCodeId={rateId}
            ></RateHistory>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminRatesDetailPage;
