import { Controller, useForm } from "react-hook-form";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  FormErrorAlert,
  SubmitButton,
  useFieldList,
  withFormStatus,
} from "shared/forms";
import TextField from "shared/forms/fields/TextField";
import DropDownField from "shared/forms/fields/DropDownField";
import {
  ActivitiesGetActivityByIdActivityModel,
  UpdateActivityBody,
  PermitModel,
  useGetAllLoans,
} from "api";
import RadioGroupField from "shared/forms/fields/RadioGroupField";
import DateField from "shared/forms/fields/DateField";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import DownloadDocumentLink from "shared/documents/DownloadDocumentLink";

interface FormProps<ActivitiesGetActivityByIdResult, UpdateActivityBody> {
  permit: PermitModel;
  activity: ActivitiesGetActivityByIdResult;
  onSave(form: UpdateActivityBody): void;
}

const yesNoRadioValues = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const assignToRadioValues = [
  { value: 1, label: "IFS Admin" },
  { value: 2, label: "Lender" },
];
const activityTypeRadioValues = [
  { value: 1, label: "Note" },
  { value: 2, label: "Document" },
];
const isGeneralActivityRadioValues = [
  { value: true, label: "General" },
  { value: false, label: "Loan" },
];

function EditActivityForm({
  activity,
  permit,
  onSave,
}: FormProps<ActivitiesGetActivityByIdActivityModel, UpdateActivityBody>) {
  const [isDocument, setIsDocument] = useState(
    activity.attachments?.length! > 0 ?? false
  );

  const { watch, control, handleSubmit, reset } = useForm<UpdateActivityBody>({
    defaultValues: {
      ...activity,
      loanId: activity.loanId ?? undefined,
      assignToId: activity.assignToId ?? undefined,
      isGeneralActivity: activity.isGeneralActivity ?? null,
      activityFor: activity.activityFor ?? undefined,
      comments: activity.comments ?? undefined,
      responseComments: activity.responseComments ?? undefined,
      dueDate: activity.dueDate ?? undefined,
      activityTypeId: activity.activityTypeId ?? undefined,
    },
  });

  const isTask = watch("isTask");
  const isGeneralActivity = watch("isGeneralActivity");

  useEffect(() => {
    const subscription = watch((value) =>
      value.activityTypeId === 2 ? setIsDocument(true) : setIsDocument(false)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const { data: loanList } = useGetAllLoans(
    {},
    { query: { keepPreviousData: true } }
  );

  const { readOnly } = useFieldList<UpdateActivityBody>(
    permit.updateableFields
  );

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) =>
        setUploadedFiles([...uploadedFiles, file])
      );
    },
    [uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = uploadedFiles.map((file) => (
    <Box key={file.name} sx={{ py: 1 }}>
      {file.name}
    </Box>
  ));

  // when we reload loan data, reset the form
  useEffect(() => {
    setUploadedFiles([]);
    reset({
      ...activity,
      loanId: activity.loanId ?? undefined,
      lenderId: activity.lenderId ?? undefined,
      assignToId: activity.assignToId ?? undefined,
      isGeneralActivity: activity.isGeneralActivity ?? null,
      activityFor: activity.activityFor ?? undefined,
      comments: activity.comments ?? undefined,
      responseComments: activity.responseComments ?? undefined,
      dueDate: activity.dueDate ?? undefined,
      activityTypeId: activity.activityTypeId ?? undefined,
    });
  }, [activity, reset]);

  const onSubmit = handleSubmit((form) => {
    if (form.isGeneralActivity) form.activityFor = undefined;
    form.file = uploadedFiles;
    onSave({
      ...form,
      file: uploadedFiles,
    });
  });

  // **For Lenders view when we have permissions set up**

  // const activityTypeReadOnly = activityTypeRadioValues.find(x => x.value === activity.activityTypeId);
  // const isTaskReadOnly = yesNoRadioValues.find(x => x.value === activity.isTask);
  // const assignToReadOnly = assignToRadioValues.find(x => x.value === activity.assignToId);
  // const isPrivateReadOnly = yesNoRadioValues.find(x => x.value === activity.makePrivate);
  // const isGeneralReadOnly = isGeneralActivityRadioValues.find(x => x.value === activity.isGeneralActivity);

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <RadioGroupField
            control={control}
            labelName="Activity Type"
            name="activityTypeId"
            options={activityTypeRadioValues}
            readOnly={readOnly("activityTypeId")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RadioGroupField
            control={control}
            labelName="is This a Task?"
            name="isTask"
            options={yesNoRadioValues}
            readOnly={readOnly("isTask")}
          />
        </Grid>
        {isTask && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <RadioGroupField
                control={control}
                labelName="Assign To"
                name="assignToId"
                options={assignToRadioValues}
                readOnly={readOnly("assignToId")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateField
                control={control}
                name="dueDate"
                label="Activity Due Date"
                readOnly={readOnly("dueDate")}
              />
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <RadioGroupField
            control={control}
            labelName="Make Private"
            name="makePrivate"
            options={yesNoRadioValues}
            readOnly={readOnly("makePrivate")}
          />
        </Grid>
        {!activity.loanId && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <RadioGroupField
                control={control}
                labelName="Activity For"
                name="isGeneralActivity"
                options={isGeneralActivityRadioValues}
                readOnly={readOnly("isGeneralActivity")}
              />
            </Grid>
            {isGeneralActivity === false && (
              <React.Fragment>
                <Grid item xs={12}>
                  <DropDownField
                    options={loanList?.items || []}
                    getOptionKey={(opt) => opt.lenderLoanNumber}
                    getOptionLabel={(opt) => opt.lenderLoanNumber}
                    control={control}
                    name="activityFor"
                    label="Lender Loan Number"
                    readOnly={readOnly("activityFor")}
                  />
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <TextField
            control={control}
            name="activityTitle"
            label="Activity Title"
            readOnly={readOnly("activityTitle")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="comments"
            label="Comments"
            multiline={true}
            rows={5}
            readOnly={readOnly("comments")}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Uploaded Files
          </Typography>
          <ul>
            {activity.attachments?.map((file) => (
              <li key={file.documentKey}>
                <DownloadDocumentLink
                  activityId={activity.id}
                  attachment={file}
                />
              </li>
            ))}
          </ul>
        </Grid>
        {isDocument && (
          <React.Fragment>
            <Grid item xs={12}>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#fff",
                  border: "2px dashed",
                  borderColor: "secondary.main",
                }}
              >
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: "gray.light",
                        "&:hover": { backgroundColor: "grid.rowHover" },
                      }}
                      {...getRootProps()}
                    >
                      <input
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...getInputProps()}
                      />
                      <Typography
                        textAlign="center"
                        variant="body1"
                        fontWeight="bold"
                      >
                        Drag 'n' drop file here, or click to select file
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
              <Box sx={{ pt: 1 }}>{files}</Box>
            </Grid>
          </React.Fragment>
        )}

        <Grid item xs={12}>
          <TextField
            control={control}
            name="responseComments"
            label="Response Field"
            multiline={true}
            rows={5}
            readOnly={readOnly("comments")}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupField
            control={control}
            labelName="Activity Complete?"
            name="isComplete"
            options={yesNoRadioValues}
            readOnly={readOnly("isComplete")}
          />
        </Grid>
      </Grid>
      <Stack pt={2} direction="row" justifyContent="flex-end">
        <SubmitButton variant="save" />
      </Stack>
    </Box>

    // **For Lenders view when we have permissions set up**

    //   <Box onSubmit={onSubmit} component="form">
    //   <FormErrorAlert />
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} md={6}>
    //       Activity Type: {activityTypeReadOnly?.label}
    //     </Grid>
    //     <Grid item xs={12} md={6}>
    //     Is Task: {isTaskReadOnly?.label}
    //     </Grid>
    //     {isTask && (
    //       <React.Fragment>
    //         <Grid item xs={12} md={6}>
    //         Assign To: {assignToReadOnly?.label}
    //         </Grid>
    //         <Grid item xs={12} md={6}>
    //           Due Date: {activity.dueDate}
    //         </Grid>
    //       </React.Fragment>
    //     )}
    //     {!activity.loanId && (
    //       <React.Fragment>
    //         <Grid item xs={12} md={6}>
    //        Activity Type: {isGeneralReadOnly?.label}
    //         </Grid>
    //         {isGeneralActivity === false && (
    //           <React.Fragment>
    //             <Grid item xs={12}>
    //               Lender Loan Number: {activity.activityFor}
    //             </Grid>
    //           </React.Fragment>
    //         )}
    //       </React.Fragment>
    //     )}
    //     <Grid item xs={12}>
    //      Activity Title: {activity.activityTitle}
    //     </Grid>
    //     <Grid item xs={12}>
    //       Is Private: {isPrivateReadOnly?.label}
    //     </Grid>
    //     <Grid item xs={12}>
    //       <TextField
    //         control={control}
    //         name="comments"
    //         label="Comments"
    //         multiline={true}
    //         rows={5}
    //         readOnly
    //       />
    //     </Grid>
    //     {isDocument && (
    //       <React.Fragment>
    //         <Grid item xs={12}>
    //           <TextField
    //             control={control}
    //             name="uploadedDocument"
    //             label="Documents Place Holder"
    //             readOnly
    //           />
    //         </Grid>
    //       </React.Fragment>
    //     )}
    //           <Grid item xs={12}>
    //       <TextField
    //         control={control}
    //         name="responseComments"
    //         label="Response Field"
    //         multiline={true}
    //         rows={5}
    //         readOnly={readOnly("comments")}
    //       />
    //     </Grid>
    //     <Grid item xs={12}>
    //       <RadioGroupField
    //         control={control}
    //         labelName="Activity Complete?"
    //         name="isComplete"
    //         options={yesNoRadioValues}
    //         readOnly={readOnly("isComplete")}
    //       />
    //     </Grid>
    //   </Grid>
    //   <Stack pt={2} direction="row" justifyContent="flex-end">
    //     <SubmitButton variant="save" />
    //   </Stack>
    // </Box>
  );
}

export default withFormStatus(EditActivityForm);
