import { useState } from "react";
import {
  DataGridPro,
  GridColumns,
  GridEnrichedColDef,
} from "@mui/x-data-grid-pro";
import { Box, Link, Card, CardContent } from "@mui/material";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDeleteLoan, useGetAllLoans } from "api";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import formatNumber from "shared/formatters/number";
import formatDate from "shared/formatters/date";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import useLenderContext from "shared/useLenderContext";
import { DeleteForever } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";
import formatPercent from "shared/formatters/percentage";

function Toolbar(
  props: Pick<
    DataGridToolbarProps,
    "onSearch" | "onAddModal" | "onLoanSoldFilter"
  >
) {
  const history = useHistory();
  const { data } = useGetAllLoans({ pageSize: 1 });

  return (
    <DataGridToolbar
      header={"Loan List"}
      addButtonLabel={data?.permitModel.create ? "Add Loan" : null}
      loanSoldFilterLabel="Is Sold?"
      onAdd={() => history.push("/loans/add")}
      exportHiddenCols={true}
      {...props}
    />
  );
}

interface Filters {
  loanSoldStateQuery?: number;
  searchQuery?: string;
}

function LoanListPage() {
  const columns: GridColumns = [
    {
      field: "lenderLoanNumber",
      headerName: "Loan ID",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/loans/${row.id}`}>
          {row.lenderLoanNumber}
        </Link>
      ),
    },
    {
      field: "borrowerName",
      headerName: "Borrow Name",
      headerAlign: "left",
      align: "left",
      width: 275,
    },
    {
      field: "lenderName",
      headerName: "Lender Name",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/lenders/${row.lenderId}`}>
          {row.lenderName}
        </Link>
      ),
    },
    {
      field: "commitmentAmount",
      headerName: "Commitment Amount",
      headerAlign: "left",
      align: "left",
      width: 150,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "amountDisbursed",
      headerName: "Amount Disbursed",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Amount</div>
          <div>Disbursed</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
      valueGetter: (params) => params.row.amountDisbursed?.value,
    },
    {
      field: "remainingCommitment",
      headerName: "Remaining Commitment",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Remaining</div>
          <div>Commitment</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
      valueGetter: (params) => params.row.remainingCommitment?.value,
    },
    {
      field: "principalBalance",
      headerName: "Principal Balance",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Principal</div>
          <div>Balance</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
      valueGetter: (params) => params.row.principalBalance?.value,
    },
    {
      field: "systemPrincipalBalance",
      headerName: "Reported Principal Balance",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Reported Principal</div>
          <div>Balance</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
      valueGetter: (params) => params.row.systemPrincipalBalance,
    },
    {
      field: "accruingBalance",
      headerName: "Accruing Balance",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Accruing</div>
          <div>Balance</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "loanTerm",
      headerName: "Loan Term",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Loan</div>
          <div>Term</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatNumber),
    },
    {
      field: "firstDisbursementDate",
      headerName: "First Disbursement Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>First</div>
          <div>Disbursement Date</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatDate),
      valueGetter: (params) => params.row.firstDisbursementDate?.value,
    },
    {
      field: "maturityDate",
      headerName: "Maturity Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Maturity</div>
          <div>Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.maturityDate?.value,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "loanPurpose",
      headerName: "Loan Purpose",
      headerAlign: "left",
      align: "left",
      width: 150,
    },
    {
      field: "noteDate",
      headerName: "Note Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "spread",
      headerName: "Spread",
      headerAlign: "left",
      align: "left",
      width: 150,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "floor",
      headerName: "Floor",
      headerAlign: "left",
      align: "left",
      width: 150,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "ceiling",
      headerName: "Ceiling",
      headerAlign: "left",
      align: "left",
      width: 150,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "NextDueDate",
      headerName: "Next Due Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Next</div>
          <div>Due Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.nextDueDate?.value,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "PrepaymentPenaltyPercentage",
      headerName: "Prepayment Penalty Percentage",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Prepayment</div>
          <div>Penalty Percentage</div>
        </div>
      ),
      valueGetter: (params) => params.row.prepaymentPenaltyPercentage?.value,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "PrepaymentPenaltyAmount",
      headerName: "Prepayment Penalty Amount",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Prepayment</div>
          <div>Penalty Amount</div>
        </div>
      ),
      valueGetter: (params) => params.row.prepaymentPenaltyAmount?.value,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "BaseRatePercentage",
      headerName: "Base Rate Percentage",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Base Rate</div>
          <div>Percentage</div>
        </div>
      ),
      valueGetter: (params) => params.row.baseRatePercentage?.value,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "PrepaymentExpirationDate",
      headerName: "Prepayment Expiration Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Prepayment</div>
          <div>Expiration Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.prepaymentExpirationDate?.value,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "EffectiveRate",
      headerName: "Effective Rate",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Effective</div>
          <div>Rate</div>
        </div>
      ),
      valueGetter: (params) => params.row.effectiveRate?.value,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "NextRateChangeDate",
      headerName: "Next Rate Change Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Next Rate</div>
          <div>Change Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.nextRateChangeDate?.value,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "ServiceFeePercent",
      headerName: "Service Fee Percent",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Service Fee</div>
          <div>Percent</div>
        </div>
      ),
      valueGetter: (params) => params.row.serviceFeePercent?.value,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "InterestPaidFromDate",
      headerName: "Interest Paid From Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Interest Paid</div>
          <div>From Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.interestPaidFromDate,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "sbagpNumber",
      headerName: "SBAGP Number",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBAGP</div>
          <div>Number</div>
        </div>
      ),
    },
    {
      field: "sbaGuarantyFeePaidAmount",
      headerName: "SBAGuaranty FeePaid Amount",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBAGuaranty</div>
          <div>FeePaid Amount</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "sbaGuarantyFeePaidDate",
      headerName: "SBAGuaranty Fee Paid Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBA Guaranty</div>
          <div>Fee Paid Date</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "sbagfPercent",
      headerName: "SBAGF Percent",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBAGF</div>
          <div>Percent</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "sbaGuarantyFeeBasisPercent",
      headerName: "sba Guaranty Fee Basis Point Percent",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBA Guaranty Fee </div>
          <div>Basis Point Percent</div>
        </div>
      ),
      valueGetter: (params) =>
        params.row.sbaGuarantyFeeBasisPercent,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "amortizationPeriod",
      headerName: "Amortization Period",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Amortization</div>
          <div>Period</div>
        </div>
      ),
    },
    {
      field: "interestOnlyPeriod",
      headerName: "Interest Only Period",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Interest Only</div>
          <div>Period</div>
        </div>
      ),
    },
    {
      field: "prepayPenaltyDescription",
      headerName: "Prepay Penalty Description",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Prepay Penalty</div>
          <div>Description</div>
        </div>
      ),
    },
    {
      field: "paymentAmount",
      headerName: "Payment Amount",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Payment</div>
          <div>Amount</div>
        </div>
      ),
      valueGetter: (params) => params.row.paymentAmount?.value,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "accumulatetoDate",
      headerName: "Accumulate to Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>Accumulate</div>
          <div>to Date</div>
        </div>
      ),
      valueGetter: (params) => params.row.accumulatetoDate?.value,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "sbaAuthorizationDate",
      headerName: "SBA Authorization Date",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderHeader: () => (
        <div className="data-header-no-line-height">
          <div>SBA Authorization</div>
          <div>Date</div>
        </div>
      ),
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "delete",
      headerName: "Delete Loan",
      headerAlign: "left",
      align: "left",
      width: 150,
      renderCell: ({ row }) => (
        <Link
          onClick={() => {
            setDeleteId(row.id);
            setShowDeleteDialog(true);
          }}
        >
          <DeleteForever></DeleteForever>
        </Link>
      ),
    },
  ];

  const { grid, query, updateLoanSoldStateQuery, updateSearchQuery } =
    useDataGrid<Filters>("loans", columns.concat(GenerateParticipantCols()));
  const { enqueueSnackbar } = useSnackbar();
  const { lenderId } = useLenderContext();
  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const { data, isFetching, refetch } = useGetAllLoans(
    {
      ...query,
      lenderId,
    },
    { query: { keepPreviousData: true } }
  );

  function onLoanSoldStateFilter(loanSoldState: number | null) {
    updateLoanSoldStateQuery({ loanSoldStateQuery: loanSoldState });
  }
  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  const { mutate: deleteLoanMutate } = useDeleteLoan({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Loan Deleted", {
          variant: "success",
        });
        refetch();
      },
    },
  });
  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box
              sx={{
                flexGrow: 1,
                wrap: "nowrap",
              }}
            >
              <DataGridPro
                className="data-grid-max-height-main"
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                    onLoanSoldFilter: (type: number | null) => {
                      onLoanSoldStateFilter(type);
                    },
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
      <AcceptDialogWarning
        alertMessage="Are you sure you want to delete the loan?"
        initialWarningDialog={showDeleteDialog}
        showDialog={(show) => {
          setShowDeleteDialog(show);
        }}
        acceptDelete={(accept) => {
          if (accept) {
            deleteLoanMutate({ loanId: deleteId });
            setDeleteId(0);
          }
        }}
      />
    </Card>
  );
}

export default LoanListPage;

function GenerateParticipantCols() {
  return [
    {
      field: "investorName",
      headerName: "Investor Name",
      valueGetter: (params) => params.row.participant?.investorName ?? "",
    },
    {
      //PERCENT
      field: "loanSalePremiumAmount",
      headerName: "Loan Sale Premium",
      valueGetter: (params) =>
        params.row.participant?.loanSalePremiumAmount ?? 0,
      valueFormatter: formatGridCell(formatNumber),
    },
    {
      //DATE
      field: "loanSaleSettlementDate",
      headerName: "Loan Sale Settlement Date",
      valueGetter: (params) =>
        params.row.participant?.loanSaleSettlementDate ?? 0,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      //PERCENT
      field: "percentageSold",
      headerName: "Percentage Sold",
      valueGetter: (params) => params.row.participant?.percentageSold ?? 0,
      valueFormatter: formatGridCell(formatNumber),
    },
    {
      //NUMBER
      field: "principalAmountSold",
      headerName: "Principal Amount Sold",
      valueGetter: (params) => params.row.participant?.principalAmountSold ?? 0,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      //DATE
      field: "accumulateToDate",
      headerName: "Accumulate to Date",
      valueGetter: (params) => params.row.participant?.accumulateToDate ?? 0,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      //CURRENCY
      field: "interestReceivable",
      headerName: "Interest Receivable",
      valueGetter: (params) => params.row.participant?.interestReceivable ?? 0,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      //PERCENT
      field: "servicingFee",
      headerName: "Servicing Fee",
      valueGetter: (params) => params.row.participant?.servicingFee ?? 0,
      valueFormatter: formatGridCell(formatNumber),
    },
    {
      //CURRENCY
      field: "currentSoldBalance",
      headerName: "Current Sold Balance",
      valueGetter: (params) => params.row.participant?.currentSoldBalance ?? 0,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      //CURRENCY
      field: "loanSalePremiumToLender",
      headerName: "Loan Sale Premium to Lender",
      valueGetter: (params) =>
        params.row.participant?.loanSalePremiumToLender ?? 0,
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      //CURRENCY
      field: "netLoanSalePremiumToLender",
      headerName: "Net Loan Sale Premium to Lender",
      valueGetter: (params) =>
        params.row.participant?.netLoanSalePremiumToLender ?? 0,
      valueFormatter: formatGridCell(formatCurrency),
    },
  ] as GridEnrichedColDef[];
}
