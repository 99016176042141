import { useCallback, useEffect, useMemo } from "react";
import { useController } from "react-hook-form";
import { IMask, useIMask } from "react-imask";
import { FormTextFieldProps } from "./FieldVariants";

interface Transform<TValue> {
  toForm(inputValue: TValue): TValue;
  toInput(formValue: TValue): TValue;
}

const identityTransform: Transform<any> = {
  toForm: (v) => v,
  toInput: (v) => v,
};

type FormValue<TMask extends IMask.AnyMaskedOptions> = IMask.MaskedTypedValue<
  TMask["mask"]
>;

function useInputMask<T, TMask extends IMask.AnyMaskedOptions>(
  { control, name, rules }: FormTextFieldProps<T>,
  maskOptions: TMask,
  transform: Transform<FormValue<TMask>> = identityTransform
) {
  const {
    field: { onChange, onBlur, value: formValue, ref: inputRef },
  } = useController({
    control,
    name,
    rules,
  });

  const getValue = useCallback(
    (mask: IMask.InputMask<TMask>) => {
      return !mask || mask.value === ""
        ? null
        : transform.toForm(mask.typedValue);
    },
    [transform]
  );

  const setValue = useCallback(
    (mask: IMask.InputMask<TMask>, formValue: FormValue<TMask>) => {
      mask.typedValue = transform.toInput(formValue);
      mask.updateControl();
    },
    [transform]
  );

  const {
    ref: maskInputRef,
    maskRef: { current: maskRef },
  } = useIMask<TMask, "typed">(maskOptions, {
    onComplete(_, mask) {
      onChange(getValue(mask));
    },
  });

  const value = getValue(maskRef);

  useEffect(() => {
    if (maskRef && value !== formValue) {
      // if the form value has changed and no longer matches the input,
      // .. then we need to update the masked input to reflect the form
      // n.b. this could happen during init if the form value is retrieved async
      setValue(maskRef, formValue as FormValue<TMask>);
    }
  }, [maskRef, setValue, value, formValue]);

  return {
    value,
    maskedValue: maskRef?.masked,
    setValue,
    onBlur,
    inputRef(current: HTMLInputElement) {
      inputRef((maskInputRef.current = current));
    },
  };
}

export function useNumberMask<T>(
  props: FormTextFieldProps<T>,
  options: Omit<IMask.MaskedNumberOptions, "mask">,
  transform?: Transform<number>
) {
  const numberOptions = useMemo(
    () => ({
      mask: Number,
      ...options,
    }),
    [options]
  );

  return useInputMask<T, IMask.MaskedNumberOptions>(
    props,
    numberOptions,
    transform
  );
}

export default useInputMask;
