import React from "react";
import { Box, Card, CardContent, CardHeader, Grid } from "@mui/material";
import AddLoanForm from "./forms/AddLoanForm";
import { LoansAddLoanResult, useAddLoan } from "api";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import useLoanDropDownOptions from "shared/forms/useLoanDropDownOptions";
import InlineFormLayout from "shared/forms/InlineFormLayout";

function LoanAddLoanPage() {
  const loanDropDownOptions = useLoanDropDownOptions();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const { mutate, ...status } = useAddLoan({
    mutation: {
      onSuccess(data: LoansAddLoanResult) {
        history.push(`/loans/${data.loanId}`);
        enqueueSnackbar("Loan Added", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar(
          "Error Adding Loan. Please verify all fields and ensure the loan does not  already exist ",
          {
            autoHideDuration: 10000,
            variant: "error",
          }
        );
      },
    },
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4}>
        <Grid item xs={12} sx={{ display: "flex" }}>
          <Card elevation={4}>
            <CardHeader title="Add Loan" />
            <CardContent>
              <InlineFormLayout>
                <AddLoanForm
                  status={status}
                  onSave={(form) => mutate({ data: form })}
                  // initial data is provided
                  loanDropDownOptions={loanDropDownOptions!}
                />
              </InlineFormLayout>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
export default LoanAddLoanPage;
