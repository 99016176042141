import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { useAuthState } from "./auth/context";

function SignOutPage() {
  const auth = useAuthState();

  useEffect(() => {
    auth.signOut();
  }, [auth]);

  return <Redirect to="/" />;
}

export default SignOutPage;
