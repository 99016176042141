import React from "react";
import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  ReportsGetDueDateDetailsReportDateModel,
  ReportsUpdateReportDateForm,
} from "api";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import StickyFooter from "shared/layout/StickyFooter";
import DateField from "shared/forms/fields/DateField";
import Months from "shared/MonthDropDown.json";
import DropDownField from "shared/forms/fields/DropDownField";

interface Props {
  dueDate: ReportsGetDueDateDetailsReportDateModel;
  onSave(form: ReportsUpdateReportDateForm): void;
}

function EditDueDateForm({ dueDate, onSave }: Props) {
  const { control, handleSubmit } = useForm<ReportsUpdateReportDateForm>({
    defaultValues: dueDate,
  });
console.log(dueDate);
  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <DropDownField
            options={Months}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.key}
            control={control}
            name="reportingMonth"
            label="Reporting Month"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <DateField
            control={control}
            name="reportStartOfPeriod"
            label="Report Start of Period"
          />
        </Grid>

        <Grid item xs={12} md={12}>
          <DateField
            control={control}
            name="reportEndOfPeriod"
            label="Report End of Period"
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <DateField
            control={control}
            name="reportDueDate"
            label="Report Due Date"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DateField
            control={control}
            name="reportGracePeriod"
            label="Report Grace Period"
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <SubmitButton variant="save" />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditDueDateForm);
