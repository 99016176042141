import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Modal,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import useDataGrid from "shared/grid/useDataGrid";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import { useAddReportDate, useGetAllReportDueDates } from "api";
import { useSnackbar } from "notistack";
import { useState } from "react";
import AddDueDateForm from "./forms/AddDueDateForm";
import { icons } from "theme";
import Icon from "shared/Icon";

const columns: GridColumns = [
  {
    field: "reportingMonth",
    headerName: "Reporting Month",
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/admin/dueDates/${row.id}`}>
        {row.reportingMonth}
      </Link>
    ),
  },
  {
    field: "reportDueDate",
    headerName: "Report Due Date",
    flex: 1,
  },
  {
    field: "reportGracePeriod",
    headerName: "Report Grace Period",
    flex: 1,
  },
];
function Toolbar(props: Pick<DataGridToolbarProps, "onAddModal">) {
  return (
    <DataGridToolbar
      header={"Due Date List"}
      addModalButtonLabel="Add Due Date"
      exportButton={false}
      {...props}
    />
  );
}

function DueDateListPage() {
  const { grid, query } = useDataGrid("Due Dates", columns);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: DueDateMutate, ...activityStatus } = useAddReportDate({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Report Date Added", {
          variant: "success",
        });
        setOpen(false);
      },
    },
  });

  const { data, isFetching, refetch } = useGetAllReportDueDates(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );
  return (
    <>
      <Card elevation={4} sx={{ flex: 1 }}>
        <CardContent sx={{ flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", height: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                <DataGridPro
                  {...grid}
                  rows={data?.items ?? []}
                  rowCount={data?.total}
                  loading={isFetching}
                  components={{
                    Toolbar: Toolbar,
                  }}
                  componentsProps={{
                    toolbar: {
                      onAddModal: (setModal: boolean) => {
                        return setOpen(setModal);
                      },
                    },
                  }}
                  autoHeight
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          elevation={4}
          sx={{
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <CardHeader
            title="Add Due Date"
            action={
              <Button sx={{ ml: 2 }} onClick={handleClose}>
                <Icon fixedWidth icon={icons.close} />
              </Button>
            }
          />
          <CardContent>
            <AddDueDateForm
              onSave={(form) => {
                DueDateMutate({ data: form });
              }}
              status={activityStatus}
            />
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

export default DueDateListPage;
