var formatter = new Intl.NumberFormat("en-US", {
  style: "decimal",

  minimumFractionDigits: 0,
  maximumFractionDigits: 4,
});

export default function formatNumber(
  value: number | string | null | undefined
) {
  if (typeof value === "string") {
    value = Number(value);
  }

  if (typeof value === "number") {
    return formatter.format(value);
  }

  return "-";
}
