/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ParticipantsGetParticipantsResult,
  GetParticipantsParams,
  ParticipantsAddParticipantResult,
  ParticipantsAddParticipantForm,
  ParticipantsGetParticipantByIdResult,
  GetParticipantByIdParams,
  ParticipantsGetParticipantPaymentsResult,
  GetParticipantPaymentsParams,
  ParticipantsUpdateParticipantResult,
  ParticipantsUpdateParticipantForm
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getParticipants = (
    loanId: number,
    params?: GetParticipantsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ParticipantsGetParticipantsResult>(
      {url: `/participant/${loanId}`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetParticipantsQueryKey = (loanId: number,
    params?: GetParticipantsParams,) => [`/participant/${loanId}`, ...(params ? [params]: [])];

    
export const useGetParticipants = <TData = AsyncReturnType<typeof getParticipants>, TError = unknown>(
 loanId: number,
    params?: GetParticipantsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getParticipants>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetParticipantsQueryKey(loanId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getParticipants>> = () => getParticipants(loanId,params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getParticipants>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const addParticipant = (
    loanId: number,
    participantsAddParticipantForm: ParticipantsAddParticipantForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ParticipantsAddParticipantResult>(
      {url: `/participant/${loanId}`, method: 'post',
      data: participantsAddParticipantForm
    },
      options);
    }
  


    export const useAddParticipant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addParticipant>, TError,{loanId: number;data: ParticipantsAddParticipantForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addParticipant>, {loanId: number;data: ParticipantsAddParticipantForm}> = (props) => {
          const {loanId,data} = props || {};

          return  addParticipant(loanId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addParticipant>, TError, {loanId: number;data: ParticipantsAddParticipantForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getParticipantById = (
    params?: GetParticipantByIdParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ParticipantsGetParticipantByIdResult>(
      {url: `/participant`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetParticipantByIdQueryKey = (params?: GetParticipantByIdParams,) => [`/participant`, ...(params ? [params]: [])];

    
export const useGetParticipantById = <TData = AsyncReturnType<typeof getParticipantById>, TError = unknown>(
 params?: GetParticipantByIdParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getParticipantById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetParticipantByIdQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getParticipantById>> = () => getParticipantById(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getParticipantById>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getParticipantPayments = (
    params?: GetParticipantPaymentsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ParticipantsGetParticipantPaymentsResult>(
      {url: `/participant/getparticipantpayments`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetParticipantPaymentsQueryKey = (params?: GetParticipantPaymentsParams,) => [`/participant/getparticipantpayments`, ...(params ? [params]: [])];

    
export const useGetParticipantPayments = <TData = AsyncReturnType<typeof getParticipantPayments>, TError = unknown>(
 params?: GetParticipantPaymentsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getParticipantPayments>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetParticipantPaymentsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getParticipantPayments>> = () => getParticipantPayments(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getParticipantPayments>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const updateParticipant = (
    participantId: number,
    participantsUpdateParticipantForm: ParticipantsUpdateParticipantForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<ParticipantsUpdateParticipantResult>(
      {url: `/participant/update/${participantId}`, method: 'post',
      data: participantsUpdateParticipantForm
    },
      options);
    }
  


    export const useUpdateParticipant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateParticipant>, TError,{participantId: number;data: ParticipantsUpdateParticipantForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateParticipant>, {participantId: number;data: ParticipantsUpdateParticipantForm}> = (props) => {
          const {participantId,data} = props || {};

          return  updateParticipant(participantId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateParticipant>, TError, {participantId: number;data: ParticipantsUpdateParticipantForm}, TContext>(mutationFn, mutationOptions)
    }
    