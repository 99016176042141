import { useCallback, useState } from "react";

export default function useUserPreference<T>(key: string, defaultState: T) {
  const [state, setState] = useState<T>(() => {
    const item = localStorage.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
    return defaultState;
  });

  const updateState = useCallback(
    (next: T) => {
      localStorage.setItem(key, JSON.stringify(next));
      setState(next);
    },
    [key]
  );

  return [state, updateState] as const;
}
