import { Dispatch } from "react";
import {
  FlowAction,
  FlowState,
  useFlow,
  DispatchRef,
  createUser,
} from "./flow";

type ForgotPasswordFlowStep =
  | ForgotPasswordStep
  | ResetPasswordStep
  | ForgotPasswordCompleteStep;
type ForgotPasswordAction = FlowAction<ForgotPasswordFlowStep>;
export type ForgotPasswordFlow = FlowState & ForgotPasswordFlowStep;

export function useForgotPasswordFlow(): ForgotPasswordFlow {
  return useFlow<ForgotPasswordFlowStep>((dispatch) =>
    forgotPasswordStep(dispatch)
  );
}

export function useResetPasswordFlow(username: string): ForgotPasswordFlow {
  return useFlow<ForgotPasswordFlowStep>((dispatch) =>
    resetPasswordStep(dispatch, username)
  );
}

interface ForgotPasswordStep {
  step: "ForgotPasswordStep";
  onForgotPassword: (username: string) => void;
}

function forgotPasswordStep(
  dispatchRef: DispatchRef<ForgotPasswordAction>
): ForgotPasswordStep {
  return {
    step: "ForgotPasswordStep",
    onForgotPassword(username: string) {
      const user = createUser(username);

      const dispatch = dispatchRef();
      dispatch({ type: "pending" });
      user.forgotPassword({
        onSuccess() {
          dispatch({
            type: "next",
            step: resetPasswordStep(dispatchRef, username),
          });
        },
        onFailure(err) {
          dispatch({ type: "error", err });
        },
      });
    },
  };
}

interface ResetPasswordStep {
  step: "ResetPasswordStep";
  username: string;
  onConfirmPassword: (password: string, code: string) => void;
}

function resetPasswordStep(
  dispatchRef: DispatchRef<ForgotPasswordAction>,
  username: string
): ResetPasswordStep {
  return {
    step: "ResetPasswordStep",
    username,
    onConfirmPassword(password: string, code: string) {
      const user = createUser(username);

      const dispatch = dispatchRef();
      dispatch({ type: "pending" });
      user.confirmPassword(code, password, {
        onSuccess() {
          dispatch({
            type: "next",
            step: forgotPasswordCompleteStep(dispatch),
          });
        },
        onFailure(err) {
          dispatch({ type: "error", err });
        },
      });
    },
  };
}

interface ForgotPasswordCompleteStep {
  step: "ForgotPasswordCompleteStep";
}

function forgotPasswordCompleteStep(
  _: Dispatch<ForgotPasswordAction>
): ForgotPasswordCompleteStep {
  return {
    step: "ForgotPasswordCompleteStep",
  };
}
