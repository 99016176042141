import { Redirect, Route, Switch } from "react-router";
import ForgotPasswordPage from "./ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage";
import SignInPage from "./SignInPage";
import SignOutPage from "./SignOutPage";
import ProfilePage from "./ProfilePage";
import { useRouteMatch } from "react-router-dom";

export function PublicRoutes() {
  return (
    <Switch>
      <Route path="/signin" component={SignInPage} />
      <Route path="/signout" component={SignOutPage} />
      <Route path="/forgot-password" component={ForgotPasswordPage} />
      <Route path="/reset-password" component={ResetPasswordPage} />
      <Redirect to="/signin" />
    </Switch>
  );
}

function AccountRoutes() {
  const { path } = useRouteMatch();
  return (
  <Switch>
    <Route path={`${path}/profile`} component={ProfilePage} />
  </Switch>
  );
}

export default AccountRoutes;
