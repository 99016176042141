import React from "react";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { FormTextFieldProps, asVariants } from "./FieldVariants";
import useFormErrors from "../useFormErrors";

type EmailFieldProps<T> = FormTextFieldProps<T>;

function EmailField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  variant = 'outlined',
  ...props
}: EmailFieldProps<T>) {
  const { getError } = useFormErrors();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <MuiTextField
          {...props}
          {...getError(name)}
          required
          placeholder="email@example.com"
          type="email"
          className={clsx(className, readOnly && "textfield-readonly")}
          value={value ?? undefined}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          inputProps={{ unmask: true }}
          InputProps={{
            readOnly,
          }}
          variant={variant}
        />
      )}
    />
  );
}

export default asVariants(EmailField);
