import React from "react";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box, Link, Card, CardContent } from "@mui/material";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useGetAllUsers } from "api";
import useDataGrid from "shared/grid/useDataGrid";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";

const columns: GridColumns = [
  {
    field: "id",
    headerName: "User Id",
    flex: 1,
    renderCell: ({ row }) => (
      <Link component={RouterLink} to={`/users/${row.id}`}>
        {row.id}
      </Link>
    ),
  },
  {
    field: "username",
    headerName: "Username",
    flex: 1,
  },
  {
    field: "name.first",
    headerName: "First Name",
    flex: 1,
    valueGetter: (params) => params.row.name?.first,
  },
  {
    field: "name.last",
    headerName: "Last Name",
    flex: 1,
    valueGetter: (params) => params.row.name?.last,
  },
  {
    field: "contact.email",
    headerName: "Email",
    flex: 1,
    valueGetter: (params) => params.row.contact?.email,
  },
];

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const history = useHistory();
  const { data } = useGetAllUsers({ pageSize: 1 });

  return (
    <DataGridToolbar
      header={"User List"}
      addButtonLabel={data?.permitModel.create ? "Add User" : null}
      onAdd={() => history.push("/users/add")}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function UserListPage() {
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "lenders",
    columns
  );

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  const { data, isFetching } = useGetAllUsers(
    {
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default UserListPage;
