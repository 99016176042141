/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  DefermentsGetDefermentsByLoanIdResult,
  GetLoanDefermentsParams,
  DefermentsAddOrUpdateDefermentResult,
  AddOrUpdateDefermentBody
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getLoanDeferments = (
    loanId: number,
    params?: GetLoanDefermentsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DefermentsGetDefermentsByLoanIdResult>(
      {url: `/deferment/${loanId}`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetLoanDefermentsQueryKey = (loanId: number,
    params?: GetLoanDefermentsParams,) => [`/deferment/${loanId}`, ...(params ? [params]: [])];

    
export const useGetLoanDeferments = <TData = AsyncReturnType<typeof getLoanDeferments>, TError = unknown>(
 loanId: number,
    params?: GetLoanDefermentsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLoanDeferments>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLoanDefermentsQueryKey(loanId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLoanDeferments>> = () => getLoanDeferments(loanId,params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLoanDeferments>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const addOrUpdateDeferment = (
    addOrUpdateDefermentBody: AddOrUpdateDefermentBody,
 options?: SecondParameter<typeof apiRequest>) => {const formData = new FormData();
if(addOrUpdateDefermentBody.id !== undefined) {
 formData.append('id', addOrUpdateDefermentBody.id.toString())
 }
if(addOrUpdateDefermentBody.loanId !== undefined) {
 formData.append('loanId', addOrUpdateDefermentBody.loanId.toString())
 }
if(addOrUpdateDefermentBody.defermentTypeId !== undefined) {
 formData.append('defermentTypeId', addOrUpdateDefermentBody.defermentTypeId.toString())
 }
if(addOrUpdateDefermentBody.beginDate !== undefined) {
 formData.append('beginDate', addOrUpdateDefermentBody.beginDate)
 }
if(addOrUpdateDefermentBody.endDate !== undefined) {
 formData.append('endDate', addOrUpdateDefermentBody.endDate)
 }
if(addOrUpdateDefermentBody.nextPaymentDate !== undefined) {
 formData.append('nextPaymentDate', addOrUpdateDefermentBody.nextPaymentDate)
 }

      return apiRequest<DefermentsAddOrUpdateDefermentResult>(
      {url: `/deferment/addorupdate`, method: 'post',
       data: formData
    },
      options);
    }
  


    export const useAddOrUpdateDeferment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addOrUpdateDeferment>, TError,{data: AddOrUpdateDefermentBody}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addOrUpdateDeferment>, {data: AddOrUpdateDefermentBody}> = (props) => {
          const {data} = props || {};

          return  addOrUpdateDeferment(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addOrUpdateDeferment>, TError, {data: AddOrUpdateDefermentBody}, TContext>(mutationFn, mutationOptions)
    }
    