import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import LenderAddLenderPage from "./LenderAddLenderPage";
import LenderDetailPage from "./LenderDetailPage";
import LenderListPage from "./LenderListPage";

function LenderRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={LenderListPage} />
      <Route path={`${path}/add`} component={LenderAddLenderPage} />
      <Route path={`${path}/:lenderId`} component={LenderDetailPage} />
    </Switch>
  );
}

export default LenderRoutes;
