import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardHeaderProps,
  CardActions,
  Button,
} from "@mui/material";
import {
  AddOrUpdateDefermentBody,
  LoansGetAllDropDownsItemModel,
  LoansGetLoanDetailDefermentDetailModel,
} from "api";
import { useForm } from "react-hook-form";
import Icon from "shared/Icon";
import { FormErrorAlert } from "shared/forms";
import DateField from "shared/forms/fields/DateField";
import DropDownField from "shared/forms/fields/DropDownField";
import { icons } from "theme";

type DefermentDetailProps = {
  defermentOpt: LoansGetAllDropDownsItemModel[];
  action?: CardHeaderProps["action"];
  deferment?: LoansGetLoanDetailDefermentDetailModel;
  onSave(form: AddOrUpdateDefermentBody): void;
};

function EditDefermentForm({
  action,
  defermentOpt,
  deferment,
  onSave,
}: DefermentDetailProps) {
  const { control, handleSubmit, trigger } = useForm<AddOrUpdateDefermentBody>({
    defaultValues: { ...deferment },
  });

  const onSubmit = handleSubmit(async (form) => {
    // manually trigger validation since
    // this is a nested form
    const res = await trigger(
      ["beginDate", "endDate", "nextPaymentDate", "defermentTypeId"],
      { shouldFocus: true }
    );
    if (res) {
      onSave(form);
    }
  });
  return (
    <Card
      elevation={4}
      id="deferment-form"
      onSubmit={onSubmit}
      component="form"
    >
      <CardHeader title="Deferment Information" action={action} />
      <FormErrorAlert />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <DateField
              control={control}
              name="beginDate"
              label="Begin Date"
              required
              rules={{ required: true }}
            />
          </Grid>
          <Grid item md={6}>
            <DateField
              control={control}
              name="endDate"
              label="End Date"
              required
              rules={{ required: true }}
            />
          </Grid>
          <Grid item md={6}>
            <DateField
              control={control}
              name="nextPaymentDate"
              label="Next Payment Date"
              required
              rules={{ required: true }}
            />
          </Grid>
          <Grid item md={6}>
            <DropDownField
              control={control}
              name="defermentTypeId"
              label="Deferment Type"
              options={defermentOpt}
              getOptionKey={(opt) => opt.id}
              getOptionLabel={(opt) => opt.label}
              required
              rules={{ required: true }}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "right" }}>
        {/* can't use the submit type button or it will trigger the update loan detail onSubmit */}
        <Button onClick={onSubmit}>
          <Icon icon={icons.save} sx={{ pr: 1 }} />
          Save Changes
        </Button>
      </CardActions>
    </Card>
  );
}

export default EditDefermentForm;
