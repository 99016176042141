import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function useLocationState<T>(key: string, defaultState: T) {
  const history = useHistory();
  const { pathname, state } = useLocation<{ [k: string]: T | undefined }>();

  const currentState = state?.[key] ?? defaultState;

  const updateState = useCallback(
    (next: T) => history.push(pathname, { ...state, [key]: next }),
    [history, state, key, pathname]
  );

  return [currentState, updateState] as const;
}
