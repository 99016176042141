import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Stack,
  Button,
  Box,
} from "@mui/material";
import {
  PaymentsPaymentImportReconciliationForm,
  useGetPaymentImport,
  useSetPaymentImportReconciliation,
} from "api";
import { useState, useCallback, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import MoneyField from "shared/forms/fields/MoneyField";
import PercentField from "shared/forms/fields/PercentField";
import DateField from "shared/forms/fields/DateField";
import TextField from "shared/forms/fields/TextField";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

interface History1502TableProps {
  paymentImportId?: number;
  isReconciled?: boolean;
}
// TODO: find a way to avoid refetch, when time permits.
function History1502Table({
  paymentImportId,
  isReconciled,
}: Readonly<History1502TableProps>) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [enableCancel, setEnableCancel] = useState(false);

  const { data: paymentImportDetails, refetch } = useGetPaymentImport(
    {
      paymentImportId: paymentImportId,
    },
    {
      query: {
        keepPreviousData: true,
      },
    }
  );

  const { control, handleSubmit } =
    useForm<PaymentsPaymentImportReconciliationForm>({
      defaultValues: {
        history1502Edits: paymentImportDetails?.processingModel.history1502,
      },
    });

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { mutate } = useSetPaymentImportReconciliation({
    mutation: {
      onSuccess(data) {
        if (data?.errorModel?.errorMessage) {
          enqueueSnackbar(data?.errorModel?.errorMessage, {
            variant: "warning",
          });
        } else {
          enqueueSnackbar("Payment Import Complete", {
            variant: "success",
          });
          history.push("/processing/payments");
          dispatchEvent(
            new CustomEvent("refreshPaymentLenderStatistics", { bubbles: true })
          );
        }
      },
    },
  });

  const onSubmit = handleSubmit(
    (form: PaymentsPaymentImportReconciliationForm) => {
      mutate({
        data: form,
        params: {
          loanId: paymentImportDetails?.processingModel.systemCalculation.id,
          paymentImportId:
            paymentImportDetails?.processingModel.lenderCalculation.id,
        },
      });
    }
  );

  const handleDoubleClick = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    setIsEditMode(true);
    setEnableCancel(true);
  }, []);

  const {
    fields: historyFields,
    replace,
    remove,
  } = useFieldArray({
    control: control,
    name: "history1502Edits",
  });

  const handleCancel = () => {
    remove();
    refetch();

    setEnableCancel(false);
  };

  useEffect(() => {
    if (
      !enableCancel &&
      paymentImportDetails?.processingModel.history1502 !== undefined
    ) {
      replace(paymentImportDetails?.processingModel.history1502);
    }
  }, [
    paymentImportDetails?.processingModel.history1502,
    enableCancel,
    replace,
  ]);

  return (
    <>
      <Paper sx={{ overflowX: "auto" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ background: "#D1D1D1" }}>
                <TableCell>
                  <Typography noWrap>Report Id</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Next Installment Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography>SBA Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Amount Disbursed</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Amount Undisbursed</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Interest Rate</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Guar. Portion Gross Interest</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Guar. Portion Net Interest</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Guar. Portion Principal</Typography>
                </TableCell>
                <TableCell sx={{ paddingRight: 4 }}>
                  <Typography noWrap>Total to FTA</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Interest Paid From Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Interest Paid To Date</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Number Of Days</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Calendar Basis</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Guar. Portion </Typography>
                  <Typography noWrap>Closing Balance</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Remittance Penalty</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Participant Service Fee</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Total Payment</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Principal Payment</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Interest Payment</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>Ending Principal Balance</Typography>
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {historyFields.map((row, index) => (
                <TableRow
                  key={`${row.paymentId}-row`}
                  sx={{ "&:last-of-type td, &:last-of-type th": { border: 0 } }}
                >
                  <TableCell>
                    <TextField
                      key={`${row.id}-reportId`}
                      control={control}
                      name={`history1502Edits.${index}.reportId`}
                      readOnly
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <DateField
                      key={`${row.id}-nextInstallmentDate`}
                      control={control}
                      name={`history1502Edits.${index}.nextInstallmentDate`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <TextField
                      key={`${row.id}-sbaStatusCode`}
                      control={control}
                      name={`history1502Edits.${index}.sbaStatusCode`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-amountDisbursed`}
                      control={control}
                      name={`history1502Edits.${index}.amountDisbursed`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-amountUndisbursed`}
                      control={control}
                      name={`history1502Edits.${index}.amountUndisbursed`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <PercentField
                      key={`${row.id}-interestRate`}
                      control={control}
                      name={`history1502Edits.${index}.interestRate`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-grossInterest`}
                      control={control}
                      name={`history1502Edits.${index}.grossInterest`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-netInterest`}
                      control={control}
                      name={`history1502Edits.${index}.netInterest`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-guarantyPortionPrincipal`}
                      control={control}
                      name={`history1502Edits.${index}.guarantyPortionPrincipal`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-totalToFTA`}
                      control={control}
                      name={`history1502Edits.${index}.totalToFTA`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <DateField
                      key={`${row.id}-interestPaidFromDate`}
                      control={control}
                      name={`history1502Edits.${index}.interestPaidFromDate`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <DateField
                      key={`${row.id}-interestPaidToDate`}
                      control={control}
                      name={`history1502Edits.${index}.interestPaidToDate`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <TextField
                      key={`${row.id}-numberOfDays`}
                      control={control}
                      name={`history1502Edits.${index}.numberOfDays`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <TextField
                      key={`${row.id}-calendarBasis`}
                      control={control}
                      name={`history1502Edits.${index}.calendarBasis`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-guarantyPortionClosingBalance`}
                      control={control}
                      name={`history1502Edits.${index}.guarantyPortionClosingBalance`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>

                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-remittancePenalty`}
                      control={control}
                      name={`history1502Edits.${index}.remittancePenalty`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-serviceFee`}
                      control={control}
                      name={`history1502Edits.${index}.serviceFee`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>

                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-totalPayment`}
                      control={control}
                      name={`history1502Edits.${index}.totalPayment`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>

                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-principalPayment`}
                      control={control}
                      name={`history1502Edits.${index}.principalPayment`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-interestPayment`}
                      control={control}
                      name={`history1502Edits.${index}.interestPayment`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                  <TableCell onDoubleClick={handleDoubleClick}>
                    <MoneyField
                      key={`${row.id}-endingPrincipal`}
                      control={control}
                      name={`history1502Edits.${index}.endingPrincipal`}
                      readOnly={!isEditMode}
                      variant="standard"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!isReconciled &&
      <Stack paddingTop={1} direction="row-reverse">
        <Box paddingLeft={2}>
          <Button disabled={!enableCancel} onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
        <Box> <Button onClick={onSubmit}>Save</Button></Box>
      </Stack>
}
    </>
  );
}
export default History1502Table;
