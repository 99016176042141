import {
  CognitoUser,
  IAuthenticationCallback,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { Dispatch } from "react";
import { userPool } from "./context";
import {
  FlowAction,
  FlowState,
  useFlow,
  DispatchRef,
  createUser,
} from "./flow";

type AuthFlowStep = SignInStep | NewPasswordRequiredStep | AuthenticatedStep;
type AuthFlowAction = FlowAction<AuthFlowStep>;
export type AuthFlow = FlowState & AuthFlowStep;

export function useAuthFlow(): AuthFlow {
  return useFlow<AuthFlowStep>((dispatch, user) => signInStep(dispatch, user));
}

function authCallbacks(
  dispatch: Dispatch<AuthFlowAction>,
  user: CognitoUser
): IAuthenticationCallback {
  return {
    onSuccess(session) {
      dispatch({ type: "session", user, session });
      dispatch({
        type: "next",
        step: authenticatedStep(dispatch, user),
      });
    },
    onFailure(err) {
      dispatch({ type: "error", err });
    },
    newPasswordRequired() {
      dispatch({
        type: "next",
        step: newPasswordRequiredStep(dispatch, user),
      });
    },
  };
}

interface SignInStep {
  step: "SignIn";
  username: string | undefined;
  onSignIn: (username: string, password: string) => void;
}

function signInStep(
  dispatchRef: DispatchRef<AuthFlowAction>,
  user = userPool.getCurrentUser()
): SignInStep {
  return {
    step: "SignIn",
    username: user?.getUsername(),
    onSignIn(username: string, password: string) {
      const newUser = createUser(username);

      const dispatch = dispatchRef();
      dispatch({ type: "user", user: newUser });
      dispatch({ type: "pending" });

      const credentials = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      newUser.authenticateUser(credentials, authCallbacks(dispatch, newUser));
    },
  };
}

interface AuthenticatedStep {
  step: "Authenticated";
  username: string;
}

function authenticatedStep(
  _: Dispatch<AuthFlowAction>,
  user: CognitoUser
): AuthenticatedStep {
  return {
    step: "Authenticated",
    username: user.getUsername(),
  };
}

interface NewPasswordRequiredStep {
  step: "NewPasswordRequired";
  onNewPassword: (password: string) => void;
}

function newPasswordRequiredStep(
  dispatch: Dispatch<AuthFlowAction>,
  user: CognitoUser
): NewPasswordRequiredStep {
  return {
    step: "NewPasswordRequired",
    onNewPassword(password: string) {
      dispatch({ type: "pending" });
      user.completeNewPasswordChallenge(
        password,
        {},
        authCallbacks(dispatch, user)
      );
    },
  };
}

// ... there are some other possible steps for auth flow
