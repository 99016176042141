/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  LoansGetAllLoansResult,
  GetAllLoansParams,
  LoansAddLoanResult,
  LoansAddLoanForm,
  LoansGetLoanDetailResult,
  LoansUpdateLoanDetailResult,
  LoansUpdateLoanDetailForm,
  LoansGetAuditLogResult,
  GetAuditLogParams,
  LoansGetAllLoanTypesResult,
  GetAllLoanTypesParams,
  LoansGetAllDropDownsResult,
  GetLoanDropdownsParams,
  LoansGetLoanInterestRateHistoryResult,
  LoansDeleteLoanResult
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllLoans = (
    params?: GetAllLoansParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetAllLoansResult>(
      {url: `/loan`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllLoansQueryKey = (params?: GetAllLoansParams,) => [`/loan`, ...(params ? [params]: [])];

    
export const useGetAllLoans = <TData = AsyncReturnType<typeof getAllLoans>, TError = unknown>(
 params?: GetAllLoansParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllLoans>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLoansQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllLoans>> = () => getAllLoans(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllLoans>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addLoan = (
    loansAddLoanForm: LoansAddLoanForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansAddLoanResult>(
      {url: `/loan`, method: 'post',
      data: loansAddLoanForm
    },
      options);
    }
  


    export const useAddLoan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addLoan>, TError,{data: LoansAddLoanForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addLoan>, {data: LoansAddLoanForm}> = (props) => {
          const {data} = props || {};

          return  addLoan(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addLoan>, TError, {data: LoansAddLoanForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getLoanDetail = (
    loanId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetLoanDetailResult>(
      {url: `/loan/${loanId}`, method: 'get'
    },
      options);
    }
  

export const getGetLoanDetailQueryKey = (loanId: number,) => [`/loan/${loanId}`];

    
export const useGetLoanDetail = <TData = AsyncReturnType<typeof getLoanDetail>, TError = unknown>(
 loanId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLoanDetail>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLoanDetailQueryKey(loanId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLoanDetail>> = () => getLoanDetail(loanId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLoanDetail>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const updateLoanDetail = (
    loanId: number,
    loansUpdateLoanDetailForm: LoansUpdateLoanDetailForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansUpdateLoanDetailResult>(
      {url: `/loan/${loanId}`, method: 'post',
      data: loansUpdateLoanDetailForm
    },
      options);
    }
  


    export const useUpdateLoanDetail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateLoanDetail>, TError,{loanId: number;data: LoansUpdateLoanDetailForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateLoanDetail>, {loanId: number;data: LoansUpdateLoanDetailForm}> = (props) => {
          const {loanId,data} = props || {};

          return  updateLoanDetail(loanId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateLoanDetail>, TError, {loanId: number;data: LoansUpdateLoanDetailForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getAuditLog = (
    loanId: number,
    params?: GetAuditLogParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetAuditLogResult>(
      {url: `/loan/${loanId}/audit`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAuditLogQueryKey = (loanId: number,
    params?: GetAuditLogParams,) => [`/loan/${loanId}/audit`, ...(params ? [params]: [])];

    
export const useGetAuditLog = <TData = AsyncReturnType<typeof getAuditLog>, TError = unknown>(
 loanId: number,
    params?: GetAuditLogParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAuditLog>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAuditLogQueryKey(loanId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAuditLog>> = () => getAuditLog(loanId,params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAuditLog>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getAllLoanTypes = (
    params?: GetAllLoanTypesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetAllLoanTypesResult>(
      {url: `/loan/loantypes`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllLoanTypesQueryKey = (params?: GetAllLoanTypesParams,) => [`/loan/loantypes`, ...(params ? [params]: [])];

    
export const useGetAllLoanTypes = <TData = AsyncReturnType<typeof getAllLoanTypes>, TError = unknown>(
 params?: GetAllLoanTypesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllLoanTypes>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLoanTypesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllLoanTypes>> = () => getAllLoanTypes(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllLoanTypes>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getLoanDropdowns = (
    params?: GetLoanDropdownsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetAllDropDownsResult>(
      {url: `/loan/loandropdowns`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetLoanDropdownsQueryKey = (params?: GetLoanDropdownsParams,) => [`/loan/loandropdowns`, ...(params ? [params]: [])];

    
export const useGetLoanDropdowns = <TData = AsyncReturnType<typeof getLoanDropdowns>, TError = unknown>(
 params?: GetLoanDropdownsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLoanDropdowns>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLoanDropdownsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLoanDropdowns>> = () => getLoanDropdowns(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLoanDropdowns>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getLoanInterestRateHistory = (
    loanId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansGetLoanInterestRateHistoryResult>(
      {url: `/loan/${loanId}/interestratehistory`, method: 'get'
    },
      options);
    }
  

export const getGetLoanInterestRateHistoryQueryKey = (loanId: number,) => [`/loan/${loanId}/interestratehistory`];

    
export const useGetLoanInterestRateHistory = <TData = AsyncReturnType<typeof getLoanInterestRateHistory>, TError = unknown>(
 loanId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLoanInterestRateHistory>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLoanInterestRateHistoryQueryKey(loanId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLoanInterestRateHistory>> = () => getLoanInterestRateHistory(loanId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLoanInterestRateHistory>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const deleteLoan = (
    loanId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LoansDeleteLoanResult>(
      {url: `/loan/delete/${loanId}`, method: 'post'
    },
      options);
    }
  


    export const useDeleteLoan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteLoan>, TError,{loanId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteLoan>, {loanId: number}> = (props) => {
          const {loanId} = props || {};

          return  deleteLoan(loanId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteLoan>, TError, {loanId: number}, TContext>(mutationFn, mutationOptions)
    }
    