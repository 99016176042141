import { Typography, Breadcrumbs as MUIBreadcrumbs, Link } from "@mui/material";
import React from "react";

import { useHistory, useLocation, withRouter } from "react-router-dom";

function Breadcrumbs() {
  const { pathname } = useLocation();
  const history = useHistory();

  const pathNames = pathname.split("/").filter((x) => x);

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        "& .MuiBreadcrumbs-li, .MuiTypography-root": { fontSize: "1.5rem" },
        "& .MuiLink-root": { cursor: "pointer" },
      }}
    >
      {pathNames.length > 0 ? (
        <Link onClick={() => history.push("/")}>Home</Link>
      ) : (
        <Typography> Home </Typography>
      )}
      {pathNames.map((name: any, index: number) => {
        const routeTo = `/${pathNames.slice(0, index + 1).join("/")}`;
        const isLast = index === pathNames.length - 1;
        return isLast ? (
          <Typography key={name}>{name}</Typography>
        ) : (
          <Link key={name} onClick={() => history.push(routeTo)}>
            {name}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
}

export default withRouter(Breadcrumbs);
