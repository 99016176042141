import React from "react";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";
import deepmerge from "deepmerge";

function InlineFormLayout({ children }: React.PropsWithChildren<{}>) {
  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme(
          deepmerge(theme, {
            components: {
              MuiTextField: {
                styleOverrides: {
                  root: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flexWrap: "wrap",
                    height: "100%", // vertically aligns controls in each grid row

                    "& label": {
                      display: "flex",
                      alignItems: "center",
                      flex: 1,
                      whiteSpace: "normal",
                      marginBottom: "0",
                      paddingRight: ".5rem",
                      justifyContent: "flex-end",
                      textAlign: "right",
                    },

                    "& .MuiOutlinedInput-root": {
                      maxWidth: "65%",
                    },

                    "& .MuiFormHelperText-root": {
                      width: "100%",
                    },
                  },
                },
              },
            },
          })
        )
      }
    >
      {children}
    </ThemeProvider>
  );
}

export default React.memo(InlineFormLayout);
