import isDate from "date-fns/isDate";
import parseISO from "date-fns/parseISO";

var formatter = new Intl.DateTimeFormat("en-US", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  hour12: true,
  minute: "2-digit",
  second: "2-digit",
});

export default function formatDateTime(
  value: Date | string | null | undefined
) {
  if (typeof value === "string") {
    value = parseISO(value);
  }

  if (isDate(value)) {
    return formatter.format(value as Date);
  }

  return "-";
}
