const activityTypeRadioValues = [
  { value: 1, label: "Note" },
  { value: 2, label: "Document" },

]
export default function formatActivityType(value: number | string | null | undefined) {


  const t = activityTypeRadioValues.find(x => x.value === value);
  return t?.label!

}
