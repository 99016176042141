import React from "react";
import { Link } from "react-router-dom";
import { useAuthUser } from "account/auth/context";
import { icons } from "theme";
import {
  Avatar,
  Stack,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import Icon from "../Icon";
import RouterBreadcrumbs from "shared/forms/fields/BreadCrumbs";
import { useGetAllActivities } from "api";

function PageHeader() {
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElBell, setAnchorElBell] = React.useState<null | HTMLElement>(
    null
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleBellClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBell(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElMenu(null);
  };

  const handleBellClose = () => {
    setAnchorElBell(null);
  };

  const user = useAuthUser();

  const { data: activityData } = useGetAllActivities({
    pageSize: 3,
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: "5rem" }}
    >
      <Typography
        sx={{
          fontSize: "1.5rem",
          textTransform: "uppercase",
          fontWeight: "bold",
          pl: 2,
        }}
      >
        <RouterBreadcrumbs />
      </Typography>

      <Stack direction="row" spacing={2}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleMenuClick}
          sx={{
            "& .MuiAvatar-root": {
              color: "primary.main",
            },
            "&:hover .MuiAvatar-root": {
              color: "secondary.main",
            },
          }}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              mr: 1,
              backgroundColor: "gray.light",
            }}
          >
            <Icon fixedWidth icon={icons.user} sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Box sx={{ mr: 1 }}>My Account</Box>
          <Icon fixedWidth icon={icons.angleDown} />
        </Button>
        <Menu
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleMenuClose}
        >
          <MenuItem divider>{user.getUsername()}</MenuItem>
          <MenuItem onClick={handleMenuClose} component={Link} to="/account/profile">Profile</MenuItem>
          <MenuItem component={Link} to="/signout">
            Logout
          </MenuItem>
        </Menu>
        <Button onClick={handleBellClick}>
          <Icon fixedWidth icon={icons.alerts} />
        </Button>
        <Menu
          anchorEl={anchorElBell}
          keepMounted
          open={Boolean(anchorElBell)}
          onClose={handleBellClose}
        >
          <MenuItem divider>{"Recent Activities"}</MenuItem>

          {activityData?.items.map((activity) => (
            <Link to={`/activity/${activity.id}`}>
              <MenuItem onClick={handleBellClose}>
                {activity.activityTitle}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Stack>
    </Stack>
  );
}

export default PageHeader;
