import React, { useCallback, useEffect, useState } from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import withFormStatus from "../../shared/forms/withFormStatus";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import TextField from "../../shared/forms/fields/TextField";
import DateField from "../../shared/forms/fields/DateField";
import SubmitButton from "../../shared/forms/SubmitButton";
import { useDropzone } from "react-dropzone";
import { AddActivityBody, useGetAllLenders, useGetAllLoans } from "api";
import RadioGroupField from "../../shared/forms/fields/RadioGroupField";
import DropDownField from "../../shared/forms/fields/DropDownField";

interface Props {
  onSave(form: AddActivityBody): void;
  loanId?: number;
  lenderId?: number;
  lenderLoanNumber?: number | string;
}

const makePrivateRadioValues = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const isTaskRadioValues = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];
const assignToRadioValues = [
  { value: 1, label: "IFS Admin" },
  { value: 2, label: "Lender" },
];
const activityTypeRadioValues = [
  { value: 1, label: "Note" },
  { value: 2, label: "Document" },
];
const isGeneralActivityRadioValues = [
  { value: true, label: "General" },
  { value: false, label: "Loan" },
];

function AddActivity({ onSave, loanId, lenderId, lenderLoanNumber }: Props) {
  const [isDocument, setIsDocument] = useState(false);
  const { watch, control, handleSubmit, setValue } = useForm<AddActivityBody>({
    defaultValues: {
      activityType: 1,
      isTask: false,
      makePrivate: false,
      isGeneralActivity: lenderLoanNumber ? false : true,

      activityFor: lenderLoanNumber?.toString(),
    },
  });

  const isTask = watch("isTask");
  const isGeneralActivity = watch("isGeneralActivity");

  useEffect(() => {
    const subscription = watch((value) =>
      value.activityType === 2 ? setIsDocument(true) : setIsDocument(false)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  const { data: loanList } = useGetAllLoans(
    { pageSize: 2000 },

    { query: { keepPreviousData: true } }
  );

  const { data: lenderList } = useGetAllLenders(
    { pageSize: 2000 },
    { query: { keepPreviousData: true } }
  );

  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) =>
        setUploadedFiles([...uploadedFiles, file])
      );
    },
    [uploadedFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  const files = uploadedFiles.map((file) => (
    <Box key={file.name} sx={{ py: 1 }}>
      {file.name}
    </Box>
  ));

  const onSubmit = handleSubmit((form) => {
    onSave({
      ...form,
      loanId,
      lenderId: form.lenderId ? form.lenderId : lenderId,
      file: uploadedFiles,
    });
  });

  useEffect(() => {
    setValue("assignTo", 1);
  }, [setValue, isTask]);

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <RadioGroupField
            control={control}
            labelName="Activity Type"
            name="activityType"
            options={activityTypeRadioValues}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RadioGroupField
            control={control}
            labelName="is This a Task?"
            name="isTask"
            options={isTaskRadioValues}
          />
        </Grid>
        {isTask && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <RadioGroupField
                control={control}
                labelName="Assign To"
                name="assignTo"
                options={assignToRadioValues}
                required={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateField
                control={control}
                name="dueDate"
                label="Activity Due Date"
                required={true}
              />
            </Grid>
          </React.Fragment>
        )}
        {!lenderId && (
          <React.Fragment>
            <Grid item xs={12}>
              <DropDownField
                options={lenderList?.items || []}
                getOptionKey={(opt) => opt.id}
                getOptionLabel={(opt) => opt.name}
                control={control}
                name="lenderId"
                label="Lender"
                required={true}
              />
            </Grid>
          </React.Fragment>
        )}
        {lenderId && (
          <React.Fragment>
            <Grid item xs={12} md={6}>
              <RadioGroupField
                control={control}
                labelName="Activity For"
                name="isGeneralActivity"
                options={isGeneralActivityRadioValues}
              />
            </Grid>
            {isGeneralActivity === false && lenderLoanNumber !== undefined && (
              <React.Fragment>
                <Grid item xs={12}>
                  <DropDownField
                    options={loanList?.items || []}
                    getOptionKey={(opt) => opt.lenderLoanNumber}
                    getOptionLabel={(opt) => opt.lenderLoanNumber}
                    control={control}
                    name="activityFor"
                    label="Lender Loan Number"
                    required={true}
                  />
                </Grid>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <Grid item xs={12}>
          <TextField
            control={control}
            name="activityTitle"
            label="Activity Title"
            required={true}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            control={control}
            name="comments"
            label="Comments"
            multiline={true}
            rows={5}
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupField
            control={control}
            labelName="Make Private"
            name="makePrivate"
            options={makePrivateRadioValues}
          />
        </Grid>
        {isDocument && (
          <Grid item xs={12}>
            <React.Fragment>
              <Box
                sx={{
                  p: 2,
                  backgroundColor: "#fff",
                  border: "2px dashed",
                  borderColor: "secondary.main",
                }}
              >
                <Controller
                  control={control}
                  name="file"
                  render={({ field: { onChange, onBlur, ref } }) => (
                    <Box
                      sx={{
                        p: 2,
                        backgroundColor: "gray.light",
                        "&:hover": { backgroundColor: "grid.rowHover" },
                      }}
                      {...getRootProps()}
                    >
                      <input
                        ref={ref}
                        onChange={onChange}
                        onBlur={onBlur}
                        {...getInputProps()}
                      />
                      <Typography
                        textAlign="center"
                        variant="body1"
                        fontWeight="bold"
                      >
                        Drag 'n' drop file here, or click to select file
                      </Typography>
                    </Box>
                  )}
                />
              </Box>
              <Box sx={{ pt: 1 }}>{files}</Box>
            </React.Fragment>
          </Grid>
        )}
      </Grid>
      <Stack pt={2} direction="row" justifyContent="flex-end">
        <SubmitButton variant="save" />
      </Stack>
    </Box>
  );
}
export default withFormStatus(AddActivity);
