import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  Stack,
  Modal,
  Link,
} from "@mui/material";
import {
  DisbursementsDisbursementImportReconciliationForm,
  useGetDisbursementImport,
  useGetLoanDetail,
  useSetDisbursementImportReconciliation,
  useAddActivity
} from "api";
import { useForm } from "react-hook-form";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import DateField from "shared/forms/fields/DateField";
import { icons } from "theme";
import Icon from "shared/Icon";
import AddActivity from "activity/forms/AddActivityForm";
import MoneyField from "shared/forms/fields/MoneyField";

function DisbursementProcessingDetailsPage() {
  const { disbursementImportId: disbursementImportIdParam } = useParams<{
    disbursementImportId: string;
  }>();

  const disbursementImportId = Number(disbursementImportIdParam);
  const { data: disbursementImportDetails } = useGetDisbursementImport({
    disbursementImportId: disbursementImportId,
  });

  const reconciled =
    disbursementImportDetails?.disbursementImportModel?.reconciliationDate;

  const { control, handleSubmit, setValue } =
    useForm<DisbursementsDisbursementImportReconciliationForm>({
      defaultValues: {
        effectiveDate:
          disbursementImportDetails?.disbursementImportModel.effectiveDate!,
        endingPrincipalBalance:
          disbursementImportDetails?.disbursementImportModel
            .endingPrincipalBalance!,
        amountDisbursed:
          disbursementImportDetails?.disbursementImportModel.amountDisbursed!,
      },
    });
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const { mutate } = useSetDisbursementImportReconciliation({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Disbursement Import Complete", {
          variant: "success",
        });
        history.push("/processing/disbursements");
        dispatchEvent(
          new CustomEvent("refreshDisbursementLenderStatistics", { bubbles: true })
        );
      },
    },
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { mutate: activityMutate, ...activityStatus } = useAddActivity({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Activity Added", {
          variant: "success",
        });
        setOpen(false);
      },
    },
  });

  const { data: loan } = useGetLoanDetail(
    disbursementImportDetails?.disbursementImportModel.loanId!
  );

  useEffect(() => {
    setValue(
      "amountDisbursed",
      disbursementImportDetails?.disbursementImportModel.amountDisbursed!
    );
    setValue(
      "endingPrincipalBalance",
      disbursementImportDetails?.disbursementImportModel.endingPrincipalBalance!
    );
    setValue(
      "effectiveDate",
      disbursementImportDetails?.disbursementImportModel.effectiveDate!
    );
  }, [disbursementImportDetails, setValue]);
  const onSubmit = handleSubmit(
    (form: DisbursementsDisbursementImportReconciliationForm) =>
      mutate({
        data: form,
        params: {
          loanId: disbursementImportDetails?.disbursementImportModel.id,
          disbursementImportId:
            disbursementImportDetails?.disbursementImportModel.id,
        },
      })
  );
  return (
    <Card
      elevation={4}
      sx={{
        mb: 4,
        width: "100%",
      }}
    >
      <CardHeader
        title={
          <Link
            component={RouterLink}
            to={`/loans/${disbursementImportDetails?.disbursementImportModel.loanId}`}
          >
            {`Reconciliation: ${loan?.loan.borrowerName} - ${loan?.loan.sbagpNumber}`}
          </Link>
        }
        subheader={
          <div>
            <Button onClick={() => setOpen(true)}>Add Activity</Button>
            &nbsp;
            {reconciled === null && (
              <>
                &nbsp;<Button onClick={onSubmit}>Save</Button>
              </>
            )}
          </div>
        }
        subheaderTypographyProps={{
          align: "right",
          mt: 4,
          ml: 1,

          width: "100%",
        }}
      />

      <CardContent
        sx={{
          ".processing-grid": {
            position: "relative",

            "& .MuiBox-root": {
              flex: 1,
              p: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              borderRight: "1px solid",
              borderColor: "gray.dark",
            },
            "& .copy-link": {
              flex: 1,
              float: "right",
              border: "none",
              padding: "none",
              cursor: "pointer",
              justifyContent: "flex-end",
            },
            "& .processing-main-header": {
              fontWeight: "700",
              color: "#ffffff",
              textTransform: "uppercase",
              position: "sticky",
              zIndex: "2",
              top: "0",

              "& div:nth-child(2)": {
                backgroundColor: "primary.main",
              },
              "& div:nth-child(3)": {
                backgroundColor: "secondary.main",
              },
              "& div:nth-child(4)": {
                backgroundColor: "error.dark",
              },
            },
            "& .processing-section-header": {
              fontWeight: "700",
              backgroundColor: "gray.main",
              textTransform: "uppercase",
            },
            "& .processing-alt-row": {
              backgroundColor: "grid.altRow",
            },
          },
        }}
      >
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={4}
            sx={{
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <CardHeader
              title="Add Activity"
              action={
                <Button sx={{ ml: 2 }} onClick={handleClose}>
                  <Icon fixedWidth icon={icons.close} />
                </Button>
              }
            />
            <CardContent>
              <AddActivity
                onSave={(form) => {
                  activityMutate({ data: form });
                }}
                status={activityStatus}
                lenderId={
                  disbursementImportDetails?.disbursementImportModel.lenderId
                }
                lenderLoanNumber={loan?.loan?.lenderLoanNumber}
              />
            </CardContent>
          </Card>
        </Modal>
        <Box className="processing-grid">
          <Stack className="processing-main-header" direction="row">
            <Box>Lender Calculation</Box>
          </Stack>

          <Stack className="processing-section-header" direction="row">
            <Box>Disbursement</Box>
            <Box></Box>
          </Stack>

          <Stack className="processing-alt-row" direction="row">
            <Box>Amount Disbursed</Box>
            <Box>
              <MoneyField
                name="amountDisbursed"
                control={control}
                readOnly={true}
              />
            </Box>
          </Stack>

          <Stack className="processing-row" direction="row">
            <Box>Ending Principal Balance</Box>
            <Box>
              <MoneyField
                name="endingPrincipalBalance"
                control={control}
                readOnly={true}
              />
            </Box>
          </Stack>
          <Stack className="processing-alt-row" direction="row">
            <Box>Effective Date</Box>
            <Box>
              <DateField
                name="effectiveDate"
                control={control}
                readOnly={true}
              ></DateField>
            </Box>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
}
export default DisbursementProcessingDetailsPage;
