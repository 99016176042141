import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import AdminOptions from "./AdminOptions";
import AdminRates from "./rates/AdminRates";
import AdminRatesDetailPage from "./rates/AdminRatesDetailPage";
import DueDateListPage from "./dueDates/DueDatesListPage";
import DueDateDetailsPage from "./dueDates/DueDateDetailsPage";
import PastDueReportsListPage from "./reports/Reports/PastDueReportsListPage";
import ReportsListPage from "admin/reports/ReportsListPage";
import NewLoanReports from "./reports/Reports/NewLoansReport";

function AdminRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={AdminOptions} />
      <Route path={`${path}/rates/:rateId`} component={AdminRatesDetailPage} />
      <Route path={`${path}/rates`} component={AdminRates} />
      <Route
        path={`${path}/duedates/:reportDateId`}
        component={DueDateDetailsPage}
      />
      <Route
        path={`${path}/reports/pastDue`}
        component={PastDueReportsListPage}
      />
      <Route path={`${path}/reports/newLoans`} component={NewLoanReports} />
      <Route path={`${path}/duedates`} component={DueDateListPage} />
      <Route path={`${path}/reports`} component={ReportsListPage} />
    </Switch>
  );
}

export default AdminRoutes;
