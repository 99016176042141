import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAddUser, useGetUserTypes, UsersAddUserResult } from "api";
import AddUserForm from "./forms/AddUserForm";

function UserAddUserPage() {
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const { mutate, ...status } = useAddUser({
    mutation: {
      onSuccess(data: UsersAddUserResult) {
        history.push(`/users/${data.user.id}`);
        enqueueSnackbar("User Added", {
          variant: "success",
        });
      },
    },
  });

  const dropDownDefault = {
    userType: [],
  };
  const { data: userTypes } = useGetUserTypes(undefined, {
    query: { placeholderData: dropDownDefault },
  });

  return (
    <Card elevation={4} sx={{ overflow: "unset", width: "100%" }}>
      <CardHeader title="Add User" />

      <CardContent>
        <AddUserForm
          userTypeResults={userTypes!}
          onSave={(form) => mutate({ data: form })}
          status={status}
        />
      </CardContent>
    </Card>
  );
}
export default UserAddUserPage;
