import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { Box, Link } from "@mui/material";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { useDeleteLender, useGetAllLenders } from "api";
import useDataGrid from "shared/grid/useDataGrid";
import formatCurrency from "shared/formatters/currency";
import formatNumber from "shared/formatters/number";
import formatGridCell from "shared/formatters/grid";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import useLenderContext from "shared/useLenderContext";
import { useState } from "react";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";
import { useSnackbar } from "notistack";
import { DeleteForever } from "@mui/icons-material";

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd" | "onSearch">) {
  const history = useHistory();
  const { data } = useGetAllLenders({ pageSize: 1 });
  return (
    <DataGridToolbar
      header={"Lender List"}
      addButtonLabel={data?.permitModel.create ? "Add Lender" : null}
      onAdd={() => history.push("/lenders/add")}
      {...props}
    />
  );
}

interface Filters {
  searchQuery?: string;
}

function LenderListPage() {
  const columns: GridColumns = [
    {
      field: "name",
      headerName: "Lender",
      flex: 2,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/lenders/${row.id}`}>
          {row?.name}
        </Link>
      ),
    },
    {
      field: "locationId",
      headerName: "Location ID",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: ({ row }) => row?.address?.line1 ?? "",
    },
    {
      field: "firs",
      headerName: "FIRS",
      flex: 1,
    },
    {
      field: "totalLoanCount",
      headerName: "Total Loans",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatNumber),
    },
    {
      field: "totalLoanAmount",
      headerName: "Total Amount",
      flex: 1,
      headerAlign: "right",
      align: "right",
      valueFormatter: formatGridCell(formatCurrency),
    },
    {
      field: "delete",
      headerName: "Delete Lender",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => (
        <Link
          onClick={() => {
            setDeleteId(row.id);
            setShowDeleteDialog(true);
          }}
        >
          <DeleteForever></DeleteForever>
        </Link>
      ),
    },
  ];
  const { grid, query, updateSearchQuery } = useDataGrid<Filters>(
    "lenders",
    columns
  );

  const { enqueueSnackbar } = useSnackbar();
  const { lenderId } = useLenderContext();
  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const { data, isFetching, refetch } = useGetAllLenders(
    {
      lenderId,
      ...query,
    },
    { query: { keepPreviousData: true } }
  );

  const { mutate: deleteLenderMutate } = useDeleteLender({
    mutation: {
      onSuccess() {
        enqueueSnackbar("Lender Deleted", {
          variant: "success",
        });
        refetch();
      },
    },
  });

  function onSearchEntered(searchString: string) {
    updateSearchQuery({ searchQuery: searchString });
  }

  return (
    <Card elevation={4} sx={{ flex: 1 }}>
      <CardContent sx={{ flex: 1 }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <DataGridPro
                {...grid}
                rows={data?.items ?? []}
                rowCount={data?.total}
                loading={isFetching}
                components={{
                  Toolbar: Toolbar,
                }}
                componentsProps={{
                  toolbar: {
                    onSearch: (searchString: string) => {
                      onSearchEntered(searchString);
                    },
                    initialSearch: query.searchQuery,
                  },
                }}
                autoHeight
              />
            </Box>
          </Box>
        </Box>
      </CardContent>
      <AcceptDialogWarning
        alertMessage='Are you sure you want to delete the lender?'
        initialWarningDialog={showDeleteDialog}
        showDialog={(show) => {
          setShowDeleteDialog(show);
        }}
        acceptDelete={(accept) => {
          if (accept) {
            deleteLenderMutate({ lenderId: deleteId });
            setDeleteId(0);
          }
        }}
      />
    </Card>
  );
}

export default LenderListPage;
