/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  DisbursementsGetDisbursementsResult,
  GetDisbursementsParams,
  DisbursementsAddDisbursementResult,
  DisbursementsAddDisbursementForm,
  DisbursementsImportDisbursementResult,
  ImportDisbursementBody,
  ImportDisbursementParams,
  DisbursementsDisbursementImportReconciliationResult,
  DisbursementsDisbursementImportReconciliationForm,
  SetDisbursementImportReconciliationParams,
  DisbursementsGetDisbursementImportResult,
  GetDisbursementImportParams,
  DisbursementsGetDisbursementImportResultResult,
  GetDisbursementImportResultParams,
  DisbursementsGetDisbursementImportsResult,
  GetDisbursementImportsParams,
  DisbursementsGetMissingImportsResult,
  GetMissingImportsParams,
  DisbursementsDeleteDisbursementImportResult,
  DisbursementsDeleteDisbursementImportsByReportIdResult
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDisbursements = (
    loanId: number,
    params?: GetDisbursementsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsGetDisbursementsResult>(
      {url: `/disbursement/${loanId}`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDisbursementsQueryKey = (loanId: number,
    params?: GetDisbursementsParams,) => [`/disbursement/${loanId}`, ...(params ? [params]: [])];

    
export const useGetDisbursements = <TData = AsyncReturnType<typeof getDisbursements>, TError = unknown>(
 loanId: number,
    params?: GetDisbursementsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDisbursements>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDisbursementsQueryKey(loanId,params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDisbursements>> = () => getDisbursements(loanId,params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDisbursements>, TError, TData>(queryKey, queryFn, {enabled: !!(loanId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const addDisbursement = (
    loanId: number,
    disbursementsAddDisbursementForm: DisbursementsAddDisbursementForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsAddDisbursementResult>(
      {url: `/disbursement/${loanId}`, method: 'post',
      data: disbursementsAddDisbursementForm
    },
      options);
    }
  


    export const useAddDisbursement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addDisbursement>, TError,{loanId: number;data: DisbursementsAddDisbursementForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addDisbursement>, {loanId: number;data: DisbursementsAddDisbursementForm}> = (props) => {
          const {loanId,data} = props || {};

          return  addDisbursement(loanId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addDisbursement>, TError, {loanId: number;data: DisbursementsAddDisbursementForm}, TContext>(mutationFn, mutationOptions)
    }
    export const importDisbursement = (
    importDisbursementBody: ImportDisbursementBody,
    params?: ImportDisbursementParams,
 options?: SecondParameter<typeof apiRequest>) => {const formData = new FormData();
if(importDisbursementBody.file !== undefined) {
 formData.append('file', importDisbursementBody.file)
 }

      return apiRequest<DisbursementsImportDisbursementResult>(
      {url: `/disbursement/importdisbursement`, method: 'post',
       data: formData,
        params,
    },
      options);
    }
  


    export const useImportDisbursement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof importDisbursement>, TError,{data: ImportDisbursementBody;params?: ImportDisbursementParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof importDisbursement>, {data: ImportDisbursementBody;params?: ImportDisbursementParams}> = (props) => {
          const {data,params} = props || {};

          return  importDisbursement(data,params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof importDisbursement>, TError, {data: ImportDisbursementBody;params?: ImportDisbursementParams}, TContext>(mutationFn, mutationOptions)
    }
    export const setDisbursementImportReconciliation = (
    disbursementsDisbursementImportReconciliationForm: DisbursementsDisbursementImportReconciliationForm,
    params?: SetDisbursementImportReconciliationParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsDisbursementImportReconciliationResult>(
      {url: `/disbursement/disbursementimportreconciliation`, method: 'post',
      data: disbursementsDisbursementImportReconciliationForm,
        params,
    },
      options);
    }
  


    export const useSetDisbursementImportReconciliation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof setDisbursementImportReconciliation>, TError,{data: DisbursementsDisbursementImportReconciliationForm;params?: SetDisbursementImportReconciliationParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof setDisbursementImportReconciliation>, {data: DisbursementsDisbursementImportReconciliationForm;params?: SetDisbursementImportReconciliationParams}> = (props) => {
          const {data,params} = props || {};

          return  setDisbursementImportReconciliation(data,params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof setDisbursementImportReconciliation>, TError, {data: DisbursementsDisbursementImportReconciliationForm;params?: SetDisbursementImportReconciliationParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getDisbursementImport = (
    params?: GetDisbursementImportParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsGetDisbursementImportResult>(
      {url: `/disbursement/getdisbursementimport`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDisbursementImportQueryKey = (params?: GetDisbursementImportParams,) => [`/disbursement/getdisbursementimport`, ...(params ? [params]: [])];

    
export const useGetDisbursementImport = <TData = AsyncReturnType<typeof getDisbursementImport>, TError = unknown>(
 params?: GetDisbursementImportParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDisbursementImport>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDisbursementImportQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDisbursementImport>> = () => getDisbursementImport(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDisbursementImport>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getDisbursementImportResult = (
    params?: GetDisbursementImportResultParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsGetDisbursementImportResultResult>(
      {url: `/disbursement/getdisbursementimportresult`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDisbursementImportResultQueryKey = (params?: GetDisbursementImportResultParams,) => [`/disbursement/getdisbursementimportresult`, ...(params ? [params]: [])];

    
export const useGetDisbursementImportResult = <TData = AsyncReturnType<typeof getDisbursementImportResult>, TError = unknown>(
 params?: GetDisbursementImportResultParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDisbursementImportResult>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDisbursementImportResultQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDisbursementImportResult>> = () => getDisbursementImportResult(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDisbursementImportResult>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getDisbursementImports = (
    params?: GetDisbursementImportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsGetDisbursementImportsResult>(
      {url: `/disbursement/getdisbursementimports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetDisbursementImportsQueryKey = (params?: GetDisbursementImportsParams,) => [`/disbursement/getdisbursementimports`, ...(params ? [params]: [])];

    
export const useGetDisbursementImports = <TData = AsyncReturnType<typeof getDisbursementImports>, TError = unknown>(
 params?: GetDisbursementImportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getDisbursementImports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetDisbursementImportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getDisbursementImports>> = () => getDisbursementImports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getDisbursementImports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const getMissingImports = (
    params?: GetMissingImportsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsGetMissingImportsResult>(
      {url: `/disbursement/getmissingimports`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetMissingImportsQueryKey = (params?: GetMissingImportsParams,) => [`/disbursement/getmissingimports`, ...(params ? [params]: [])];

    
export const useGetMissingImports = <TData = AsyncReturnType<typeof getMissingImports>, TError = unknown>(
 params?: GetMissingImportsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getMissingImports>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetMissingImportsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getMissingImports>> = () => getMissingImports(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getMissingImports>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const deleteDisbursementImport = (
    disbursementImportId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsDeleteDisbursementImportResult>(
      {url: `/disbursement/deletedisbursementimport/${disbursementImportId}`, method: 'post'
    },
      options);
    }
  


    export const useDeleteDisbursementImport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteDisbursementImport>, TError,{disbursementImportId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteDisbursementImport>, {disbursementImportId: number}> = (props) => {
          const {disbursementImportId} = props || {};

          return  deleteDisbursementImport(disbursementImportId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteDisbursementImport>, TError, {disbursementImportId: number}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteDisbursementsByReportId = (
    reportId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<DisbursementsDeleteDisbursementImportsByReportIdResult>(
      {url: `/disbursement/deletedisbursementimportsbyreportid/${reportId}`, method: 'post'
    },
      options);
    }
  


    export const useDeleteDisbursementsByReportId = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteDisbursementsByReportId>, TError,{reportId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteDisbursementsByReportId>, {reportId: number}> = (props) => {
          const {reportId} = props || {};

          return  deleteDisbursementsByReportId(reportId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteDisbursementsByReportId>, TError, {reportId: number}, TContext>(mutationFn, mutationOptions)
    }
    