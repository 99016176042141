import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Modal,
  Stack,
  Link,
} from "@mui/material";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import formatGridCell from "shared/formatters/grid";
import formatDate from "shared/formatters/date";
import useDataGrid from "shared/grid/useDataGrid";
import { useSnackbar } from "notistack";
import { icons } from "theme";
import Icon from "shared/Icon";
import {
  useAddLoanInterestRate,
  useDeleteLoanInterestRate,
  useGetLoanInterestRateHistory,
} from "api";
import { Link as RouterLink, useParams } from "react-router-dom";
import formatPercent from "shared/formatters/percentage";
import AddLoanInterestRates from "./forms/AddLoanInterestRates";
import { DeleteForever } from "@mui/icons-material";
import AcceptDialogWarning from "shared/alerts/AcceptDialogWarning";

function PanelLoanRateHistory() {
  const columns: GridColumns = [
    {
      field: "baseRateCodeSymbol",
      headerName: "Rate Code",
      flex: 1,
      renderCell: ({ row }) => (
        <Link component={RouterLink} to={`/loans/loanInterestRate/${row.id}`}>
          {row.baseRateCodeSymbol}
        </Link>
      ),
    },
    {
      field: "effectiveDate",
      headerName: "Effective Date",
      flex: 1,
      valueFormatter: formatGridCell(formatDate),
    },
    {
      field: "baseRatePercentage",
      headerName: "Base Rate",
      flex: 1,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "spread",
      headerName: "Spread",
      flex: 1,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "effectiveRate",
      headerName: "Interest Rate",
      flex: 1,
      valueFormatter: formatGridCell(formatPercent),
    },
    {
      field: "delete",
      headerName: "Delete Interest Rate",
      flex: 1,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => (
        <Link
          onClick={() => {
            setDeleteId(row.id);
            setShowDeleteInterestRateDialog(true);
          }}
        >
          <DeleteForever></DeleteForever>
        </Link>
      ),
    },
  ];
  const { grid } = useDataGrid("payments", columns);
  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data, isFetching, refetch } = useGetLoanInterestRateHistory(loanId);
  const { mutate, ...status } = useAddLoanInterestRate({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Interest Rate Added", {
          variant: "success",
        });
        setOpen(false);
      },
    },
  });

  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteInterestRateDialog, setShowDeleteInterestRateDialog] =
    useState<boolean>(false);

  const { mutate: deleteInterestRateMutate } = useDeleteLoanInterestRate({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Interest Rate Deleted", {
          variant: "success",
        });
      },
    },
  });

  return (
    <Stack>
      <Box>
        <Box sx={{ display: "flex", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <DataGridPro
              {...grid}
              rows={data?.interestRates ?? []}
              rowCount={data?.interestRates.length}
              loading={isFetching}
              components={{
                Toolbar: Toolbar,
              }}
              componentsProps={{
                toolbar: {
                  onAddModal: (setModal: boolean) => {
                    return setOpen(setModal);
                  },
                },
              }}
              autoHeight
            />
          </Box>
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          elevation={4}
          sx={{
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <CardHeader
            title="Add Loan History Rate"
            action={
              <Button sx={{ ml: 2 }} onClick={handleClose}>
                <Icon fixedWidth icon={icons.close} />
              </Button>
            }
          />
          <CardContent>
            <AddLoanInterestRates
              onSave={(form) => {
                mutate({ loanId: loanId, data: form });
              }}
              status={status}
            />
          </CardContent>
        </Card>
      </Modal>
      <AcceptDialogWarning
        alertMessage="Are you sure you want to delete the Loan Interest Rate?"
        initialWarningDialog={showDeleteInterestRateDialog}
        showDialog={(show: boolean) => {
          setShowDeleteInterestRateDialog(show);
        }}
        acceptDelete={(accept: boolean) => {
          if (accept) {
            deleteInterestRateMutate({
              loanInterestRateId: deleteId,
              params: { loanId: loanId },
            });
            setDeleteId(0);
          }
        }}
      />
    </Stack>
  );
}

export default PanelLoanRateHistory;

function Toolbar(props: Pick<DataGridToolbarProps, "onAddModal">) {
  return (
    <DataGridToolbar
      header={"Interest Rate History"}
      addModalButtonLabel={"Add Interest Rate"}
      {...props}
    />
  );
}
