import { Route, Switch, useRouteMatch } from "react-router";
import DisbursementProcessingDetailsPage from "./DisbursementProcessingDetailsPage";
import DisbursementProcessingListPage from "./DisbursementProcessingListPage";
import PaymentProcessingDetailPage from "./PaymentProcessingDetailPage";
import PaymentProcessingListPage from "./PaymentProcessingListPage";

function ProcessingRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/payments`} component={PaymentProcessingListPage} />
      <Route exact path={`${path}/disbursements`} component={DisbursementProcessingListPage} />
      <Route path={`${path}/disbursements/:disbursementImportId`} component={DisbursementProcessingDetailsPage} />
      <Route path={`${path}/:paymentImportId`} component={PaymentProcessingDetailPage} />

    </Switch>
  );
}

export default ProcessingRoutes;
