import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Card, CardContent, Dialog } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { useAddInterestRate, 
  useGetInterestRateDropdowns, 
  useGetInterestRateHistory } from "api/interest-rate/interest-rate";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import AddRateForm from "./forms/AddRateForm";
import { InterestRatesAddInterestRateResult } from "api";

import Icon from "shared/Icon";
import { icons } from "theme";

const columns: GridColumns = [
  {
    field: "code",
    headerName: "Rate Code",
    flex: 1
  },
  {
    field: "effectiveDate",
    headerName: "Effective Date",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "rate",
    headerName: "Rate",
    headerAlign: "left",
    align: "left",
    valueFormatter: (params) => {
      const paramsValue = ((params.value as number) ?? 0) * 100;
      const valueFormatted = Number(paramsValue).toLocaleString();
      return `${valueFormatted} %`;
    },
    valueParser: (value) => Number(value) / 100,
  },
  {
    field: "userID",
    headerName: "User ID",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
];

interface Props {
  baseRateCodeId: number;
}

const dropDownDefaults = {
  baseRateCode: [],
};

function Toolbar(props: Pick<DataGridToolbarProps, "onAdd">) {
  return (
    <DataGridToolbar
      header={"Interest Rate History"}
      addButtonLabel={"Add Interest Rate"}
      {...props}
    />
  );
}

function RateHistory({ baseRateCodeId }: Props) {
  
  const { data: rateHistory } = useGetInterestRateHistory(baseRateCodeId);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const history = useHistory();

  const { data: interestRatesDropDownOptions } = useGetInterestRateDropdowns(
    undefined,
    {
      query: { placeholderData: dropDownDefaults },
    }
  );

  const { mutate, ...status } = useAddInterestRate({
    mutation: {
      onSuccess(data: InterestRatesAddInterestRateResult) {
        history.push(`/admin/rates`);
        enqueueSnackbar("Rate Added", {
          variant: "success",
        });
      },
    },
  });
  
  return (
    <Box>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <AddRateForm
          interestRatesDropDownOptions={interestRatesDropDownOptions!}
          onSave={(form) => mutate({ data: form })}
          baseRateCodeId={baseRateCodeId}
          status={status}
          action={
            <Button onClick={handleClose} sx={{ ml: 2 }}>
              <Icon fixedWidth icon={icons.close} />
            </Button>
          }
        />
      </Dialog>
      <Card elevation={4} sx={{ height: "100%" }}>
        <CardContent>
          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", height: "100%" }}>
              <Box sx={{ flexGrow: 1 }}>
                <DataGridPro
                  rows={rateHistory?.interestRate ?? []}
                  autoHeight
                  rowCount={rateHistory?.interestRate.length}
                  columns={columns}
                  components={{
                    Toolbar: Toolbar,
                  }}
                  componentsProps={{
                    toolbar: {
                      onAdd: handleOpen,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default RateHistory;

function enqueueSnackbar(arg0: string, arg1: { variant: string }) {
  throw new Error("Function not implemented.");
}
