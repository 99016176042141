import {
  CardActions,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardHeaderProps
} from "@mui/material";
import DateField from "shared/forms/fields/DateField";
import { useForm } from "react-hook-form";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import PercentField from "shared/forms/fields/PercentField";
import {
  InterestRatesAddInterestRateForm,
  InterestRatesGetAllInterestRateDropDownsResult,
} from "api";

interface Props {
  onSave(form: InterestRatesAddInterestRateForm): void;
  interestRatesDropDownOptions: InterestRatesGetAllInterestRateDropDownsResult;
  baseRateCodeId: number;
  action?: CardHeaderProps["action"];
}

function AddRateForm({ onSave, interestRatesDropDownOptions, baseRateCodeId, action }: Props) {
  const { control, handleSubmit } = useForm<InterestRatesAddInterestRateForm>({
    defaultValues: {
      baseRateCodeId: baseRateCodeId
    },
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Card elevation={4} onSubmit={onSubmit} component="form">
      <CardHeader title="Add Interest Rate" action={action} />
      <FormErrorAlert />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <PercentField control={control} name="rate" label="Rate" />
          </Grid>
          <Grid item md={6}>
            <DateField
              control={control}
              name="effectiveDate"
              label="Effective Date"
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <SubmitButton variant="save" />
      </CardActions>
    </Card>
  );
}
export default withFormStatus(AddRateForm);
