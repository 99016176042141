import ProcessingDetailPage from "processing/PaymentProcessingDetailPage";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import ReportingDetailPage from "./ReportingDetailPage";
import ReportingListPage from "./ReportingListPage";

function ReportingRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={ReportingListPage} />
      <Route path={`${path}/:reportId`} component={ReportingDetailPage} />
      <Route path={`${path}/processing/:paymentImportId`} component={ProcessingDetailPage} />
    </Switch>
  );
}

export default ReportingRoutes;
