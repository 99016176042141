import React, { useState } from "react";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useGetAllLenders } from "api";
import useLenderContext from "shared/useLenderContext";

function LenderSelector() {
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useGetAllLenders(
    { pageSize: 1000 },
    { query: { enabled: open } } // only fetch results if the menu is open
  );

  const { lender, select, clear } = useLenderContext();

  return (
    <Autocomplete
      fullWidth
      loading={isLoading}
      blurOnSelect
      options={data?.items ?? []}
      getOptionLabel={(opt) => opt.name}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      value={lender ?? null}
      onChange={(_, value) => (value ? select(value) : clear())}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: "#ffffff",
            },
          }}
          {...params}
          label="Filter by lender:"
          placeholder="All Lenders"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default LenderSelector;
