import React from "react";
import { DatePickerProps, DesktopDatePicker } from "@mui/lab";
import { TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { parseISO, formatISO } from "date-fns";
import useFormErrors from "../useFormErrors";
import { FormFieldProps, asVariants } from "./FieldVariants";

type DateFieldProps<T> = FormFieldProps<T> & Pick<DatePickerProps, "label">;

function DateField<T>({
  name,
  control,
  className,
  rules,
  readOnly = false,
  variant = "outlined",
  ...props
}: DateFieldProps<T>) {
  const { getError } = useFormErrors();
  
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value, ref } }) => {
        // we will manually marshall the date value to/from a string value for the form
        // .. this way we can get the exact ISO format we want and avoid timezone issues
        
        const parsedDateVal = parseISO(String(value));

        // setting the date input to null (will display 'mm/dd/yyyy')
        const dateVal = (!value || parsedDateVal.getTime() < 0) ? null : parsedDateVal;

        return (
          <DesktopDatePicker
            {...props}
            inputFormat="MM/dd/yyyy"
            value={dateVal}
            readOnly={readOnly}
            onChange={(date) => {
              const strValue = !(date instanceof Date && !isNaN(date.getTime())) 
                ? null
                : formatISO(date, { representation: "date" });
              window.dispatchEvent(new CustomEvent('dateChange', {detail: {value: strValue!, id: name}}));
              return onChange(strValue);
            }}
            onClose={() => onBlur()}
            renderInput={(params: any) => (
              <MuiTextField
                variant={variant}
                required={props.required}
                {...params}
                {...getError(name)}
                ref={ref}
                className={clsx(className, readOnly && "textfield-readonly")}
              />
            )}
          />
        );
      }}
    />
  );
}

export default asVariants(DateField);
