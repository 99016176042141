import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { clearJwtToken, setJwtToken } from "api/client";
import { ThemeProvider, StyledEngineProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { SnackbarProvider } from "notistack";

import { theme } from "theme";
import Shell from "shared/layout/Shell";
import { LenderContextProvider } from "shared/useLenderContext";

import { AuthState, getAuthSession } from "account/auth/context";
import AuthProvider from "account/auth/AuthProvider";
import AccountRoutes, { PublicRoutes } from "account/AccountRoutes";
import SignOutPage from "account/SignOutPage";
import LenderRoutes from "lenders/LenderRoutes";
import LoanRoutes from "loans/LoanRoutes";
import ProcessingRoutes from "processing/ProcessingRoutes";
import ReportingRoutes from "./reporting/ReportingRoutes";
import ActivityRoutes from "./activity/ActvityRoutes";
import AdminRoutes from "admin/AdminRoutes";
import UserRoutes from "users/UserRoutes";
import getRuntimeConfig from "config";

const queryClient = new QueryClient();
const { Api } = getRuntimeConfig();

function onAuthStateChange(state: AuthState) {
  if (state.authenticated) {
    setJwtToken(async () =>
      (await getAuthSession(state.user)).getAccessToken().getJwtToken()
    );
  } else {
    clearJwtToken();
  }
}
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <AuthProvider
              publicRoutes={<PublicRoutes />}
              onStateChange={onAuthStateChange}
            >
              <QueryClientProvider client={queryClient}>
                <LenderContextProvider>
                  <Shell>
                    <SnackbarProvider autoHideDuration={2000}>
                      <Switch>
                        <Route path="/account" component={AccountRoutes} />
                        <Route path="/lenders" component={LenderRoutes} />
                        <Route path="/loans" component={LoanRoutes} />
                        <Route
                          path="/processing"
                          component={ProcessingRoutes}
                        />
                        <Route path="/reporting" component={ReportingRoutes} />
                        <Route path="/admin" component={AdminRoutes} />
                        <Route path="/activity" component={ActivityRoutes} />
                        <Route path="/users" component={UserRoutes} />
                        <Route path='/reportJob' component={() => { 
                            const url = `${Api.BaseUrl}/reportJob`;
                            window.location.href = url; 
                            return null;
                        }}/>


                        <Route path="/signout" component={SignOutPage} />
                        <Redirect to="/activity" />
                      </Switch>
                    </SnackbarProvider>
                  </Shell>
                </LenderContextProvider>
              </QueryClientProvider>
            </AuthProvider>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
