import React from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { ButtonBase, Typography } from "@mui/material";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";

interface Props {
  label: string;
  icon: IconDefinition;
  to: string;
}

function NavMenuItem({ label, icon, to }: Props) {
  const match = useRouteMatch({
    path: to,
  });

  return (
    <ButtonBase
      component={Link}
      to={to}
      className={match ? "active" : undefined}
      sx={{
        pl: 1,
        pr: 1,
        py: 0,
        mt: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "4rem",
        fontSize: "1.5rem",
        borderLeft: ".5rem solid",
        borderLeftColor: "#fff",
        transition: "border-left .5s ease, color .5s ease",
        "&:hover, &.active": {
          borderLeft: ".5rem solid",
          borderLeftColor: "secondary.main",
          color: "secondary.main",
        },
        "&:hover .icon, &.active .icon": {
          color: "secondary.main",
        },
      }}
    >
      <Icon
        className="icon"
        sx={{
          color: "primary.main",
          transition: "color .5s ease",
        }}
        fixedWidth
        icon={icon}
      />
      <Typography component="span" sx={{ display: "inline-block", pl: 1,}}>
        {label}
      </Typography>
    </ButtonBase>
  );
}

export default NavMenuItem;
