import { Box, Typography } from "@mui/material";
import AddParticipantForm from "./forms/AddParticipantForm";
import { useParams } from "react-router-dom";
import {
  LoansGetLoanDetailResult,
  useAddParticipant,
  useGetParticipantById,
  useUpdateParticipant,
} from "api";
import { useQueryClient } from "react-query";
import InlineFormLayout from "shared/forms/InlineFormLayout";
import UpdateParticipantForm from "./forms/UpdateParticipantForm";

interface ParticipantProps {
  loan?: LoansGetLoanDetailResult;
}
function PanelParticipation({ loan }: ParticipantProps) {
  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);

  const queryClient = useQueryClient();

  const { data: participant, queryKey } = useGetParticipantById(
    {
      loanId: loanId,
      participantId: loan?.loan.participantId ?? 0,
    },
    {
      query: {
        keepPreviousData: true,
      },
    }
  );
  const { mutate: addParticipant, ...addParticipantStatus } = useAddParticipant(
    {
      mutation: {
        onSuccess() {
          queryClient.invalidateQueries(queryKey);
        },
      },
    }
  );

  const { mutate: updateParticipant, ...updateParticipantStatus } =
    useUpdateParticipant({
      mutation: {
        onSuccess() {
          queryClient.invalidateQueries(queryKey);
        },
      },
    });

  return (
    <Box>
      {participant && participant.participant ? (
        <Box pb={4}>
          <Typography variant="h5">Participant Info</Typography>
          <InlineFormLayout>
            <UpdateParticipantForm
              participant={participant?.participant}
              status={updateParticipantStatus}
              loan={loan}
              onSave={(data) =>
                updateParticipant({
                  participantId: participant.participant.id,
                  data,
                })
              }
            />
          </InlineFormLayout>
        </Box>
      ) : (
        <Box pb={4}>
          <Typography variant="h5">Participant Info</Typography>
          <InlineFormLayout>
            <AddParticipantForm
              participant={participant?.participant}
              status={addParticipantStatus}
              loan={loan}
              onSave={(data) => addParticipant({ loanId, data })}
            />
          </InlineFormLayout>
        </Box>
      )}
    </Box>
  );
}

export default PanelParticipation;

