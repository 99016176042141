import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AdminOptions() {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        "& .item": {
          width: "100%",

          "& .MuiTypography-root ": {
            fontSize: "1.5rem",
            fontWeight: 700,
            color: "",
          },
        },
      }}
    >
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/admin/reports/pastDue" className="item">
          <Typography>Past Due</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/admin/reports/newLoans" className="item">
          <Typography>New Loans</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
export default AdminOptions;
