import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Box, Grid, Stack } from "@mui/material";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import DropDownField from "shared/forms/fields/DropDownField";
import {
  InterestRatesGetLoanInterestRateByIdLoanInterestRateModel,
  InterestRatesUpdateLoanInterestRateForm,
  useGetInterestRateDropdowns,
} from "api";
import DateField from "shared/forms/fields/DateField";
import PercentField from "shared/forms/fields/PercentField";

const dropDownDefaults = {
  baseRateCode: [],
};

interface FormProps {
  loanInterestRate: InterestRatesGetLoanInterestRateByIdLoanInterestRateModel;
  onSave(form: InterestRatesUpdateLoanInterestRateForm): void;
}
function EditLoanInterestRateForm({ loanInterestRate, onSave }: FormProps) {
  const { data: interestRatesDropDownOptions } = useGetInterestRateDropdowns(
    undefined,
    {
      query: { placeholderData: dropDownDefaults },
    }
  );

  const { control, handleSubmit, watch, setValue } =
    useForm<InterestRatesUpdateLoanInterestRateForm>({
      defaultValues: {
        ...loanInterestRate,
      },
    });

  const baseRatePercentage = watch("baseRatePercentage");
  const spread = watch("spread");

  useEffect(() => {
    if (baseRatePercentage != null && spread != null) {
      const effectiveRate = baseRatePercentage + spread;
      setValue("effectiveRate", effectiveRate);
    }
  }, [baseRatePercentage, spread, setValue]);

  const onSubmit = handleSubmit((form) => {
    onSave({
      ...form,
    });
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DropDownField
            control={control}
            name="baseRateCodeId"
            label="Rate Code"
            options={interestRatesDropDownOptions?.baseRateCode ?? []}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            control={control}
            name="effectiveDate"
            label="Effective Date"
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="baseRatePercentage"
            label="Base Rate Percentage"
            required={true}
          />
        </Grid>{" "}
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="spread"
            label="Spread"
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="effectiveRate"
            label="Effective Interest Rate"
            required={true}
          />
        </Grid>
      </Grid>
      <Stack pt={2} direction="row" justifyContent="flex-end">
        <SubmitButton variant="save" />
      </Stack>
    </Box>
  );
}

export default withFormStatus(EditLoanInterestRateForm);
