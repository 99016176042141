var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export default function formatCurrency(
  value: number | string | null | undefined
) {
  if (typeof value === "string") {
    value = Number(value);
  }

  if (typeof value === "number") {
    return formatter.format(value);
  }

  return "-";
}
