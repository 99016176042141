import React from "react";
import { Box, Stack, Typography, Button, Link } from "@mui/material";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import { Link as RouterLink } from "react-router-dom";
import {
  ActivitiesAddActivityActivityModel,
  ActivitiesGetAllActivitiesActivityModel,
} from "api";
import { format } from "date-fns";

interface Props {
  icon: IconDefinition;
  activity:
    | ActivitiesGetAllActivitiesActivityModel
    | ActivitiesAddActivityActivityModel;
}

function ActivityListItem({ icon, activity }: Props) {
  return (
    <Stack
      direction="row"
      className="alert-list-item"
      sx={{
        borderBottom: "1px solid",
        borderColor: "gray.main",
        padding: 2,
      }}
    >
      <Box>
        <Link component={RouterLink} to={`/activity/${activity.id}`}>
          <Button
            sx={{
              width: 64,
              height: 64,
              borderRadius: "50%",
              fontSize: "1.75rem",
              color: "primary.main",

              "&:hoover": {
                color: "secondary.main",
              },
            }}
          >
            <Icon className="icon" fixedWidth icon={icon} />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          flex: 1,
        }}
      >
        <Typography
          component="span"
          sx={{ display: "block", fontWeight: "700" }}
        >
          <Link component={RouterLink} to={`/activity/${activity.id}`}>
            {activity.activityTitle}
          </Link>
        </Typography>
        <Box>
          <Typography component="span" sx={{ fontWeight: "700" }}>
            Assigned To:
          </Typography>{" "}
          <Typography component="span">{activity.lenderName}</Typography>
        </Box>
        <Box>
          <Typography component="span" sx={{ fontWeight: "700" }}>
            Created Date:
          </Typography>{" "}
          <Typography component="span">
            {format(Date.parse(activity.activityDate), "MM-dd-yy")}
          </Typography>
        </Box>
      </Box>
      {activity.dueDate && (
        <Stack
          sx={{
            textAlign: "center",
            paddingLeft: 2,
            borderLeft: "1px solid",
            borderColor: "gray.main",
          }}
          justifyContent="center"
          alignItems="center"
        >
          {activity.isTask && (
            <Box>
              <Typography>
                {format(Date.parse(activity.dueDate), "MM-dd-yy")}
              </Typography>
              <Typography sx={{ fontWeight: "700" }}>Due Date</Typography>
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default ActivityListItem;
