import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Link,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Modal,
} from "@mui/material";
import { theme, icons } from "theme";
import CardActions from "@mui/material/CardActions";
import { Link as RouterLink } from "react-router-dom";
import ActivityListItem from "shared/alerts/ActivityListItem";
import AddActivity from "activity/forms/AddActivityForm";
import { useSnackbar } from "notistack";
import Icon from "shared/Icon";
import { useAddActivity, useGetAllActivities } from "api/activity/activity";
import {
  ActivitiesAddActivityActivityModel,
  ActivitiesGetAllActivitiesActivityModel,
} from "api";

interface Props {
  loanId?: number;
  lenderId: number;
}

function RecentActivities({ loanId, lenderId }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: activityData } = useGetAllActivities({
   lenderId,
    pageSize: 3,
  });
  const [activities, setActivities] = useState<
    | ActivitiesGetAllActivitiesActivityModel[]
    | ActivitiesAddActivityActivityModel[]
  >([]);

  const { mutate: activityMutate, ...activityStatus } = useAddActivity({
    mutation: {
      onSuccess(newActivity) {
        enqueueSnackbar("Activity Added", {
          variant: "success",
        });
        setOpen(false);

        const activitiesArrayCopy = activities;
        if (activitiesArrayCopy.length >= 3) activitiesArrayCopy.shift();
        setActivities(activitiesArrayCopy);
        setActivities([...(activities ?? []), newActivity?.item]);
      },
    },
  });

  useEffect(() => {
    const initialActivities = activityData?.items ?? [];
    return setActivities(initialActivities);
  }, [activityData]);
  return (
    <>
      <Grid item xs={12} xl={4}>
        <Card
          elevation={4}
          sx={{
            mb: 4,
          }}
        >
          <CardHeader
            title="Recent Activities"
            action={
              <Button
                sx={{ ml: 2, px: 2, color: theme.palette.secondary.main }}
                onClick={handleOpen}
              >
                Add
              </Button>
            }
          />

          <CardContent>
            <Box>
              {activities?.map((item, index) => {
                return (
                  <ActivityListItem
                    key={index}
                    activity={item}
                    icon={icons.lender}
                  />
                );
              }) ?? []}
            </Box>
          </CardContent>

          <CardActions sx={{ justifyContent: "center" }}>
            <Link component={RouterLink} to={`/activity/`}>
              View All Activities
            </Link>
          </CardActions>
        </Card>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          elevation={4}
          sx={{
            maxWidth: "600px",
            width: "100%",
          }}
        >
          <CardHeader
            title="Add Activity"
            action={
              <Button sx={{ ml: 2 }} onClick={handleClose}>
                <Icon fixedWidth icon={icons.close} />
              </Button>
            }
          />
          <CardContent>
            <AddActivity
              onSave={(form) => activityMutate({ data: form })}
              loanId={loanId}
              lenderId={lenderId}
              status={activityStatus}
            />
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

export default RecentActivities;
