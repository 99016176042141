import { useParams } from "react-router";
import { Box } from "@mui/material";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import { CalculatedDate, useGetPayments } from "api";
import DataGridToolbar, {
  DataGridToolbarProps,
} from "shared/grid/DataGridToolbar";
import useDataGrid from "shared/grid/useDataGrid";
import formatGridCell from "shared/formatters/grid";
import formatCurrency from "shared/formatters/currency";
import formatDate from "shared/formatters/date";


interface Props {
  isSold: boolean;
}

function PanelPayments({isSold}: Props) {
  const { grid, query } = useDataGrid("payments", columns);
  const { loanId: loanIdParam } = useParams<{ loanId: string }>();
  const loanId = Number(loanIdParam);
  
  const {
    data: payments,
    isFetching,
  } = useGetPayments(
    loanId,
    { ...query },
    { query: { keepPreviousData: true } }
  );

  return (
    <Box>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ flexGrow: 1 }}>
          <DataGridPro
            {...grid}
            rows={payments?.items ?? []}
            rowCount={payments?.total}
            loading={isFetching}
            components={{
              Toolbar: Toolbar,
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  totalToFTA: isSold
                },
              },
            }}
            componentsProps={{
              toolbar: {
                nextPaymentDate: payments?.nextDueDate,
              },
            }}
            autoHeight
          />
        </Box>
      </Box>
    </Box>
  );
}

export default PanelPayments;

const columns: GridColumns = [
  {
    field: "referenceNumber",
    headerName: "Reference Number",
    flex: 1,
    headerAlign: "left",
    align: "left",
  },
  {
    field: "principalPayment",
    headerName: "Principal Payment",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency)
  },
  {
    field: "interestPayment",
    headerName: "Interest Payment",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency)
  },
  {
    field: "prepaymentPenaltyPayment",
    headerName: "Prepay Payment",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
    hide: true,
  },
  {
    field: "totalPayment",
    headerName: "Total Payment",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "effectiveDate",
    headerName: "Effective Date",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
  },
  {
    field: "entryDate",
    headerName: "Entry Date",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatDate),
    hide: true,
  },
  {
    field: "principalBalance",
    headerName: "Principal Balance",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "availableBalance",
    headerName: "Available Balance",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "totalToFTA",
    headerName: "Total to FTA",
    flex: 1,
    headerAlign: "right",
    align: "right",
    valueFormatter: formatGridCell(formatCurrency),
  },
  {
    field: "description",
    headerName: "Description",
    flex: 2,
    hide: true,
  },
];


function Toolbar({
  nextPaymentDate,
}: Pick<DataGridToolbarProps, "onAdd"> & { nextPaymentDate?: CalculatedDate }) {
  return (
    <DataGridToolbar
      header="Payment History"
      subheader={`Next Payment Date: ${formatDate(nextPaymentDate?.value)}`}
    />
  );
}
