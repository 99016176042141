import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import {
  UsersGetUserByIdUserModel,
  UsersUpdateUserForm,
} from "api";
import { useForm } from "react-hook-form";
import EmailField from "shared/forms/fields/EmailField";
import PhoneField from "shared/forms/fields/PhoneField";
import TextField from "shared/forms/fields/TextField";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import StickyFooter from "shared/layout/StickyFooter";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  onSave(form: UsersUpdateUserForm): void;
  user: UsersGetUserByIdUserModel;
  enableUser(userId: number): void;
  disableUser(userId: number): void;
}

function EditUserForm({ onSave, enableUser, disableUser, user }: Props) {

  const { control, handleSubmit, reset } = useForm<UsersUpdateUserForm>({
    defaultValues: {
      ...user,
    },
  });

  useEffect(() => reset(user), [user, reset]);

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card elevation={0} sx={{ backgroundColor: "gray.light" }}>
            <CardContent>
              <Box>
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  User Id:
                </Typography>{" "}
                <Typography component="span">{user.id}</Typography>
              </Box>
              <Box>
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  Username:
                </Typography>{" "}
                <Typography component="span">{user.username}</Typography>
              </Box>
              {user.userTypeId === 1 && (
                <Box>
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    AccountType:
                  </Typography>{" "}
                  <Typography component="span">IFS Admin</Typography>
                </Box>
              )}
              {user.userTypeId === 2 && (
                <Box>
                  <Typography component="span" sx={{ fontWeight: 700 }}>
                    AccountType:
                  </Typography>{" "}
                  <Typography component="span">Lender</Typography>
                </Box>
              )}
              <Box>
                <Typography component="span" sx={{ fontWeight: 700 }}>
                  Account Disabled:
                </Typography>{" "}
                <Typography component="span">
                  {user.isDisabled ? "True" : "False"}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={9}>
          <Grid container spacing={4}>
            <Grid item md={6}>
              <TextField
                required={true}
                name="name.first"
                control={control}
                label="First Name"
                placeholder={"John"}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                name="name.last"
                control={control}
                label="Last Name"
                placeholder={"Doe"}
              />
            </Grid>
            <Grid item md={6}>
              <EmailField
                name="contact.email"
                control={control}
                label="Email"
              />
            </Grid>
            <Grid item md={6}>
              <PhoneField
                name="contact.phone"
                control={control}
                label="Phone Number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <StickyFooter>
        <Link variant="button" component={RouterLink} to={`/users`}>
          Back to User List
        </Link>
        {user.isDisabled === false && (
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              disableUser(user.id);
            }}
          >
            Disable User
          </Button>
        )}
        {user.isDisabled === true && (
          <Button
            sx={{ mr: 2 }}
            onClick={() => {
              enableUser(user.id);
            }}
          >
            Enable User
          </Button>
        )}
        <SubmitButton variant="save" />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditUserForm);
