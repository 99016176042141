import React from "react";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { icons } from "../../theme";
import { ButtonBase, Box, Stack } from "@mui/material";
import Icon from "../Icon";
import formatNumber from "shared/formatters/number";
import { Link, useRouteMatch } from "react-router-dom";

interface Props {
  label: string;
  icon: IconDefinition;
  value?: number;
  to?: string;
}

function TabMenuItem({ label, icon, value, to = "/" }: Props) {
  const match = useRouteMatch({
    path: to,
  });

  return (
    <ButtonBase
      component={Link}
      sx={{
        flex: 1,
        height: "100%",
        cursor: "pointer",
        transition: "color .5s ease",
        "&:hover": {
          color: "secondary.main",
        },
        "& .icon": {
          transition: "color .5s ease",
        },
        "&:hover .icon, &.active, &.active .icon": {
          color: "secondary.main",
        },
        "&:hover .line, &.active .line": {
          backgroundColor: "secondary.main",
        },
        ".fa-angle-right": {
          transition: "transform .25s ease",
        },
        "&.active .fa-angle-right": {
          transform: "rotate(90deg)",
        },
      }}
      to={to}
      className={match ? "active" : undefined}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          height: "100%",
          width: "100%",
          background: "#ffffff",
          borderRadius: 2,
          boxShadow: 4,
          transition: "box-shadow .5s ease",
          "&:hover": {
            boxShadow: 8,
          },
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ height: "100%" }}>
          <Box
            component="div"
            className="line"
            sx={{
              display: "block",
              height: "100%",
              width: "1rem",
              backgroundColor: "primary.main",
              borderRadius: ".5rem 0 0 .5rem",
              transition: "all .5s ease",
            }}
          ></Box>

          <Box sx={{ color: "primary.main" }}>
            <Icon className="icon" size="4x" fixedWidth icon={icon} />
          </Box>

          <Box sx={{ textAlign: "left" }}>
            <Box sx={{ fontSize: "2rem", fontWeight: 700 }}>
              {formatNumber(value)}
            </Box>
            <Box sx={{ textTransform: "uppercase" }}>{label}</Box>
          </Box>
        </Stack>

        <Box sx={{ color: "primary.main" }}>
          <Icon className="icon" size="2x" fixedWidth icon={icons.angleRight} />
        </Box>
      </Stack>
    </ButtonBase>
  );
}

export default TabMenuItem;
