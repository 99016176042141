import React from "react";
import { Redirect } from "react-router";
import useQueryString from "shared/useQueryString";
import { useResetPasswordFlow } from "./auth/useForgotPasswordFlow";
import ResetPasswordForm from "./forms/ResetPasswordForm";

function ResetPasswordPage() {
  const { query } = useQueryString();
  const username = query.get("username");
  const code = query.get("code") ?? undefined;

  if (!username) throw new Error("Username is required.");

  const flow = useResetPasswordFlow(username);

  switch (flow.step) {
    case "ResetPasswordStep":
      return <ResetPasswordForm {...flow} verificationCode={code} />;

    case "ForgotPasswordCompleteStep":
      return <Redirect to="/" />;

    default:
      return <Redirect to="/forgot-password" />;
  }
}

export default ResetPasswordPage;
