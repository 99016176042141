import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "theme";
import { useAuthUser } from "./auth/context";
import { useCallback, useEffect, useState } from "react";
import { FormErrorAlert } from "shared/forms";
import { useSnackbar } from "notistack";

function ProfilePage() {
  const user = useAuthUser();
  const userName = user.getUsername();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (oldPassword === "" || newPassword === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newPassword, oldPassword, setDisabled]);
  const { enqueueSnackbar } = useSnackbar();
  const changePassword = useCallback(
    (oldPassword: string, newPassword: string) => {
      user.changePassword(oldPassword, newPassword, (err, result) => {
        if (err) {
          enqueueSnackbar(err.message, { variant: "error",autoHideDuration:8000});
        } else {
          enqueueSnackbar("Password Changed", { variant: "success" });
        }
        setOldPassword('');
        setNewPassword('');
      });
    },[user, enqueueSnackbar]);
  return (
    <>
      <Card elevation={4} sx={{ flex: 1 }}>
        <Typography
          variant="subtitle1"
          sx={{
            textTransform: "uppercase",
            fontSize: "1.25rem",
            paddingLeft: 2,
            paddingTop: 2,
            fontWeight: 700,
          }}
        >
          Profile
        </Typography>
        <CardContent sx={{ flex: 1 }}>
          <Stack direction="column" alignItems="center" spacing={5}>
            <Stack
              direction="row"
              spacing={2}
              color={theme.palette.primary.main}
            >
              <Typography variant="h4">Username:</Typography>
              <Typography variant="h4">{userName}</Typography>
            </Stack>

            <Stack>
              <Card sx={{ maxWidth: 400, width: "100%" }} elevation={4}>
                <CardHeader title="Change Password" />
                <CardContent>
                  <Stack spacing={2}>
                    <TextField
                      label="Old Password"
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <TextField
                      label="New Password"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </Stack>
                </CardContent>
                <FormErrorAlert />
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button
                    onClick={() => changePassword(oldPassword, newPassword)}
                    disabled={disabled}
                  >
                    Change Password
                  </Button>
                </CardActions>
              </Card>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

export default ProfilePage;
