import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardHeaderProps,
} from "@mui/material";
import { LoansAddLoanForm, LoansGetAllDropDownsItemModel } from "api";
import { Control } from "react-hook-form";
import { FormErrorAlert } from "shared/forms";
import DateField from "shared/forms/fields/DateField";
import DropDownField from "shared/forms/fields/DropDownField";

type DefermentDetailProps = {
  defermentOpt: LoansGetAllDropDownsItemModel[];
  action?: CardHeaderProps["action"];
  control: Control<LoansAddLoanForm>;
};
function DefermentForm({
  control,
  defermentOpt,
  action,
}: DefermentDetailProps) {
  return (
    <Card elevation={4}>
      <CardHeader title="Deferment Information" action={action} />
      <FormErrorAlert />
      <CardContent>
        <Grid container spacing={4}>
          <Grid item md={6}>
            <DateField control={control} name="beginDate" label="Begin Date" />
          </Grid>
          <Grid item md={6}>
            <DateField control={control} name="endDate" label="End Date" />
          </Grid>
          <Grid item md={6}>
            <DateField
              control={control}
              name="nextPaymentDate"
              label="Next Payment Date"
            />
          </Grid>
          <Grid item md={6}>
            <DropDownField
              control={control}
              name="defermentTypeId"
              label="Deferment Type"
              options={defermentOpt}
              getOptionKey={(opt) => opt.id}
              getOptionLabel={(opt) => opt.label}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default DefermentForm;
