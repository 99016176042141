/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  LendersGetAllLendersResult,
  GetAllLendersParams,
  LendersAddLenderResult,
  LendersAddLenderForm,
  LendersGetLenderByIdResult,
  LendersUpdateLenderResult,
  LendersUpdateLenderForm,
  LendersGetLenderStatisticsResult,
  GetLenderStatisticsParams,
  LendersDeleteLenderResult
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllLenders = (
    params?: GetAllLendersParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersGetAllLendersResult>(
      {url: `/lender`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllLendersQueryKey = (params?: GetAllLendersParams,) => [`/lender`, ...(params ? [params]: [])];

    
export const useGetAllLenders = <TData = AsyncReturnType<typeof getAllLenders>, TError = unknown>(
 params?: GetAllLendersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllLenders>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLendersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllLenders>> = () => getAllLenders(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllLenders>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addLender = (
    lendersAddLenderForm: LendersAddLenderForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersAddLenderResult>(
      {url: `/lender`, method: 'post',
      data: lendersAddLenderForm
    },
      options);
    }
  


    export const useAddLender = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addLender>, TError,{data: LendersAddLenderForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addLender>, {data: LendersAddLenderForm}> = (props) => {
          const {data} = props || {};

          return  addLender(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addLender>, TError, {data: LendersAddLenderForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getLenderById = (
    lenderId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersGetLenderByIdResult>(
      {url: `/lender/${lenderId}`, method: 'get'
    },
      options);
    }
  

export const getGetLenderByIdQueryKey = (lenderId: number,) => [`/lender/${lenderId}`];

    
export const useGetLenderById = <TData = AsyncReturnType<typeof getLenderById>, TError = unknown>(
 lenderId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLenderById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLenderByIdQueryKey(lenderId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLenderById>> = () => getLenderById(lenderId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLenderById>, TError, TData>(queryKey, queryFn, {enabled: !!(lenderId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const updateLender = (
    lenderId: number,
    lendersUpdateLenderForm: LendersUpdateLenderForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersUpdateLenderResult>(
      {url: `/lender/${lenderId}`, method: 'post',
      data: lendersUpdateLenderForm
    },
      options);
    }
  


    export const useUpdateLender = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateLender>, TError,{lenderId: number;data: LendersUpdateLenderForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateLender>, {lenderId: number;data: LendersUpdateLenderForm}> = (props) => {
          const {lenderId,data} = props || {};

          return  updateLender(lenderId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateLender>, TError, {lenderId: number;data: LendersUpdateLenderForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getLenderStatistics = (
    params?: GetLenderStatisticsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersGetLenderStatisticsResult>(
      {url: `/lender/stats`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetLenderStatisticsQueryKey = (params?: GetLenderStatisticsParams,) => [`/lender/stats`, ...(params ? [params]: [])];

    
export const useGetLenderStatistics = <TData = AsyncReturnType<typeof getLenderStatistics>, TError = unknown>(
 params?: GetLenderStatisticsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLenderStatistics>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLenderStatisticsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLenderStatistics>> = () => getLenderStatistics(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLenderStatistics>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const deleteLender = (
    lenderId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<LendersDeleteLenderResult>(
      {url: `/lender/delete/${lenderId}`, method: 'post'
    },
      options);
    }
  


    export const useDeleteLender = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteLender>, TError,{lenderId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteLender>, {lenderId: number}> = (props) => {
          const {lenderId} = props || {};

          return  deleteLender(lenderId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteLender>, TError, {lenderId: number}, TContext>(mutationFn, mutationOptions)
    }
    