import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import LoanListPage from "./LoanListPage";
import LoanDetailPage from "./LoanDetailPage";
import LoanAddLoanPage from "./LoanAddLoanPage";
import LoanInterestRateDetailsPage from "./LoanInterestRateDetailsPage";

function LoanRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={LoanListPage} />
      <Route path={`${path}/add`} component={LoanAddLoanPage} />
      <Route
        path={`${path}/loanInterestRate/:loanInterestRateId`}
        component={LoanInterestRateDetailsPage}
      />
      <Route path={`${path}/:loanId`} component={LoanDetailPage} />
    </Switch>
  );
}

export default LoanRoutes;
