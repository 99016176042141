import React, { useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import withFormStatus from "../../shared/forms/withFormStatus";
import FormErrorAlert from "../../shared/forms/FormErrorAlert";
import DateField from "../../shared/forms/fields/DateField";
import SubmitButton from "../../shared/forms/SubmitButton";
import {
  InterestRatesAddLoanInterestRateForm,
  useGetInterestRateDropdowns,
} from "api";
import PercentField from "shared/forms/fields/PercentField";
import DropDownField from "shared/forms/fields/DropDownField";

interface Props {
  onSave(form: InterestRatesAddLoanInterestRateForm): void;
}

const dropDownDefaults = {
  baseRateCode: [],
};

function AddLoanInterestRates({ onSave }: Props) {
  const { control, handleSubmit, watch, setValue } =
    useForm<InterestRatesAddLoanInterestRateForm>();

  const { data: interestRatesDropDownOptions } = useGetInterestRateDropdowns(
    undefined,
    {
      query: { placeholderData: dropDownDefaults },
    }
  );

  // Watch the values of baseRatePercentage and spread
  const baseRatePercentage = watch("baseRatePercentage");
  const spread = watch("spread");

  useEffect(() => {
    // Calculate effective rate only if both baseRatePercentage and spread are defined
    if (baseRatePercentage != null && spread != null) {
      const effectiveRate = baseRatePercentage + spread;
      // Update the value of effectiveRate in the form
      setValue("effectiveRate", effectiveRate);
    }
  }, [baseRatePercentage, spread, setValue]);

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DropDownField
            control={control}
            name="baseRateCodeId"
            label="Rate Code"
            options={interestRatesDropDownOptions?.baseRateCode ?? []}
            getOptionKey={(opt) => opt.id}
            getOptionLabel={(opt) => opt.label}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateField
            control={control}
            name="effectiveDate"
            label="Effective Date"
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="baseRatePercentage"
            label="Base Rate Percentage"
            required={true}
          />
        </Grid>{" "}
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="spread"
            label="Spread"
            required={true}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField
            control={control}
            name="effectiveRate"
            label="Effective Interest Rate"
            required={true}
          />
        </Grid>
      </Grid>
      <Stack pt={2} direction="row" justifyContent="flex-end">
        <SubmitButton variant="save" />
      </Stack>
    </Box>
  );
}
export default withFormStatus(AddLoanInterestRates);
