import React from "react";
import { Autocomplete, TextField as MuiTextField } from "@mui/material";
import { Controller } from "react-hook-form";
import clsx from "clsx";
import useFormErrors from "../useFormErrors";
import { FormTextFieldProps } from "./FieldVariants";

type MultiSelectFieldProps<T, TOptions> = FormTextFieldProps<
  T,
  {
    options: TOptions[];
    getOptionLabel(option: TOptions): string;
  }
>;

function MultiSelectField<T, TOptions>({
  name,
  control,
  options,
  label,
  className,
  rules,
  readOnly = false,
  getOptionLabel,
  ...props
}: MultiSelectFieldProps<T, TOptions>) {
  const { getError } = useFormErrors();
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, value: keyValueArray, ref } }) => {
        const newKeyArray = (keyValueArray as Array<TOptions>) || null;
        return (
          <Autocomplete
            multiple={true}
            {...props}
            value={newKeyArray ?? []}
            options={options}
            getOptionLabel={(option) => getOptionLabel(option)}
            isOptionEqualToValue={(option, value) => option === value}
            filterSelectedOptions={true}
            disableCloseOnSelect
            onChange={(_, newArray) => onChange(newArray ?? null)}
            renderInput={({ InputProps, ...params }: any) => (
              <MuiTextField
                {...params}
                {...getError(name)}
                className={clsx(className, readOnly && "textfield-readonly")}
                label={label}
                onBlur={onBlur}
                inputRef={ref}
                InputProps={{ ...InputProps, readOnly }}
              />
            )}
          />
        );
      }}
    />
  );
}

export default MultiSelectField;
