import React, { useMemo } from "react";
import { Link } from "@mui/material";
import { saveAs } from "file-saver";
import filesize from "filesize";
import { DocumentsAttachment, useGetDocumentByKey } from "api";

interface Props {
  activityId: number;
  attachment: DocumentsAttachment;
}

function DownloadDocumentLink({
  activityId,
  attachment: { documentKey, filename, contentLength },
}: Props) {
  const { refetch } = useGetDocumentByKey(
    {
      activityId,
      key: documentKey,
    },
    {
      query: {
        enabled: false, // don't run the query immediately when mounted
        cacheTime: 0, // don't cache query results
        retry: 0, // don't retry the query if it fails
        onSuccess(data: Blob) {
          // when data is fetched, save it
          saveAs(data, filename || undefined);
        },
      },
      request: {
        // interpret the response as a blob
        responseType: "blob",
      },
    }
  );

  const size = useMemo(
    () => filesize(contentLength, { round: 0 }),
    [contentLength]
  );

  return (
    <Link onClick={() => refetch()} sx={{ cursor: "pointer" }}>
      {filename} ({size})
    </Link>
  );
}

export default DownloadDocumentLink;
