import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";

interface ResetPasswordFormProps {
  verificationCode?: string;
  onConfirmPassword: (password: string, code: string) => void;
}

function ResetPasswordForm({
  verificationCode,
  onConfirmPassword,
}: ResetPasswordFormProps) {
  const { register, handleSubmit } = useForm<{
    password: string;
    confirmPassword: string;
    verificationCode: string;
  }>({ defaultValues: { verificationCode } });

  const onSubmit = handleSubmit((form) =>
    onConfirmPassword(form.password, form.verificationCode)
  );

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Card
        component="form"
        onSubmit={onSubmit}
        sx={{ maxWidth: 400, width: "100%" }}
        elevation={4}
      >
        <CardHeader title="Reset Password" />
        <CardContent>
          <Stack spacing={2}>
            {!verificationCode && (
              <TextField
                label="Verification Code"
                {...register("verificationCode")}
              />
            )}
            <TextField
              label="Password"
              type="password"
              {...register("password", { required: true })}
            />
            <TextField
              label="Confirm Password"
              type="password"
              {...register("confirmPassword", { required: true })}
            />
          </Stack>
        </CardContent>
        <FormErrorAlert />
        <CardActions sx={{ justifyContent: "center" }}>
          <SubmitButton sx={{ mx: 1 }}>Change Password</SubmitButton>
          <Button sx={{ mx: 1 }} component={Link} to="/signin" variant="text">
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
}

export default withFormStatus(ResetPasswordForm);
