import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function AdminOptions() {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        "& .item": {
          width: "100%",

          "& .MuiTypography-root ": {
            fontSize: "1.5rem",
            fontWeight: 700,
            color: "",
          },
        },
      }}
    >
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/users" className="item">
          <Typography>Users</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/lenders/add" className="item">
          <Typography>Lenders</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/admin/rates" className="item">
          <Typography>Rates</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/admin/dueDates" className="item">
          <Typography>Due Dates</Typography>
        </Button>
      </Grid>
      <Grid item xs={12} md={3} xl={4}>
        <Button component={Link} to="/admin/reports" className="item">
          <Typography>Reports</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
export default AdminOptions;
