import React from "react";
import { useParams } from "react-router";
import { Card, CardContent, CardHeader } from "@mui/material";
import EditUserForm from "./forms/EditUserForm";

import {
  useDisableUser,
  useEnableUser,
  useGetUserById,
  useUpdateUser,
} from "api";
import { useSnackbar } from "notistack";

function UserDetailPage() {
  const { userId: userIdParam } = useParams<{ userId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const userId = Number(userIdParam);
  const { data, refetch } = useGetUserById(userId);

  const { mutate: DisableUserMutate } = useDisableUser({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Account Disabled", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Failed to Disable Account", {
          variant: "error",
        });
      },
    },
  });

  const { mutate: EnableUserMutate } = useEnableUser({
    mutation: {
      onSuccess() {
        refetch();
        enqueueSnackbar("Account Enable", {
          variant: "success",
        });
      },
      onError() {
        enqueueSnackbar("Failed to Enable Account", {
          variant: "error",
        });
      },
    },
  });

  const { mutate, ...status } = useUpdateUser();

  return (
    <Card elevation={4} sx={{ overflow: "unset", width: "100%" }}>
      <CardHeader title="User Details" />

      <CardContent>
        {data && (
          <EditUserForm
            status={status}
            user={data.user}
            onSave={(form) => mutate({ userId, data: form })}
            enableUser={(userId) => EnableUserMutate({ userId: userId })}
            disableUser={(userId) => DisableUserMutate({ userId: userId })}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default UserDetailPage;
