/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  UsersGetAllUsersResult,
  GetAllUsersParams,
  UsersAddUserResult,
  UsersAddUserForm,
  UsersGetUserByIdResult,
  UsersUpdateUserResult,
  UsersUpdateUserForm,
  UsersGetUserTypesResult,
  GetUserTypesParams,
  UsersResetUserPasswordResult,
  UsersDisableUserResult,
  UsersEnableUserResult
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllUsers = (
    params?: GetAllUsersParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersGetAllUsersResult>(
      {url: `/user`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllUsersQueryKey = (params?: GetAllUsersParams,) => [`/user`, ...(params ? [params]: [])];

    
export const useGetAllUsers = <TData = AsyncReturnType<typeof getAllUsers>, TError = unknown>(
 params?: GetAllUsersParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllUsers>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllUsers>> = () => getAllUsers(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllUsers>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addUser = (
    usersAddUserForm: UsersAddUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersAddUserResult>(
      {url: `/user`, method: 'post',
      data: usersAddUserForm
    },
      options);
    }
  


    export const useAddUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addUser>, TError,{data: UsersAddUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addUser>, {data: UsersAddUserForm}> = (props) => {
          const {data} = props || {};

          return  addUser(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addUser>, TError, {data: UsersAddUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getUserById = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersGetUserByIdResult>(
      {url: `/user/${userId}`, method: 'get'
    },
      options);
    }
  

export const getGetUserByIdQueryKey = (userId: number,) => [`/user/${userId}`];

    
export const useGetUserById = <TData = AsyncReturnType<typeof getUserById>, TError = unknown>(
 userId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUserById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserByIdQueryKey(userId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserById>> = () => getUserById(userId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUserById>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const updateUser = (
    userId: number,
    usersUpdateUserForm: UsersUpdateUserForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersUpdateUserResult>(
      {url: `/user/${userId}`, method: 'post',
      data: usersUpdateUserForm
    },
      options);
    }
  


    export const useUpdateUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateUser>, TError,{userId: number;data: UsersUpdateUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateUser>, {userId: number;data: UsersUpdateUserForm}> = (props) => {
          const {userId,data} = props || {};

          return  updateUser(userId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateUser>, TError, {userId: number;data: UsersUpdateUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getUserTypes = (
    params?: GetUserTypesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersGetUserTypesResult>(
      {url: `/user/usertypes`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetUserTypesQueryKey = (params?: GetUserTypesParams,) => [`/user/usertypes`, ...(params ? [params]: [])];

    
export const useGetUserTypes = <TData = AsyncReturnType<typeof getUserTypes>, TError = unknown>(
 params?: GetUserTypesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getUserTypes>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetUserTypesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getUserTypes>> = () => getUserTypes(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getUserTypes>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const resetUserPassword = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersResetUserPasswordResult>(
      {url: `/user/password/reset/${userId}`, method: 'post'
    },
      options);
    }
  


    export const useResetUserPassword = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof resetUserPassword>, TError,{userId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof resetUserPassword>, {userId: number}> = (props) => {
          const {userId} = props || {};

          return  resetUserPassword(userId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof resetUserPassword>, TError, {userId: number}, TContext>(mutationFn, mutationOptions)
    }
    export const disableUser = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersDisableUserResult>(
      {url: `/user/disable/${userId}`, method: 'post'
    },
      options);
    }
  


    export const useDisableUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof disableUser>, TError,{userId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof disableUser>, {userId: number}> = (props) => {
          const {userId} = props || {};

          return  disableUser(userId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof disableUser>, TError, {userId: number}, TContext>(mutationFn, mutationOptions)
    }
    export const enableUser = (
    userId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<UsersEnableUserResult>(
      {url: `/user/enable/${userId}`, method: 'post'
    },
      options);
    }
  


    export const useEnableUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof enableUser>, TError,{userId: number}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof enableUser>, {userId: number}> = (props) => {
          const {userId} = props || {};

          return  enableUser(userId,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof enableUser>, TError, {userId: number}, TContext>(mutationFn, mutationOptions)
    }
    