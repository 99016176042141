/**
 * Generated by orval v6.5.1 🍺
 * Do not edit manually.
 * IFS API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  InterestRatesGetAllInterestRatesResult,
  GetAllInterestRatesParams,
  InterestRatesAddInterestRateResult,
  InterestRatesAddInterestRateForm,
  InterestRatesGetInterestRateByIdResult,
  InterestRatesGetInterestRateHistoryResult,
  InterestRatesUpdateInterestRateResult,
  InterestRatesUpdateInterestRateForm,
  InterestRatesGetLoanInterestRateByIdResult,
  InterestRatesAddLoanInterestRateResult,
  InterestRatesAddLoanInterestRateForm,
  InterestRatesDeleteLoanInterestRateResult,
  DeleteLoanInterestRateParams,
  InterestRatesUpdateLoanInterestRateResult,
  InterestRatesUpdateLoanInterestRateForm,
  InterestRatesGetAllInterestRateDropDownsResult,
  GetInterestRateDropdownsParams
} from '../backend.schemas'
import { apiRequest } from '.././client'

type AsyncReturnType<
T extends (...args: any) => Promise<any>
> = T extends (...args: any) => Promise<infer R> ? R : any;


type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllInterestRates = (
    params?: GetAllInterestRatesParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesGetAllInterestRatesResult>(
      {url: `/interestrate`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetAllInterestRatesQueryKey = (params?: GetAllInterestRatesParams,) => [`/interestrate`, ...(params ? [params]: [])];

    
export const useGetAllInterestRates = <TData = AsyncReturnType<typeof getAllInterestRates>, TError = unknown>(
 params?: GetAllInterestRatesParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getAllInterestRates>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetAllInterestRatesQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getAllInterestRates>> = () => getAllInterestRates(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getAllInterestRates>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const addInterestRate = (
    interestRatesAddInterestRateForm: InterestRatesAddInterestRateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesAddInterestRateResult>(
      {url: `/interestrate`, method: 'post',
      data: interestRatesAddInterestRateForm
    },
      options);
    }
  


    export const useAddInterestRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addInterestRate>, TError,{data: InterestRatesAddInterestRateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addInterestRate>, {data: InterestRatesAddInterestRateForm}> = (props) => {
          const {data} = props || {};

          return  addInterestRate(data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addInterestRate>, TError, {data: InterestRatesAddInterestRateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getInterestRateById = (
    interestRateId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesGetInterestRateByIdResult>(
      {url: `/interestrate/rate/${interestRateId}`, method: 'get'
    },
      options);
    }
  

export const getGetInterestRateByIdQueryKey = (interestRateId: number,) => [`/interestrate/rate/${interestRateId}`];

    
export const useGetInterestRateById = <TData = AsyncReturnType<typeof getInterestRateById>, TError = unknown>(
 interestRateId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getInterestRateById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetInterestRateByIdQueryKey(interestRateId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getInterestRateById>> = () => getInterestRateById(interestRateId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getInterestRateById>, TError, TData>(queryKey, queryFn, {enabled: !!(interestRateId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const getInterestRateHistory = (
    baseCodeRateId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesGetInterestRateHistoryResult>(
      {url: `/interestrate/history/${baseCodeRateId}`, method: 'get'
    },
      options);
    }
  

export const getGetInterestRateHistoryQueryKey = (baseCodeRateId: number,) => [`/interestrate/history/${baseCodeRateId}`];

    
export const useGetInterestRateHistory = <TData = AsyncReturnType<typeof getInterestRateHistory>, TError = unknown>(
 baseCodeRateId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getInterestRateHistory>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetInterestRateHistoryQueryKey(baseCodeRateId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getInterestRateHistory>> = () => getInterestRateHistory(baseCodeRateId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getInterestRateHistory>, TError, TData>(queryKey, queryFn, {enabled: !!(baseCodeRateId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const updateInterestRate = (
    interestRateId: number,
    interestRatesUpdateInterestRateForm: InterestRatesUpdateInterestRateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesUpdateInterestRateResult>(
      {url: `/interestrate/${interestRateId}`, method: 'post',
      data: interestRatesUpdateInterestRateForm
    },
      options);
    }
  


    export const useUpdateInterestRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateInterestRate>, TError,{interestRateId: number;data: InterestRatesUpdateInterestRateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateInterestRate>, {interestRateId: number;data: InterestRatesUpdateInterestRateForm}> = (props) => {
          const {interestRateId,data} = props || {};

          return  updateInterestRate(interestRateId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateInterestRate>, TError, {interestRateId: number;data: InterestRatesUpdateInterestRateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getLoanInterestRateById = (
    loanInterestRateId: number,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesGetLoanInterestRateByIdResult>(
      {url: `/interestrate/loaninterestrate/${loanInterestRateId}`, method: 'get'
    },
      options);
    }
  

export const getGetLoanInterestRateByIdQueryKey = (loanInterestRateId: number,) => [`/interestrate/loaninterestrate/${loanInterestRateId}`];

    
export const useGetLoanInterestRateById = <TData = AsyncReturnType<typeof getLoanInterestRateById>, TError = unknown>(
 loanInterestRateId: number, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getLoanInterestRateById>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetLoanInterestRateByIdQueryKey(loanInterestRateId);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getLoanInterestRateById>> = () => getLoanInterestRateById(loanInterestRateId, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getLoanInterestRateById>, TError, TData>(queryKey, queryFn, {enabled: !!(loanInterestRateId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

export const addLoanInterestRate = (
    loanId: number,
    interestRatesAddLoanInterestRateForm: InterestRatesAddLoanInterestRateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesAddLoanInterestRateResult>(
      {url: `/interestrate/loaninterestrate/add/${loanId}`, method: 'post',
      data: interestRatesAddLoanInterestRateForm
    },
      options);
    }
  


    export const useAddLoanInterestRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof addLoanInterestRate>, TError,{loanId: number;data: InterestRatesAddLoanInterestRateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof addLoanInterestRate>, {loanId: number;data: InterestRatesAddLoanInterestRateForm}> = (props) => {
          const {loanId,data} = props || {};

          return  addLoanInterestRate(loanId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof addLoanInterestRate>, TError, {loanId: number;data: InterestRatesAddLoanInterestRateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const deleteLoanInterestRate = (
    loanInterestRateId: number,
    params?: DeleteLoanInterestRateParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesDeleteLoanInterestRateResult>(
      {url: `/interestrate/loaninterestrate/delete/${loanInterestRateId}`, method: 'post',
        params,
    },
      options);
    }
  


    export const useDeleteLoanInterestRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof deleteLoanInterestRate>, TError,{loanInterestRateId: number;params?: DeleteLoanInterestRateParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof deleteLoanInterestRate>, {loanInterestRateId: number;params?: DeleteLoanInterestRateParams}> = (props) => {
          const {loanInterestRateId,params} = props || {};

          return  deleteLoanInterestRate(loanInterestRateId,params,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof deleteLoanInterestRate>, TError, {loanInterestRateId: number;params?: DeleteLoanInterestRateParams}, TContext>(mutationFn, mutationOptions)
    }
    export const updateLoanInterestRate = (
    loanInterestRateId: number,
    interestRatesUpdateLoanInterestRateForm: InterestRatesUpdateLoanInterestRateForm,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesUpdateLoanInterestRateResult>(
      {url: `/interestrate/loaninterestrate/update/${loanInterestRateId}`, method: 'post',
      data: interestRatesUpdateLoanInterestRateForm
    },
      options);
    }
  


    export const useUpdateLoanInterestRate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<AsyncReturnType<typeof updateLoanInterestRate>, TError,{loanInterestRateId: number;data: InterestRatesUpdateLoanInterestRateForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options || {}

      


      const mutationFn: MutationFunction<AsyncReturnType<typeof updateLoanInterestRate>, {loanInterestRateId: number;data: InterestRatesUpdateLoanInterestRateForm}> = (props) => {
          const {loanInterestRateId,data} = props || {};

          return  updateLoanInterestRate(loanInterestRateId,data,requestOptions)
        }

      return useMutation<AsyncReturnType<typeof updateLoanInterestRate>, TError, {loanInterestRateId: number;data: InterestRatesUpdateLoanInterestRateForm}, TContext>(mutationFn, mutationOptions)
    }
    export const getInterestRateDropdowns = (
    params?: GetInterestRateDropdownsParams,
 options?: SecondParameter<typeof apiRequest>) => {
      return apiRequest<InterestRatesGetAllInterestRateDropDownsResult>(
      {url: `/interestrate/interestratedropdowns`, method: 'get',
        params,
    },
      options);
    }
  

export const getGetInterestRateDropdownsQueryKey = (params?: GetInterestRateDropdownsParams,) => [`/interestrate/interestratedropdowns`, ...(params ? [params]: [])];

    
export const useGetInterestRateDropdowns = <TData = AsyncReturnType<typeof getInterestRateDropdowns>, TError = unknown>(
 params?: GetInterestRateDropdownsParams, options?: { query?:UseQueryOptions<AsyncReturnType<typeof getInterestRateDropdowns>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options || {}

  const queryKey = queryOptions?.queryKey ?? getGetInterestRateDropdownsQueryKey(params);

  

  const queryFn: QueryFunction<AsyncReturnType<typeof getInterestRateDropdowns>> = () => getInterestRateDropdowns(params, requestOptions);

  const query = useQuery<AsyncReturnType<typeof getInterestRateDropdowns>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

