import { useForm } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import {
  LendersAddLenderLenderModel,
  LendersUpdateLenderForm,
  PermitModel,
} from "api";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import PhoneField from "shared/forms/fields/PhoneField";
import TextField from "shared/forms/fields/TextField";
import DropDownField from "shared/forms/fields/DropDownField";
import USStates from "shared/USStates.json";
import StickyFooter from "shared/layout/StickyFooter";
import RadioGroupField from "shared/forms/fields/RadioGroupField";
interface Props {
  lender: LendersAddLenderLenderModel;
  permit: PermitModel;
  onSave(form: LendersUpdateLenderForm): void;
}

const WeeklyReminders = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

function EditLenderForm({ lender, permit, onSave }: Props) {
  const { control, handleSubmit } = useForm<LendersUpdateLenderForm>({
    defaultValues: lender,
  });

  const onSubmit = handleSubmit((form) => onSave(form));

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item md={4}>
          <TextField control={control} name="firs" label="FIRS" />
        </Grid>
        <Grid item md={8}>
          <TextField control={control} name="name" label="Lender Name" />
        </Grid>
        <Grid item md={12}>
          <TextField
            control={control}
            name="locationId"
            label="Location Id"
            // need to add permit prop?
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            control={control}
            name="address.line1"
            label="Lender Address Line 1"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            control={control}
            name="address.line2"
            label="Lender Address Line 2"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField control={control} name="address.city" label="City" />
        </Grid>

        <Grid item xs={12} md={4}>
          <DropDownField
            options={USStates}
            getOptionKey={(opt) => opt.key}
            getOptionLabel={(opt) => opt.key}
            control={control}
            name="address.state"
            label="State"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField control={control} name="address.zip" label="Zip" />
        </Grid>

        <Grid item xs={12}>
          <TextField
            control={control}
            name="contact.name"
            label="Contact Name"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            control={control}
            name="contact.email"
            label="Contact Email"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <PhoneField
            control={control}
            name="contact.phone"
            label="Contact Phone"
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <PhoneField
            control={control}
            name="contact.fax"
            label="Contact Fax"
          />
        </Grid>
        <Grid item xs={12}>
          <RadioGroupField
            control={control}
            labelName="Weekly Email Reminders"
            name="weeklyReminders"
            options={WeeklyReminders}
          />
        </Grid>
      </Grid>
      <StickyFooter>
        <SubmitButton variant="save" />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(EditLenderForm);
