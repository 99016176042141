import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";

interface ForgotPasswordFormProps {
  username?: string;
  onForgotPassword(username: string): void;
}

function ForgotPasswordForm({
  username,
  onForgotPassword,
}: ForgotPasswordFormProps) {
  const { register, handleSubmit } = useForm<{
    username: string;
  }>({ defaultValues: { username } });

  const onSubmit = handleSubmit((form) => onForgotPassword(form.username));

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: "100vh" }}
    >
      <Card
        component="form"
        onSubmit={onSubmit}
        sx={{ maxWidth: 400, width: "100%" }}
        elevation={4}
      >
        <CardHeader title="Forgot Password" />
        <CardContent>
          <Stack spacing={2}>
            <TextField
              label="Username"
              {...register("username", { required: true })}
            />
          </Stack>
        </CardContent>
        <FormErrorAlert />
        <CardActions sx={{ justifyContent: "center" }}>
          <SubmitButton sx={{ mx: 1 }}>Submit</SubmitButton>
          <Button sx={{ mx: 1 }} component={Link} to="/signin" variant="text">
            Cancel
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
}

export default withFormStatus(ForgotPasswordForm);
