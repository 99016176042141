export default function getRuntimeConfig() {
  const runtime = (globalThis as any)?.["__RUNTIME_CONFIG"] ?? {};
  return {
    Cognito: {
      UserPoolId:
        runtime.COGNITO_USERPOOL ?? process.env.REACT_APP_COGNITO_USERPOOL!,
      ClientId:
        runtime.COGNITO_CLIENTID ?? process.env.REACT_APP_COGNITO_CLIENTID!,
    },
    Api: {
      BaseUrl: runtime.API_BASEURL ?? process.env.REACT_APP_API_BASEURL!,
    },
  };
}
