import React from "react";
import { Box, Grid, Link } from "@mui/material";
import {
  useGetAllLenders,
  UsersAddUserForm,
  UsersGetUserTypesResult,
} from "api";
import { useForm } from "react-hook-form";
import { FormErrorAlert, SubmitButton, withFormStatus } from "shared/forms";
import EmailField from "shared/forms/fields/EmailField";
import PhoneField from "shared/forms/fields/PhoneField";
import TextField from "shared/forms/fields/TextField";
import StickyFooter from "shared/layout/StickyFooter";
import { Link as RouterLink } from "react-router-dom";
import MultiSelectField from "shared/forms/fields/MultiSelectField";
import RadioGroupField from "shared/forms/fields/RadioGroupField";

interface Props {
  userTypeResults: UsersGetUserTypesResult;
  onSave(form: UsersAddUserForm): void;
}

function AddUserForm({ userTypeResults, onSave }: Props) {
  const { control, handleSubmit, watch } = useForm<UsersAddUserForm>();

  const userType = watch("userTypeId");

  const { data: LenderList } = useGetAllLenders({ pageSize: 1000 });

  const onSubmit = handleSubmit((form) => {
    onSave({
      ...form,
    });
  });

  return (
    <Box onSubmit={onSubmit} component="form">
      <FormErrorAlert />
      <Grid container spacing={4}>
        <Grid item md={12}>
          <TextField
            required={true}
            control={control}
            name="username"
            label="Username"
            placeholder={"JohnDoe"}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            required={true}
            name="name.first"
            control={control}
            label="First Name"
            placeholder={"John"}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            name="name.last"
            control={control}
            label="Last Name"
            placeholder={"Doe"}
          />
        </Grid>
        <Grid item md={6}>
          <EmailField name="contact.email" control={control} label="Email" />
        </Grid>
        <Grid item md={6}>
          <PhoneField
            name="contact.phone"
            control={control}
            label="Phone Number"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <RadioGroupField
            control={control}
            labelName="User Type"
            name="userTypeId"
            options={userTypeResults.userType}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {userType === 2 && (
            <MultiSelectField
              options={
                LenderList?.items?.map((option) => {
                  return { id: option.id, name: option.name };
                }) || []
              }
              getOptionLabel={(opt) => opt.name}
              control={control}
              name="lenders"
              label="Lenders"
            />
          )}
        </Grid>
      </Grid>
      <StickyFooter>
        <Link variant="button" component={RouterLink} to={`/users`}>
          Back to User List
        </Link>
        <SubmitButton variant="save" />
      </StickyFooter>
    </Box>
  );
}

export default withFormStatus(AddUserForm);
